import React, { useState, useEffect } from "react";
import CustomMultiSelect from "./multiSelect";
import { useNotifications } from '@mantine/notifications'

const primitiveType = [
  "boolean",
  "date",
  "dateTime",
  "double",
  "duration",
  "float",
  "integer",
  "long",
  "string",
  "time",
];
// CommandPanel Component
const CommandPanel = ({
  selectedObject,
  allSchemas,
  allInterlocks,
  onUpdate,
  refreshWindow
}) => {
  const notifications = useNotifications()
  const [selectedCommand, setSelectedCommand] = useState("");
  const [commands, setCommands] = useState([]);
  const [currentObjectSchema, setCurrentObjectSchema] = useState(null);
  const [currentCommandSchema, setCurrentCommandSchema] = useState(null);
  const [requestPayload, setRequestPayload] = useState({});

  const [isInterlocksUpdate, setIsInterlocksUpdate] = useState(false);
  const [interlocks, setInterlocks] = useState([]);
  const [currentObjectInterlocks, setCurrentInterlocks] = useState(null);
  const [selectedPreExecutionInterlocks, setSelectedPreExecutionInterlocks] =
    useState([]);
  const [selectedPostExecutionInterlocks, setSelectedPostExecutionInterlocks] =
    useState([]);

  const findSchemaById = (schemaId) => {
    return allSchemas.find((schema) => schema["@id"] === schemaId);
  };
  const findInterlocksById = (interlockId) => {
    return allInterlocks.find(
      (interlock) => interlock["objectId"] === interlockId
    );
  };

  useEffect(() => {
    if (selectedObject) {
      const schema = findSchemaById(selectedObject["$metadata"]["$model"]);
      setCurrentObjectSchema(() => schema);
      // console.log(selectedObject, schema,allInterlocks);
      const commands = schema?.contents.filter(
        (content) => content["@type"] === "Command"
      );
      if (commands) {
        setCommands(() => commands);
      } else {
        setCommands(() => []);
      }
      setSelectedPreExecutionInterlocks([]);
      setSelectedPostExecutionInterlocks([]);
      fetchInterlocks(selectedObject["$dtId"]);
    }
  }, [selectedObject]);

  useEffect(() => {
    const interlocks = findInterlocksById(selectedObject["$dtId"]);
    if (interlocks) {
      setCurrentInterlocks(interlocks);
      setIsInterlocksUpdate(true);
    } else {
      setCurrentInterlocks(null);
      setIsInterlocksUpdate(false);
    }
  }, [allInterlocks, selectedObject]);

  const fetchInterlocks = async (deviceId) => {
    const response = await fetch(
      `${process.env.REACT_APP_VIA_RULE_ENGINE_API}/api/Rule/ByDevice/${deviceId}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    setInterlocks(jsonData);
  };

  const handleCommandChange = (e) => {
    setSelectedCommand(e.target.value);
    var commandSchema = commands.filter((s) => s.name === e.target.value);
    if (commandSchema) {
      setCurrentCommandSchema(() => commandSchema[0]);
      handleInterlockByCommand(commandSchema[0]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let convertedValue = value;
    // Find the field schema to determine its type
    let fieldSchema = "";
    if (
      currentCommandSchema.request &&
      "@type" in currentCommandSchema.request &&
      currentCommandSchema.request["@type"] === "Object"
    ) {
      const field = currentCommandSchema?.request?.fields?.find(
        (field) => field.name === name
      );
      if (field) {
        fieldSchema = field[0].schema;
      }
    } else {
      fieldSchema = currentCommandSchema.request.schema;
    }

    if (fieldSchema) {
      switch (fieldSchema) {
        case "double":
        case "float":
        case "long":
          convertedValue = parseFloat(value);
          convertedValue = isNaN(convertedValue) ? 0 : convertedValue;
          break;
        case "integer":
          convertedValue = parseInt(value, 10);
          break;
        case "boolean":
          convertedValue = value === "true";
          break;
        case "dateTime":
          // Assuming ISO 8601 format; no conversion needed as it's already a string
          break;
        // Handle additional schema types as needed
        default:
          // For string and other unhandled types; no conversion needed
          break;
      }
    }

    // Update the request payload with the converted value
    setRequestPayload((prev) => ({ ...prev, [name]: convertedValue }));
  };

  const executeCommand = async (e) => {
    e.preventDefault();
    console.log(
      `Executing command: ${selectedCommand} with payload:`,
      requestPayload
    );
    // Command execution logic goes here
    const apiUrl = `${process.env.REACT_APP_VIA_OBJECT_API}/api/object/command/execute`;
    const requestBody = {
      instanceId: selectedObject["$dtId"], // Assuming selectedCommand holds the DTDL ID; adjust as necessary
      commandName: selectedCommand, // This should be the actual command name; adjust variable as necessary
      parameters: { ...requestPayload }, // Cloning requestPayload to parameters
    };
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Command execution successful:", data);
      // Handle successful command execution here (e.g., show a notification)
    } catch (error) {
      console.error("Error executing command:", error);
      // Handle errors here (e.g., show an error message)
    }
  };

  const handleInterlockByCommand = (command) => {
    const preExecutionInterlocks =
      currentObjectInterlocks?.preExecutionInterlocks?.[command?.name] || [];
    const postExecutionInterlocks =
      currentObjectInterlocks?.postExecutionInterlocks?.[command?.name] || [];
    setSelectedPreExecutionInterlocks(preExecutionInterlocks);
    setSelectedPostExecutionInterlocks(postExecutionInterlocks);
  };

  const saveCommand = async (e) => {
    e.preventDefault();
    // console.log(`saveCommand command: ${selectedCommand} with payload:`, {
    //   preExecutionInterlocks: selectedPreExecutionInterlocks,
    //   postExecutionInterlocks: selectedPostExecutionInterlocks,
    // });
    const apiUrl = `${process.env.REACT_APP_VIA_OBJECT_API}/api/Interlock`;
    try {
      if (isInterlocksUpdate) {
        const payload = {
          objectId: selectedObject["$dtId"],
          preExecutionInterlocks: {
            ...currentObjectInterlocks.preExecutionInterlocks,
            [selectedCommand]: selectedPreExecutionInterlocks,
          },
          postExecutionInterlocks: {
            ...currentObjectInterlocks.postExecutionInterlocks,
            [selectedCommand]: selectedPostExecutionInterlocks,
          },
        };
        console.log(payload);
        const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        refreshWindow()
        const data = await response.json();
        console.log("Command update successful:", data);
        setSelectedCommand("");
        setSelectedPreExecutionInterlocks([]);
        setSelectedPostExecutionInterlocks([]);
        onUpdate(true);
        notifications.showNotification({
          title: 'Commands updated successfully!',
          message: '',
          autoClose: 5000,
          color: 'green',
          loading: false,
          disallowClose: false,
        })
      } else {
        const payload = {
          objectId: selectedObject["$dtId"],
          preExecutionInterlocks: {
            [selectedCommand]: selectedPreExecutionInterlocks,
          },
          postExecutionInterlocks: {
            [selectedCommand]: selectedPostExecutionInterlocks,
          },
        };
        console.log(payload);
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Command save successful:", data);
        setSelectedCommand("");
        setSelectedPreExecutionInterlocks([]);
        setSelectedPostExecutionInterlocks([]);
        onUpdate(true);
      }
    } catch (error) {
      console.error("Error save command:", error);
      // Handle errors here (e.g., show an error message)
    }
  };

  const handleInterlockChange = (selectedOptions, type) => {
    if (type === "pre") {
      setSelectedPreExecutionInterlocks(selectedOptions);
    } else {
      setSelectedPostExecutionInterlocks(selectedOptions);
    }
  };

  const renderInputField = (field) => {
    if (!field) {
      return;
    }

    let fieldCustomSchema;
    if (currentObjectSchema.schemas) {
      fieldCustomSchema = currentObjectSchema?.schemas?.filter(
        (s) => s["@id"] === field.schema
      );
    }
    if (field.schema === "dateTime") {
      return (
        <input
          className="p-1 mx-2 rounded border border-[#ccc] w-[calc((100% - 22px)]"
          type="datetime-local"
          name={field.name}
          onChange={handleInputChange}
        />
      );
    } else if (field.schema === "double" || field.schema === "integer") {
      return (
        <input
          className="p-1 mx-2 rounded border border-[#ccc] w-[calc((100% - 22px)]"
          type={"number"}
          name={field.name}
          onChange={handleInputChange}
        />
      );
    } else if (field.schema === "string") {
      return (
        <input
          className="p-1 mx-2 rounded border border-[#ccc] w-[calc((100% - 22px)]"
          type={"text"}
          name={field.name}
          onChange={handleInputChange}
        />
      );
    } else if (field.schema === "boolean") {
      return (
        <>
          {" "}
          <select
            name={field.name}
            className="p-2 mx-1 rounded border-[#ccc] border"
            onChange={handleInputChange}
          >
            <option value="">Select a value</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </>
      );
    } else if (
      field.schema["@type"] === "Enum" &&
      field.schema.hasOwnProperty("enumValues")
    ) {
      return (
        <select
          name={field.name}
          onChange={handleInputChange}
          className="p-2 mx-1 rounded border-[#ccc] border"
        >
          {field.schema.enumValues.map((enumValue) => (
            <option key={enumValue.enumValue} value={enumValue.enumValue}>
              {enumValue.name}
            </option>
          ))}
        </select>
      );
    } else if (fieldCustomSchema && fieldCustomSchema.length > 0) {
      if (fieldCustomSchema[0]["@type"] === "Enum") {
        return (
          <select
            name={field.name}
            onChange={handleInputChange}
            className="p-2 mx-1 rounded border-[#ccc] border"
          >
            <option value="">Select a value</option>
            {fieldCustomSchema[0].enumValues.map((enumValue) => (
              <option key={enumValue.enumValue} value={enumValue.enumValue}>
                {enumValue.name}
              </option>
            ))}
          </select>
        );
      }
    }
    // Additional field types can be handled here
  };

  const renderRequestForm = (command) => {
    if (command) {
      if (
        command.request &&
        command.request.schema &&
        command.request.schema["@type"] === "Object"
      ) {
        return command.request.schema.fields.map((field, index) => (
          <div key={index} className="mb-4">
            <label className="block">{field.name}: </label>
            {renderInputField(field)}
          </div>
        ));
      } else {
        // Simple property case
        return (
          <div className="w-full">
            {command.request ? (
              <label>{command.request.name} </label>
            ) : (
              <label>{command.name} </label>
            )}
            {renderInputField(command.request)}
          </div>
        );
      }
    }
  };

  return (
    <>
      {/* <form onSubmit={executeCommand} className="flex flex-col space-y-2 ">
        <select
          className="p-2 mx-1 rounded border-[#ccc] border w-[calc(100%-8px)]"
          onChange={handleCommandChange}
          value={selectedCommand}
        >
          <option value="">Select a Command</option>
          {commands.map((command, index) => (
            <option key={index} value={command.name}>
              {command.name}
            </option>
          ))}
        </select>

      {selectedCommand && selectedCommand !== "" && (
        <>
          {renderRequestForm(
            commands.find((cmd) => cmd.name === selectedCommand)
          )}
          <button type="submit" className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner">
            Execute
          </button>
        </>
      )}
    </form> */}
      <form onSubmit={saveCommand} className="flex flex-col space-y-2 ">
        <div className="grid grid-cols-3 gap-2 items-start">
          <div className="space-y-1 flex flex-col items-start gap-1">
            <label
              htmlFor="pre-execution"
              className="block text-sm font-medium text-gray-700"
            >
              Select Command
            </label>
            <select
              className="  w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
              onChange={handleCommandChange}
              value={selectedCommand}
            >
              <option className="text-gray-400" value="">
                Select a Command
              </option>
              {commands.map((command, index) => (
                <option key={index} value={command.name}>
                  {command.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="grid grid-cols-[104px,1fr] items-center gap-1">
            <label className="">Pre Execution</label>
            <select
              className="p-2 mx-1 rounded border-[#ccc] border w-[calc(100%-8px)]"
              multiple
              onChange={(e) => handleInterlockChange(e, "pre")}
              value={selectedPreExecutionInterlocks}
            >
              <option value="">Select Interlocks</option>
              {interlocks.map((interlock, index) => (
                <option key={index} value={interlock.id}>
                  {interlock.ruleName}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-[104px,1fr] items-center gap-1">
            <label className="">Post Execution</label>
            <select
              className="p-2 mx-1 rounded border-[#ccc] border w-[calc(100%-8px)]"
              multiple
              onChange={(e) => handleInterlockChange(e, "post")}
              value={selectedPostExecutionInterlocks}
            >
              <option value="">Select Interlocks</option>
              {interlocks.map((interlock, index) => (
                <option key={index} value={interlock.id}>
                  {interlock.ruleName}
                </option>
              ))}
            </select>
          </div> */}

          <div className="space-y-1 flex flex-col items-start gap-1">
            <label
              htmlFor="pre-execution"
              className="block text-sm font-medium text-gray-700"
            >
              Pre Execution
            </label>
            <CustomMultiSelect
              options={interlocks.map((interlock) => ({
                value: interlock.id,
                label: interlock.ruleName,
              }))}
              value={selectedPreExecutionInterlocks}
              onChange={(value) => handleInterlockChange(value, "pre")}
              placeholder="Select Interlocks"
            />
          </div>
          <div className="space-y-1 flex flex-col items-start gap-1">
            <label
              htmlFor="post-execution"
              className="block text-sm font-medium text-gray-700"
            >
              Post Execution
            </label>
            <CustomMultiSelect
              options={interlocks.map((interlock) => ({
                value: interlock.id,
                label: interlock.ruleName,
              }))}
              value={selectedPostExecutionInterlocks}
              onChange={(value) => handleInterlockChange(value, "post")}
              placeholder="Select interlocks"
            />
          </div>
        </div>
        <button
          type="submit"
          className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
        >
          {isInterlocksUpdate ? "Update" : "Save"}
        </button>
      </form>
    </>
  );
};

export default CommandPanel;
