import React from 'react'

function pm5(props) {
  return (
    <svg width="120" height="149" viewBox="0 0 120 149" fill="none" xmlns="http://www.w3.org/2000/svg" x={props.x} y={props.y}>
    <g id="Group 152">
    <path id="Vector" d="M30.0842 147.267L30.3174 109.725L7.23819 109.581L7.89292 4.1529L115.891 4.82359L115.236 110.252L91.2159 110.103L90.9827 147.645C64.2396 147.571 56.8273 147.342 30.0842 147.267Z" fill="url(#paint0_linear_2088_34)" stroke="#646368" stroke-miterlimit="10"/>
    <g id="Vector_2">
    <g filter="url(#filter0_i_2088_34)">
    <path d="M104.319 96.7536L104.571 56.296C103.66 45.9802 99.1266 36.2915 91.7434 28.8788C84.3602 21.4661 74.5831 16.7873 64.0759 15.6389C40.7501 13.6162 18.5027 31.1425 15.5728 55.7428L15.3213 96.2005L104.319 96.7536Z" fill="#76777A"/>
    </g>
    <path d="M91.3891 29.2316C98.685 36.5567 103.165 46.1274 104.071 56.3165L103.823 96.2505L15.8244 95.7036L16.0726 55.7741C18.9839 31.4577 40.9817 14.1412 64.0271 16.1366C74.42 17.2738 84.0887 21.902 91.3891 29.2316Z" stroke="#A2A2A2"/>
    </g>
    <g id="Vector_3">
    <g filter="url(#filter1_ii_2088_34)">
    <path d="M91.5652 57.1736C91.6698 40.3292 78.0995 26.5894 61.2552 26.4848C44.4108 26.3802 30.671 39.9504 30.5664 56.7948C30.4618 73.6392 44.032 87.379 60.8764 87.4836C77.7207 87.5882 91.4606 74.018 91.5652 57.1736Z" fill="#E6E6E6"/>
    </g>
    <path d="M92.0652 57.1767C92.1715 40.0562 78.3788 26.0911 61.2583 25.9848C44.1378 25.8785 30.1727 39.6712 30.0664 56.7917C29.96 73.9122 43.7528 87.8773 60.8733 87.9836C77.9938 88.0899 91.9589 74.2972 92.0652 57.1767Z" stroke="#B1B1B1"/>
    </g>
    <rect id="Rectangle 756" x="115.269" y="17.6643" width="3.55581" height="62.8886" rx="0.75" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
    <g id="Rectangle 757" filter="url(#filter2_d_2088_34)">
    <rect x="115.325" y="95.822" width="16" height="108" rx="1" transform="rotate(90.3558 115.325 95.822)" fill="url(#paint1_linear_2088_34)" shape-rendering="crispEdges"/>
    <rect x="115.074" y="96.0705" width="15.5" height="107.5" rx="0.75" transform="rotate(90.3558 115.074 96.0705)" stroke="#646368" stroke-width="0.5" shape-rendering="crispEdges"/>
    </g>
    <g id="Rectangle 758" filter="url(#filter3_i_2088_34)">
    <rect x="65.6748" y="39.5125" width="3" height="2" rx="1" transform="rotate(-89.6442 65.6748 39.5125)" fill="#D9D9D9"/>
    </g>
    <g id="Rectangle 759" filter="url(#filter4_i_2088_34)">
    <rect x="47.5566" y="58.4004" width="3" height="3" rx="1" transform="rotate(-89.6442 47.5566 58.4004)" fill="#D9D9D9"/>
    </g>
    <g id="Rectangle 760" filter="url(#filter5_i_2088_34)">
    <rect x="60.5498" y="54.4998" width="6" height="6" rx="3" transform="rotate(-1.6442 63.5498 59.4998)" fill="#767070"/>
    </g>
    <g id="Rectangle 761" filter="url(#filter6_i_2088_34)">
    <rect x="60.4443" y="76.4807" width="2" height="2" rx="1" transform="rotate(-89.6442 60.4443 76.4807)" fill="#D9D9D9"/>
    </g>
    <g id="Rectangle 762" filter="url(#filter7_i_2088_34)">
    <rect x="84.5557" y="58.6301" width="3" height="3" rx="1" transform="rotate(-89.6442 84.5557 58.6301)" fill="#D9D9D9"/>
    </g>
    <g id="Rectangle 763" filter="url(#filter8_d_2088_34)">
    <rect x="90.2266" y="111.666" width="4" height="60" transform="rotate(90.3558 90.2266 111.666)" fill="#D9D9D9"/>
    </g>
    <rect id="Rectangle 764" x="21.7509" y="3.94551" width="3.55581" height="91.0104" rx="0.75" transform="rotate(-90.01 21.7509 3.94551)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
    <rect id="Rectangle 765" x="7.35372" y="3.72562" width="3.47207" height="14.9469" rx="0.75" transform="rotate(-89.0496 7.35372 3.72562)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
    <rect id="Rectangle 766" x="9.82011" y="10.8584" width="5.4396" height="10.2353" rx="0.75" transform="rotate(179.953 9.82011 10.8584)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
    <g id="Group 72">
    <path id="Vector_4" d="M30.1972 21.6275C30.2432 21.5995 30.2741 21.5494 30.2744 21.4929L30.3062 16.3768C30.3065 16.3202 30.2763 16.2697 30.2306 16.2412L25.938 13.6564C25.8923 13.6278 25.8312 13.6274 25.7852 13.6554L21.4608 16.1867C21.4148 16.2147 21.3839 16.2648 21.3836 16.3214L21.3518 21.4375C21.3515 21.494 21.3817 21.5445 21.4274 21.5731L25.72 24.1579C25.7657 24.1864 25.8268 24.1868 25.8728 24.1588L30.1972 21.6275ZM21.658 21.3482L21.6886 16.4144L25.8596 13.9733L30.0001 16.466L29.9694 21.3998L25.7984 23.8409L21.658 21.3482Z" fill="#646368"/>
    <path id="Vector_5" d="M27.8564 18.9197C27.8633 17.8028 26.958 16.8862 25.8411 16.8792C24.7243 16.8723 23.8076 17.7776 23.8007 18.8945C23.7937 20.0114 24.6991 20.928 25.8159 20.935C26.9328 20.9419 27.8495 20.0366 27.8564 18.9197ZM24.1127 18.8964C24.1185 17.9511 24.8939 17.1853 25.8392 17.1912C26.7845 17.1971 27.5503 17.9725 27.5444 18.9177C27.5386 19.863 26.7632 20.6288 25.8179 20.623C24.8726 20.6171 24.1068 19.8417 24.1127 18.8964Z" fill="#646368"/>
    </g>
    <g id="Group 73">
    <path id="Vector_6" d="M109.689 22.1212C109.735 22.0932 109.766 22.0431 109.767 21.9865L109.798 16.8704C109.799 16.8139 109.768 16.7634 109.723 16.7348L105.43 14.15C105.385 14.1214 105.323 14.1211 105.277 14.1491L100.953 16.6804C100.907 16.7084 100.876 16.7584 100.876 16.815L100.844 21.9311C100.844 21.9877 100.874 22.0382 100.92 22.0667L105.212 24.6515C105.258 24.6801 105.319 24.6805 105.365 24.6525L109.689 22.1212ZM101.15 21.8419L101.181 16.908L105.352 14.4669L109.492 16.9597L109.462 21.8935L105.291 24.3346L101.15 21.8419Z" fill="#646368"/>
    <path id="Vector_7" d="M107.349 19.4133C107.356 18.2965 106.45 17.3798 105.333 17.3729C104.216 17.3659 103.3 18.2713 103.293 19.3882C103.286 20.505 104.191 21.4217 105.308 21.4286C106.425 21.4355 107.342 20.5302 107.349 19.4133ZM103.605 19.3901C103.611 18.4448 104.386 17.679 105.331 17.6849C106.277 17.6907 107.042 18.4661 107.037 19.4114C107.031 20.3567 106.255 21.1225 105.31 21.1166C104.365 21.1108 103.599 20.3354 103.605 19.3901Z" fill="#646368"/>
    </g>
    <g id="Rectangle 767" filter="url(#filter9_i_2088_34)">
    <rect x="90.6182" y="118.389" width="9.36905" height="60.4805" transform="rotate(90.4113 90.6182 118.389)" fill="#EAEAEA"/>
    </g>
    {/* <g id="Group 74" filter="url(#filter10_d_2088_34)">
    <path id="Vector_8" d="M35.5957 56.6988C35.509 70.662 46.7982 82.0923 60.7614 82.179C74.7247 82.2657 86.1549 70.9766 86.2416 57.0133C86.3284 43.05 75.0392 31.6198 61.0759 31.5331C47.1127 31.4464 35.6825 42.7355 35.5957 56.6988ZM69.0015 56.9062C66.0751 64.6998 66.9153 61.827 60.8702 64.6675C54.1613 63.1868 57.4491 63.4128 52.762 56.8054C56.7111 49.8405 53.6136 52.0825 61.6536 48.4321C68.0158 50.1162 66.1517 52.3659 69.0015 56.9062Z" fill="#2F3034"/>
    <path id="Vector_9" d="M60.9365 54.0425C59.3852 54.0329 58.115 55.2874 58.1054 56.8387C58.0957 58.3899 59.3503 59.6602 60.9016 59.6698C62.4528 59.6795 63.7231 58.4249 63.7327 56.8736C63.7423 55.3224 62.4878 54.0521 60.9365 54.0425ZM60.9132 57.794C60.3964 57.7908 59.9779 57.3671 59.9811 56.8503C59.9843 56.3336 60.4081 55.9151 60.9248 55.9183C61.4416 55.9215 61.8601 56.3452 61.8569 56.862C61.8537 57.3788 61.43 57.7973 60.9132 57.794Z" fill="#2F3034"/>
    <path id="Vector_10" d="M60.9706 48.4151C56.3168 48.3862 52.5061 52.1489 52.4772 56.8036C52.4483 61.4574 56.212 65.2681 60.8658 65.297C65.5205 65.326 69.3303 61.5623 69.3592 56.9085C69.3881 52.2538 65.6254 48.444 60.9706 48.4151ZM60.8774 63.4213C57.2572 63.3988 54.3305 60.4355 54.353 56.8153C54.3755 53.196 57.3387 50.2684 60.959 50.2909C64.5783 50.3133 67.5059 53.2775 67.4834 56.8968C67.4609 60.5171 64.4967 63.4438 60.8774 63.4213Z" fill="#2F3034"/>
    <path id="Vector_11" d="M48.5013 41.4274C48.8228 41.8332 49.4131 41.9015 49.8189 41.5799C57.5202 35.4768 68.7502 36.7758 74.8541 44.4766C75.1757 44.8823 75.7652 44.9512 76.1717 44.629C76.5781 44.3069 76.6457 43.7172 76.3241 43.3114C69.5782 34.8007 57.165 33.3647 48.6543 40.1106C48.2479 40.4314 48.1791 41.021 48.5013 41.4274Z" fill="#F5F4F9"/>
    </g> */}
    <rect id="Rectangle 769" x="5.11523" y="41.9807" width="3.55581" height="62.8886" rx="0.75" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
    <g id="Rectangle 770" filter="url(#filter11_d_2088_34)">
    <rect x="1" y="21.094" width="10.7977" height="10.1015" rx="1" transform="rotate(-89.6787 1 21.094)" fill="#D9D9D9"/>
    <rect x="1.2514" y="20.8454" width="10.2977" height="9.60147" rx="0.75" transform="rotate(-89.6787 1.2514 20.8454)" stroke="#646368" stroke-width="0.5"/>
    </g>
    </g>
    <defs>
    <filter id="filter0_i_2088_34" x="15.3213" y="15.4802" width="89.25" height="85.2734" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2088_34"/>
    </filter>
    <filter id="filter1_ii_2088_34" x="25.5654" y="21.4841" width="70" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="3" dy="5"/>
    <feGaussianBlur stdDeviation="2.5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2088_34"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-4" dy="-4"/>
    <feGaussianBlur stdDeviation="2.5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
    <feBlend mode="normal" in2="effect1_innerShadow_2088_34" result="effect2_innerShadow_2088_34"/>
    </filter>
    <filter id="filter2_d_2088_34" x="2.23438" y="94.1575" width="114.085" height="22.658" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="2"/>
    <feGaussianBlur stdDeviation="1.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2088_34"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2088_34" result="shape"/>
    </filter>
    <filter id="filter3_i_2088_34" x="65.6807" y="36.5188" width="2.00684" height="4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2088_34"/>
    </filter>
    <filter id="filter4_i_2088_34" x="47.5625" y="55.4067" width="3.00684" height="4.0061" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2088_34"/>
    </filter>
    <filter id="filter5_i_2088_34" x="60.5498" y="54.4998" width="6" height="7" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2088_34"/>
    </filter>
    <filter id="filter6_i_2088_34" x="60.4502" y="74.4871" width="2" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2088_34"/>
    </filter>
    <filter id="filter7_i_2088_34" x="84.5615" y="55.6365" width="3.00684" height="4.0061" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2088_34"/>
    </filter>
    <filter id="filter8_d_2088_34" x="25.2031" y="110.294" width="66.0234" height="10.3726" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="2"/>
    <feGaussianBlur stdDeviation="1.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2088_34"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2088_34" result="shape"/>
    </filter>
    <filter id="filter9_i_2088_34" x="30.0723" y="117.955" width="61.5459" height="10.803" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="1" dy="1"/>
    <feGaussianBlur stdDeviation="2.5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2088_34"/>
    </filter>
    <filter id="filter10_d_2088_34" x="33.5957" y="29.5325" width="60.6465" height="60.647" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="3" dy="3"/>
    <feGaussianBlur stdDeviation="2.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2088_34"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2088_34" result="shape"/>
    </filter>
    <filter id="filter11_d_2088_34" x="0.00585938" y="10.302" width="14.1504" height="14.843" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="1" dy="2"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2088_34"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2088_34" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_2088_34" x1="20.5758" y1="-10.1859" x2="99.2931" y2="164.489" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EAEAEA"/>
    <stop offset="0.25" stop-color="#CDCDCD"/>
    <stop offset="0.495" stop-color="#A6A6A7"/>
    <stop offset="0.695" stop-color="#DBDBDC"/>
    <stop offset="1" stop-color="#8B8B8B"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2088_34" x1="123.325" y1="95.822" x2="123.325" y2="203.822" gradientUnits="userSpaceOnUse">
    <stop stop-color="#BFBFBF"/>
    <stop offset="0.485" stop-color="#E2E2E2"/>
    <stop offset="1" stop-color="#A9A6A6" stop-opacity="0"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default pm5