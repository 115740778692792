
import React from 'react';
import Images from '../../../Images';
import { useLocation, Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { hasPermission, enumPermission } from '../../../utils/permissionUtils';
export default function TabsDinamic(props) {
  const { user, permissionRole } = useAuth();
  console.log("view", user?.user_type == "super_admin" || permissionRole?.permissions.configuration.user_management_tab.user_list_tab.access)
  const location = useLocation();
  return (
    <>
      <div className='flex border-b border-gray-300 relative z-20'>
        {props.tabs.map(({ name, tabs }) => (
          props?.type == name &&
          tabs.filter((e) => e.isActive).map((i, j) => (
            <button onClick={() => { props.setHead(i.name); props.Tabheadchecker(i.name); props.setsubhead(i.sub); props.updatePage(i.tab) }}
              className={`p-1 px-6 font-semibold rounded-t-lg  
                ${i.name === props.head &&
                'bg-blue-100 '} 
                `}>
              {i.name}
            </button>
          ))
        ))}
      </div>
      {(props?.head == 'Isotropic Etch Chamber') &&
        <div className='flex gap-1 mt-2 border-b border-red-400 pl-4'>
          <>
            <Link onClick={() => { props.setsubhead('Main'); props.updatePage('setpageIsotopic') }} className={`p-1 px-10 rounded-t ${'Main' == props.subhead ? 'bg-red-200' : 'bg-gray-300'}`}>Main</Link>
            {/* <Link onClick={()=>{props.setsubhead('GasBox');props.updatePage('setpageIsotopicgasbox')}} className={`p-1 px-10  rounded-t  ${'GasBox'==props.subhead? 'bg-red-200': 'bg-gray-300'}`}>Gasbox</Link> */}
            {/* <Link to={'/motor'} className={`p-1 px-4 font-bold rounded-t-lg ${'/motor'===location.pathname&& 'bg-blue-100'}`}>Motor1</Link> */}
            {/* <Link onClick={()=>{props.setsubhead('MotorOption');props.updatePage('setpageIsotopicmotor')}}className={`p-1 px-10  rounded-t  ${'MotorOption'==props.subhead? 'bg-red-200': 'bg-gray-300'}`}>Motor</Link> */}
            <Link onClick={() => { props.setsubhead('IecChamber'); props.updatePage('setpageIsotopicnew') }} className={`p-1 px-10  rounded-t  ${'IecChamber' == props.subhead ? 'bg-red-200' : 'bg-gray-300'}`}>IEC Latest</Link>
            <Link onClick={() => { props.setsubhead('Iecdtdl'); props.updatePage('setpageIsotopicDtdl') }} className={`p-1 px-10  rounded-t  ${'Iecdtdl' == props.subhead ? 'bg-red-200' : 'bg-gray-300'}`}>IEC DTDL</Link>
          </>
        </div>
      }
      {(props?.head == 'Dicing RIE Chamber') &&
        <div className='flex gap-1 mt-2 border-b border-red-400 pl-4'>
          <>
            {/* <Link onClick={() => { props.setsubhead('Main'); props.updatePage('setpagedicing') }} className={`p-1 px-10 rounded-t ${'Main' == props.subhead ? 'bg-red-200' : 'bg-gray-300'}`}>Main</Link> */}
            <Link onClick={() => { props.setsubhead('DicingDtdl'); props.updatePage('setpagedicingDtdl') }} className={`p-1 px-10 rounded-t ${'DicingDtdl' == props.subhead ? 'bg-red-200' : 'bg-gray-300'}`}>DicingChamberA</Link>
            {/* <Link onClick={()=>{props.setsubhead('GasBox');props.updatePage('setpagedicingGasbox')}} className={`p-1 px-10  rounded-t  ${'GasBox'==props.subhead? 'bg-red-200': 'bg-gray-300'}`}>Gasbox</Link>
                <Link onClick={()=>{props.setsubhead('MotorOption');props.updatePage('setpagedicingmotor')}}className={`p-1 px-10  rounded-t  ${'MotorOption'==props.subhead? 'bg-red-200': 'bg-gray-300'}`}>Motor</Link> */}
          </>
        </div>
      }
      {(props?.head == 'User Management') &&
        <div className='flex gap-1 mt-2 border-b border-red-400 pl-4'>
          <>
            {hasPermission(user, permissionRole, enumPermission.USER_LIST_tAB, 'read') && <Link onClick={() => { props.setsubhead('Main'); props.updatePage('setpageUserList') }} className={`p-1 px-10 rounded-t ${'Main' == props.subhead ? 'bg-red-200' : 'bg-gray-300'}`}>Users List</Link>}
            {/* {hasPermission(user, permissionRole, enumPermission.PERMISSION_TAB, 'read') && <Link onClick={() => { props.setsubhead('Permission'); props.updatePage('setpagePermission') }} className={`p-1 px-10  rounded-t  ${'Permission' == props.subhead ? 'bg-red-200' : 'bg-gray-300'}`}>Permission</Link>} */}
          </>
        </div>
      }
      {/* {(props?.head=='Transfer Chamber')&& 
           <div className='flex gap-1 mt-2 border-b border-red-400 pl-4'>
           <>
             <Link onClick={()=>{props.setsubhead('Main');props.updatePage('setpageTransfer')}}  className={`p-1 px-10 rounded-t ${'Main'==props.subhead? 'bg-red-200': 'bg-gray-300'}`}>Main</Link>
                <Link onClick={()=>{props.setsubhead('efem');props.updatePage('setpageTransfer')}} className={`p-1 px-10  rounded-t  ${'efem'==props.subhead? 'bg-red-200': 'bg-gray-300'}`}>EFEM</Link>
           </>
           </div>

          }    */}
    </>
  )
}

