import React, { useState, useEffect } from "react";
import { useNotifications } from "@mantine/notifications";
import CustomMultiSelect from "./multiSelect";
import { TrashIcon } from "@heroicons/react/16/solid";
import ExpressionBuilder from "./expressionBuilder";
import EventCard from "./eventCard";
import {
  DeleteObjectEventByName,
  ExpressionBuild,
  GetObjectEventById,
  SaveObjectEvent,
} from "../../api/object";

const ACTIONS = [
  {
    actionName: "SetObjectProperty",
    input: ["id", "property", "value"],
    type: "dtdl",
  },
  { actionName: "StartMonitor", input: ["monitorId"], type: "monitor" },
  { actionName: "StopMonitor", input: ["monitorId"], type: "monitor" },
  { actionName: "StartSequence", input: ["sequenceId"], type: "sequence" },
  { actionName: "StopSequence", input: ["sequenceId"], type: "sequence" },
  // {
  //   actionName: "EvaluateInterlock",
  //   input: ["interlockId"],
  //   type: "interlock",
  // },
];
// EventPanel Component
const EventPanel = ({ selectedObject, allSchemas, allObject, onUpdate, refreshWindow }) => {
  const notifications = useNotifications();
  const [selectedEvent, setSelectedEvent] = useState("");
  const [commands, setCommands] = useState([]);
  const [currentObjectSchema, setCurrentObjectSchema] = useState(null);
  const [requestPayload, setRequestPayload] = useState({});
  const [isShowAddEvent, setShowAddEvent] = useState(false);
  const [currentEvents, setCurrentEvents] = useState(null);
  const [currentConditions, setCurrentConditions] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");
  const [dtdlList, setDtdlList] = useState([]);
  const [dtdlSchemaList, setDtdlSchemaList] = useState([]);
  const [dtdlPropertyList, setDtdlPropertyList] = useState([]);
  const [selectedDeviceName, setSelectedDeviceName] = useState("");
  const [selectedDeviceProperty, setSelectedDeviceProperty] = useState("");
  const [selectedStartMonitor, setSelectedStartMonitor] = useState([]);
  const [selectedStopMonitor, setSelectedStopMonitor] = useState([]);
  const [monitors, setMonitors] = useState([]);
  const [selectedStartSequence, setSelectedStartSequence] = useState([]);
  const [selectedStopSequence, setSelectedStopSequence] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [selectedInterlock, setSelectedInterlock] = useState([]);
  const [interlocks, setInterlocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedExprnCondition, setSelectedExprnCondition] = useState("");
  const [isExpressionModalOpen, setIsExpressionModalOpen] = useState(false);
  const [expressions, setExpressions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [editConditionIndex, setEditConditionIndex] = useState(-1);

  useEffect(() => {
    if (selectedObject) {
      const schema = allSchemas.find(
        (schema) => schema["@id"] === selectedObject["$metadata"]["$model"]
      );
      setCurrentObjectSchema(schema);
      setCommands(
        schema?.contents.filter((content) => content["@type"] === "Command") ||
          []
      );
      setSelectedEvent("");
      initObjectEvent();
      resetSelections();
      setShowAddEvent(false);
      getInterlocks();
      getMonitors();
      getSequences();
    }
  }, [selectedObject, allSchemas]);

  useEffect(() => {
    if (allObject && allSchemas) {
      const filteredSchema = getFilteredSchema(allSchemas);
      const filteredDtdlList = getFilteredDtdlList(allObject, filteredSchema);
      setDtdlList(filteredDtdlList);
      setDtdlSchemaList(filteredSchema);
    }
  }, [allObject, allSchemas]);

  const initObjectEvent = async () => {
    setLoading(true);
    try {
      const data = await GetObjectEventById({
        objectId: selectedObject["$dtId"],
      });
      setCurrentEvents(data?.events);
    } catch (error) {
      console.log(error, "getObjectError");
    }
    setLoading(false);
  };

  const resetSelections = () => {
    setSelectedAction("");
    setSelectedDeviceName("");
    setSelectedDeviceProperty("");
    setSelectedStartMonitor([]);
    setSelectedStopMonitor([]);
    setSelectedInterlock([]);
    setSelectedStartSequence([]);
    setSelectedStopSequence([]);
    setSelectedExprnCondition("");
    setEditConditionIndex(-1);
    setExpressions([]);
  };

  const getFilteredSchema = (schemas) => {
    return schemas
      .map((item) => ({
        ...item,
        contents:
          item.contents?.filter(
            (content) =>
              (content.displayName || content.name) &&
              (content["@type"] === "Telemetry" ||
                content["@type"] === "Property")
          ) || [],
      }))
      .filter((item) => item.contents.length > 0);
  };

  const getFilteredDtdlList = (objects, schema) => {
    return objects
      .filter((device) =>
        schema.some((iface) => device.$metadata.$model === iface["@id"])
      )
      .map((device) => ({
        ...device,
        value: device["$dtId"],
        label: device.Name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const updatePropertyList = (type) => {
    setSelectedDeviceName(type);
    const selectedDevice = dtdlList?.find((x) => x.value == type);
    const selectedDeviceModel = selectedDevice?.$metadata?.$model;
    const filteredCommandList = dtdlSchemaList
      ?.find((e) => e["@id"] == selectedDeviceModel)
      ?.contents?.map((info) => ({
        ...info,
        value: info.name,
        label: info.displayName || info.name,
      }));
    setDtdlPropertyList(filteredCommandList || []);
  };

  const fetchData = async (url, setter) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setter(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectionChange = (setter) => (e) => {
    if (e?.target?.value != undefined) {
      setter(e.target.value);
    }
  };

  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
    const selConditions = currentEvents?.find(
      (s) => s.eventName === e.target.value
    );
    setCurrentConditions(selConditions?.conditions);
    resetSelections();
  };

  const handleActionChange = async (e) => {
    setSelectedAction(e.target.value);
    const selectedActionType = ACTIONS.find(
      (action) => action.actionName === e.target.value
    )?.type;
    // if (selectedActionType === "monitor") await getMonitors();
    // else if (selectedActionType === "sequence") await getSequences();
    // else if (selectedActionType === "interlock") await getInterlocks();
    updateActionInputs(e.target.value);
  };

  const getInterlocks = async () => {
    return await fetchData(
      `${process.env.REACT_APP_VIA_RULE_ENGINE_API}/api/Rule/ByDevice/${selectedObject["$dtId"]}`,
      setInterlocks
    );
  };
  const getMonitors = async () => {
    return await fetchData(
      `${process.env.REACT_APP_VIA_MONITORWORKFLOW_API}/api/Monitor/1/100`,
      setMonitors
    );
  };
  const getSequences = async () => {
    return await fetchData(
      `${process.env.REACT_APP_VIA_RULE_ENGINE_API}/api/Sequence`,
      setSequences
    );
  };

  const updateActionInputs = (
    actionName = selectedAction,
    expression = selectedExprnCondition,
    conditions = currentConditions
  ) => {
    console.log(conditions, "conditions");
    const selectedCondition = conditions?.find(
      (e) => e.expression == expression
    );
    const currentEventAction = selectedCondition?.eventActions?.find(
      (val) => val.actionName == actionName
    );
    if (!currentEventAction) return;
    const { input } = currentEventAction;
    switch (actionName) {
      case "SetObjectProperty":
        setSelectedDeviceName(input?.id);
        updatePropertyList(input?.id);
        setSelectedDeviceProperty(input.property);
        setRequestPayload({ [input.property]: input.value });
        break;
      case "EvaluateInterlock":
        setSelectedInterlock(input);
        break;
      case "StartMonitor":
        setSelectedStartMonitor(input);
        break;
      case "StopMonitor":
        setSelectedStopMonitor(input);
        break;
      case "StartSequence":
        setSelectedStartSequence(input);
        break;
      case "StopSequence":
        setSelectedStopSequence(input);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const field = dtdlPropertyList.find(
      (e) => e.value == selectedDeviceProperty
    );
    let convertedValue = convertValue(value, field?.schema);
    setRequestPayload((prev) => ({ [name]: convertedValue }));
  };

  const convertValue = (value, schema) => {
    switch (schema) {
      case "double":
      case "float":
      case "long":
        return parseFloat(value) || 0;
      case "integer":
        return parseInt(value, 10);
      case "boolean":
        return value === "true";
      default:
        return value;
    }
  };

  const saveObjectEvent = async (e) => {
    e.preventDefault();
    const input = getActionInput();
    if (!selectedEvent || !selectedAction) {
      notifications.showNotification({
        title: "missing fields",
        message: "",
        autoClose: 5000,
        color: "red",
        loading: false,
        disallowClose: false,
      });
      return;
    }
    setIsSaving(true);

    const eventActions = getEventActions(input);
    const conditions = getConditions(eventActions);
    const events = getEvents(conditions);
    const payload = {
      objectId: selectedObject["$dtId"],
      events: events,
    };

    try {
      const data = await SaveObjectEvent(payload);
      notifications.showNotification({
        title: "event saved successfully",
        message: "",
        autoClose: 5000,
        color: "green",
        loading: false,
        disallowClose: false,
      });
      console.log("Command save successful:", data);
      setSelectedEvent("");
      resetSelections();
      setCurrentEvents(data?.events);
      onUpdate(true);
      setShowAddEvent(false);
      setIsSaving(false);
      refreshWindow()
    } catch (error) {
      console.error("Error save command:", error);
      setIsSaving(false);
    }
  };
  const getEventActions = (input) => {
    const currentEventActions =
      currentConditions?.find((e) => e?.expression == selectedExprnCondition)
        ?.eventActions || [];
    const eventActions = currentEventActions.reduce((acc, e) => {
      if (e.actionName === selectedAction) {
        if (!input) {
          return acc;
        }
        if (Array.isArray(input) && input.length === 0) {
          // Skip this action if input is an empty array
          return acc;
        }

        return [...acc, { actionName: selectedAction, input }];
      }
      return [...acc, e];
    }, []);

    if (
      !eventActions.some((e) => e.actionName === selectedAction) &&
      input &&
      !(Array.isArray(input) && input.length === 0)
    ) {
      eventActions.push({ actionName: selectedAction, input });
    }

    return eventActions;
  };
  const getEvents = (conditions) => {
    const selEvents = currentEvents || [];
    const event = selEvents.reduce((acc, e) => {
      if (e?.eventName === selectedEvent) {
        if (Array.isArray(conditions) && conditions.length === 0) {
          return acc;
        }
        return [
          ...acc,
          {
            eventName: selectedEvent,
            conditions: conditions,
          },
        ];
      } else {
        return [...acc, e];
      }
    }, []); // Add an empty array as the initial value

    if (
      !selEvents.some((e) => e.eventName === selectedEvent) &&
      !(Array.isArray(conditions) && conditions.length === 0)
    ) {
      event.push({
        eventName: selectedEvent,
        conditions: conditions,
      });
    }
    return event;
  };
  const getConditions = (eventActions) => {
    const selConditions = currentConditions || [];
    const condition = selConditions.reduce((acc, e, index) => {
      if (
        e?.expression === selectedExprnCondition ||
        index == editConditionIndex
      ) {
        if (Array.isArray(eventActions) && eventActions.length === 0) {
          return acc;
        }
        return [
          ...acc,
          {
            expressionConditions: expressions,
            expression: selectedExprnCondition,
            eventActions: eventActions,
          },
        ];
      } else {
        return [...acc, e];
      }
    }, []); // Add an empty array as the initial value

    if (
      !selConditions.some((e) => e.expression === selectedExprnCondition) &&
      !(Array.isArray(eventActions) && eventActions.length === 0) &&
      editConditionIndex == -1
    ) {
      condition.push({
        expressionConditions: expressions,
        expression: selectedExprnCondition,
        eventActions: eventActions,
      });
    }
    return condition;
  };
  const getActionInput = () => {
    switch (selectedAction) {
      case "SetObjectProperty":
        if (
          selectedDeviceName &&
          selectedDeviceProperty &&
          requestPayload[selectedDeviceProperty] != undefined
        ) {
          return {
            id: selectedDeviceName,
            property: selectedDeviceProperty,
            value: requestPayload[selectedDeviceProperty],
          };
        } else {
          return null;
        }
      case "EvaluateInterlock":
        if (selectedInterlock) {
          return [...selectedInterlock];
        } else {
          return null;
        }
      case "StartMonitor":
        if (selectedStartMonitor) {
          return [...selectedStartMonitor];
        } else {
          return null;
        }
      case "StopMonitor":
        if (selectedStopMonitor) {
          return [...selectedStopMonitor];
        } else {
          return null;
        }
      case "StartSequence":
        if (selectedStartSequence) {
          return [...selectedStartSequence];
        } else {
          return null;
        }
      case "StopSequence":
        if (selectedStopSequence) {
          return [...selectedStopSequence];
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  const renderInputField = () => {
    const field = dtdlPropertyList.find(
      (e) => e.value == selectedDeviceProperty
    );
    if (!field) return null;

    const fieldCustomSchema = currentObjectSchema.schemas?.filter(
      (s) => s["@id"] === field.schema
    );

    switch (field.schema) {
      case "dateTime":
        return (
          <input
            type="datetime-local"
            name={field.name}
            onChange={handleInputChange}
            value={requestPayload[field.name]}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "double":
      case "integer":
        return (
          <input
            type="number"
            name={field.name}
            onChange={handleInputChange}
            value={requestPayload[field.name]}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "string":
        return (
          <input
            type="text"
            name={field.name}
            onChange={handleInputChange}
            value={requestPayload[field.name]}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "boolean":
        return (
          <select
            name={field.name}
            onChange={handleInputChange}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
            value={requestPayload[field.name]}
          >
            <option value="">Select a value</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        );
      default:
        if (
          field.schema["@type"] === "Enum" &&
          field.schema.hasOwnProperty("enumValues")
        ) {
          return (
            <select
              name={field.name}
              onChange={handleInputChange}
              value={requestPayload[field.name]}
              className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
            >
              {field.schema.enumValues.map((enumValue) => (
                <option key={enumValue.enumValue} value={enumValue.enumValue}>
                  {enumValue.name}
                </option>
              ))}
            </select>
          );
        } else if (
          fieldCustomSchema &&
          fieldCustomSchema.length > 0 &&
          fieldCustomSchema[0]["@type"] === "Enum"
        ) {
          return (
            <select
              name={field.name}
              onChange={handleInputChange}
              value={requestPayload[field.name]}
              className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
            >
              <option value="">Select a value</option>
              {fieldCustomSchema[0].enumValues.map((enumValue) => (
                <option key={enumValue.enumValue} value={enumValue.enumValue}>
                  {enumValue.name}
                </option>
              ))}
            </select>
          );
        }
        return null;
    }
  };

  const renderValueForm = (action) => {
    if (!action) return null;

    switch (action.type) {
      case "monitor":
      case "sequence":
      case "interlock":
        const options =
          action.type === "monitor"
            ? monitors?.filter((e) => e?.workflowType == 0)
            : action.type === "sequence"
            ? sequences
            : interlocks;
        const setter = getSetter(action);
        const value = getValue(action);
        const label = `${
          action.type.charAt(0).toUpperCase() + action.type.slice(1)
        }`;
        return (
          <div className="grid grid-cols-2 gap-2 items-start">
            <div className="space-y-1 w-full flex flex-col flex-1 items-start gap-1">
              <label
                htmlFor="event"
                className="block text-sm font-medium text-gray-700"
              >
                {`Select ${label}`}
              </label>
              <CustomMultiSelect
                options={options.map((option) => ({
                  value: option._id || option.sequenceName,
                  label:
                    option.payload?.sequenceName ||
                    option.sequenceName ||
                    option.ruleName,
                }))}
                value={value}
                onChange={(value) => setter(value)}
                placeholder={`Select  ${label}`}
                enableSearch={true}
              />
              {/* <select
                className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                onChange={handleSelectionChange(setter)}
                value={value}
              >
                <option value="">{`Select a ${label}`}</option>
                {options.map((option, index) => (
                  <option key={index} value={option._id || option.id}>
                    {option.payload?.sequenceName ||
                      option.sequenceName ||
                      option.ruleName}
                  </option>
                ))}
              </select> */}
            </div>
          </div>
        );
      case "dtdl":
        return (
          <div className="grid grid-cols-3 gap-2 items-start">
            <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
              <label
                htmlFor="event"
                className="block text-sm font-medium text-gray-700"
              >
                Select Device
              </label>
              <select
                className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                onChange={(e) => updatePropertyList(e.target.value)}
                value={selectedDeviceName}
              >
                <option value="">Select a Device</option>
                {dtdlList.map((dtdl, index) => (
                  <option key={index} value={dtdl.value}>
                    {dtdl.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
              <label
                htmlFor="event"
                className="block text-sm font-medium text-gray-700"
              >
                Select Property
              </label>
              <select
                className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                onChange={handleSelectionChange(setSelectedDeviceProperty)}
                value={selectedDeviceProperty}
              >
                <option value="">Select a Property</option>
                {dtdlPropertyList.map((prop, index) => (
                  <option key={index} value={prop.value}>
                    {prop.label}
                  </option>
                ))}
              </select>
            </div>
            {selectedDeviceProperty && (
              <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
                <label
                  htmlFor="event"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Value
                </label>
                {renderInputField()}
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const getSetter = (action) => {
    let setter = null;
    switch (action.actionName) {
      case "EvaluateInterlock":
        setter = setSelectedInterlock;
        break;
      case "StartMonitor":
        setter = setSelectedStartMonitor;
        break;
      case "StopMonitor":
        setter = setSelectedStopMonitor;
        break;
      case "StartSequence":
        setter = setSelectedStartSequence;
        break;
      case "StopSequence":
        setter = setSelectedStopSequence;
        break;
      default:
        break;
    }
    return setter;
  };

  const getValue = (action) => {
    let setter = null;
    switch (action.actionName) {
      case "EvaluateInterlock":
        setter = selectedInterlock;
        break;
      case "StartMonitor":
        setter = selectedStartMonitor;
        break;
      case "StopMonitor":
        setter = selectedStopMonitor;
        break;
      case "StartSequence":
        setter = selectedStartSequence;
        break;
      case "StopSequence":
        setter = selectedStopSequence;
        break;
      default:
        break;
    }
    return setter;
  };

  useEffect(() => {
    console.log(editConditionIndex, "editConditionIndex");
  }, [editConditionIndex]);

  const handleEventActionEdit = async (
    event,
    action,
    expressionConditions,
    index
  ) => {
    setSelectedEvent(event?.eventName);
    setCurrentConditions(event?.conditions);
    setSelectedAction(action?.actionName);
    setExpressions(expressionConditions);
    const expressionRes = await ExpressionBuild(expressionConditions || []);
    const expression = expressionRes?.expression;
    setSelectedExprnCondition(expression);
    setEditConditionIndex(index);
    updateActionInputs(action?.actionName, expression, event?.conditions);
    setShowAddEvent(true);
  };

  const handleEventDelete = async (ev) => {
    try {
      const data = await DeleteObjectEventByName({
        objectId: selectedObject["$dtId"],
        eventName: ev?.eventName,
      });
      initObjectEvent();
      notifications.showNotification({
        title: "event deleted successfully",
        message: "",
        autoClose: 5000,
        color: "green",
        loading: false,
        disallowClose: false,
      });
      console.log("Command delete successful:", data);
      initObjectEvent();
      onUpdate(true);
    } catch (error) {
      console.error("Error save command:", error);
    }
  };

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 pt-2 rounded-lg shadow-lg w-4/6">
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          {children}
        </div>
      </div>
    );
  };

  const handleAddCondition = async ({ conditionExpressions, expression }) => {
    setExpressions(conditionExpressions);
    setSelectedExprnCondition(expression);
    setIsExpressionModalOpen(false);
    updateActionInputs(selectedAction, expression);
  };
  // const getDeviceName = (value) => {
  //   const selectedDevice = dtdlList?.find((x) => x.value == value);
  //   return selectedDevice.label;
  // };
  // const generateExpression = (expressions) => {
  //   let expression = ``;
  //   expressions.map((e, index) => {
  //     let exp = `${getDeviceName(e.object)}.${e?.property} ${e?.operator} ${
  //       e?.value
  //     } `;
  //     if (index !== 0) {
  //       exp = e?.outerCondition+ ' ' + exp ;
  //     }
  //     expression = expression + exp;
  //   });
  //   return expression;
  // };
  if (loading) {
    return <div className="text-center py-4">Loading ...</div>;
  }

  return (
    <>
      {!isShowAddEvent && (
        <div className="flex flex-col space-y-2 px-1">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-lg">Event Actions</h1>
            {currentEvents && (
              <button
                className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
                onClick={() => {
                  setShowAddEvent(true);
                  setSelectedEvent("");
                }}
              >
                Add Event
              </button>
            )}
          </div>
          <div className="flex flex-col bg-white space-y-2 p-2 overflow-auto h-[calc(100vh-354px)]">
            {currentEvents &&
              currentEvents?.map((event) => (
                <div className="border rounded px-2 py-1 space-y-1">
                  <div className="flex justify-between items-center pt-1">
                    <h1 className="font-semibold text-base">
                      on:{event.eventName}
                    </h1>
                    <div className="flex gap-2">
                      <button
                        className="flex items-center justify-center border rounded p-1 hover:bg-slate-100"
                        onClick={() => handleEventDelete(event)}
                      >
                        {<TrashIcon className="w-4 h-4 text-blue-300" />}
                      </button>
                    </div>
                  </div>
                  <div className="flex  flex-col gap-1">
                    {event.conditions?.map((condition, index) => (
                      <>
                        {condition?.eventActions?.map((action) => (
                          <EventCard
                            action={action}
                            interlocks={interlocks}
                            monitors={monitors}
                            sequences={sequences}
                            dtdlList={dtdlList}
                            expression={condition?.expression}
                            expressionConditions={
                              condition?.expressionConditions
                            }
                            onEventEdit={(action, expressionConditions) =>
                              handleEventActionEdit(
                                event,
                                action,
                                expressionConditions,
                                index
                              )
                            }
                          />
                        ))}
                      </>
                    ))}
                  </div>
                </div>
              ))}
            {(!currentEvents || currentEvents?.length == 0) && (
              <div className="flex flex-col items-center justify-center">
                <h2 className="text-xl font-bold text-gray-800 mb-2 mt-6">
                  Ooops! It's Empty
                </h2>
                <p className="text-gray-600 mb-6">
                  It looks like you don't have any event actions
                </p>
                <button
                  className="flex  h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
                  onClick={() => {
                    setShowAddEvent(true);
                    setSelectedEvent("");
                  }}
                >
                  + Add Event
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {isShowAddEvent && (
        <form onSubmit={saveObjectEvent} className="flex flex-col space-y-2 ">
          <div className="flex justify-between">
            <button
              className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
              onClick={() => {
                setShowAddEvent(false);
                resetSelections();
              }}
            >
              Back
            </button>
            <button
              disabled={isSaving}
              type="submit"
              className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
            >
              Save {isSaving && "..."}
            </button>
          </div>
          <div className=" h-[calc(100vh-354px)] overflow-auto">
            <div className="grid grid-cols-2 gap-2 items-start">
              <div className="space-y-1 flex flex-col items-start gap-1">
                <label
                  htmlFor="pre-execution"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Event
                </label>
                <select
                  className="  w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                  onChange={handleEventChange}
                  value={selectedEvent}
                >
                  <option className="text-gray-400" value="">
                    Select a Event
                  </option>
                  {commands.map((command, index) => (
                    <option key={index} value={command.name}>
                      on:{command.name}
                    </option>
                  ))}
                </select>
              </div>
              {selectedEvent && (
                <div className="space-y-1 flex flex-col items-start gap-1">
                  <label
                    htmlFor="event"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select Action
                  </label>
                  <select
                    className="p-2 mx-1 rounded border-[#ccc] border w-[calc(100%-8px)]"
                    onChange={handleActionChange}
                    value={selectedAction}
                  >
                    <option value="">Select a Actions</option>
                    {ACTIONS.map((command, index) => (
                      <option key={index} value={command.actionName}>
                        {command.actionName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {selectedAction && (
              <div className="flex flex-col flex-1 gap-1 ">
                <label
                  htmlFor="Expression"
                  className="block text-sm font-medium text-gray-700"
                >
                  Expression
                </label>
                <input
                  name="condition"
                  autoComplete="off"
                  title={selectedExprnCondition}
                  // onChange={(e)=>handleInputChange(e,isExpression)}
                  onClick={() => {
                    setIsExpressionModalOpen(true);
                  }}
                  value={selectedExprnCondition}
                  className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                />
              </div>
            )}

            {selectedAction &&
              renderValueForm(
                ACTIONS.find((action) => action.actionName === selectedAction)
              )}
          </div>
        </form>
      )}
      <Modal
        isOpen={isExpressionModalOpen}
        onClose={() => {
          setIsExpressionModalOpen(false);
        }}
      >
        {dtdlList && dtdlSchemaList && (
          <ExpressionBuilder
            dtdlList={dtdlList}
            dtdlSchemaList={dtdlSchemaList}
            onAddCondition={handleAddCondition}
            expressions={expressions}
            currentObject={selectedObject}
          />
        )}
      </Modal>
    </>
  );
};

export default EventPanel;
