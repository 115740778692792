import React from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import { useLocation } from "react-router-dom";

const sqns_url = process.env.REACT_APP_VIA_SEQUENCE;
// const sqns_url = "http://localhost:4202/sqns";
export default function Sqns(props) {
  const location = useLocation();  
  return (
    <div>
      {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign}/>}
      <div className="px-20 bg-blue-50 flex gap-4">
        <div className="flex-1">
        {location.pathname!="/" &&<TabNav tabs={props.layoutdesign.tabs} type="recipes"></TabNav>}
          <div className="theme-lite-bg pt-0 overflow-hidden">
            <iframe
              title="Events & Alarms"
              src={sqns_url}
              className="w-full h-[calc(100vh-230px)]"
            ></iframe>
          </div>
        </div>
      </div>
      {location.pathname!="/" && <Footer layoutdesign={props.layoutdesign}/>}
    </div>
  );
}