import React from 'react'
import E95Wafer from '../../../../Images/svg/e95Wafer'

function LP1(props) {
  return (
    <svg width="97" height="141" viewBox="0 0 97 141" fill="none" xmlns="http://www.w3.org/2000/svg" x={props.x} y={props.y}>
    <g id="Group 175">
      <path id="Vector" d="M71.0481 1.04943L71.0899 37.4212L88.4901 37.3974L88.6075 139.538L7.18422 139.65L7.0668 37.5091L25.1765 37.4842L25.1347 1.11238C45.2966 0.996056 50.8861 1.1658 71.0481 1.04943Z" fill="url(#paint0_linear_2102_160)" stroke="#646368" stroke-miterlimit="10"/>
      <g id="Rectangle 775" filter="url(#filter0_d_2102_160)">
      <rect x="31" y="54.6499" width="33" height="20" rx="3" fill="#D9D9D9"/>
      <rect x="31.5" y="55.1499" width="32" height="19" rx="2.5" stroke="#7A7A7D"/>
    </g>
    <g id="Rectangle 776" filter="url(#filter1_d_2102_160)">
      <rect x="25" y="63.6499" width="46" height="64" rx="3" fill="#D9D9D9"/>
      <rect x="25.5" y="64.1499" width="45" height="63" rx="2.5" stroke="#7A7A7D"/>
    </g>
    {/* <g id="Vector_2" filter="url(#filter2_d_2102_160)">
      <path d="M69.1551 90.1378C61.0968 102.434 45.1389 105.463 33.582 96.8887C22.025 88.3148 19.1787 71.3358 27.237 59.0393C35.2953 46.7428 51.2532 43.7143 62.8101 52.2883C74.3671 60.8623 77.2134 77.8413 69.1551 90.1378ZM41.5061 69.6253C39.38 78.2715 40.3603 75.2322 43.688 81.4675C50.0785 84.3253 47.2377 82.4868 54.9471 79.5971C55.748 71.0439 56.9951 74.9285 52.4951 66.7473C46.2717 64.2826 46.498 67.4099 41.5061 69.6253Z" fill="white"/>
    </g>
    <path id="Vector_3" d="M49.0352 95.621C48.9974 95.0476 49.3749 94.5404 49.8778 94.4889C59.4229 93.5111 66.6044 83.8621 65.8873 72.9779C65.8495 72.4045 66.2261 71.8974 66.7299 71.8458C67.2337 71.7942 67.6716 72.218 67.7094 72.7914C68.502 84.8205 60.5638 95.4861 50.0147 96.5656C49.5118 96.6181 49.0731 96.1955 49.0352 95.621Z" fill="#808080"/> */}
    <path id="Vector_4" d="M52.939 67.06C56.7908 69.9176 57.7403 75.5773 55.054 79.6765C52.3682 83.7747 47.0481 84.7844 43.1963 81.9268C39.3437 79.0686 38.3955 73.4086 41.0813 69.3103C43.7676 65.2112 49.0864 64.2018 52.939 67.06ZM44.2788 80.2749C47.2752 82.4979 51.4122 81.7128 53.5015 78.5247C55.5902 75.3374 54.8528 70.9348 51.8565 68.7118C48.8609 66.4894 44.7225 67.2748 42.6338 70.4621C40.5445 73.6502 41.2832 78.0525 44.2788 80.2749Z" fill="#2F3034"/>
    <path id="Vector_5" d="M46.5889 71.6844C45.3513 72.6369 45.0464 74.5238 45.9094 75.8899C46.7723 77.256 48.4818 77.5925 49.7194 76.64C50.9571 75.6874 51.262 73.8006 50.399 72.4345C49.536 71.0684 47.8266 70.7318 46.5889 71.6844ZM48.6759 74.9881C48.2636 75.3054 47.6934 75.1932 47.4059 74.7381C47.1184 74.283 47.2201 73.6536 47.6324 73.3362C48.0447 73.0189 48.615 73.1312 48.9025 73.5863C49.1899 74.0414 49.0882 74.6708 48.6759 74.9881Z" fill="#2F3034"/>
    <g id="Group 171">
    <path id="Vector_6" d="M11.7206 136.908C11.7414 136.945 11.7785 136.97 11.8203 136.97H15.5953C15.6371 136.97 15.6742 136.945 15.6951 136.908L17.5826 133.398C17.6035 133.361 17.6035 133.311 17.5826 133.274L15.6951 129.765C15.6742 129.728 15.6371 129.703 15.5953 129.703H11.8203C11.7785 129.703 11.7414 129.728 11.7206 129.765L9.83303 133.274C9.81217 133.311 9.81217 133.361 9.83303 133.398L11.7206 136.908ZM11.8875 129.952H15.5281L17.3484 133.336L15.5281 136.721H11.8875L10.0672 133.336L11.8875 129.952Z" fill="#646368"/>
    <path id="Vector_7" d="M13.7073 134.988C14.5314 134.988 15.2036 134.246 15.2036 133.336C15.2036 132.427 14.5314 131.685 13.7073 131.685C12.8831 131.685 12.2109 132.427 12.2109 133.336C12.2109 134.246 12.8831 134.988 13.7073 134.988ZM13.7073 131.939C14.4048 131.939 14.9734 132.566 14.9734 133.336C14.9734 134.106 14.4048 134.734 13.7073 134.734C13.0097 134.734 12.4411 134.106 12.4411 133.336C12.4411 132.566 13.0097 131.939 13.7073 131.939Z" fill="#646368"/>
    </g>
    <g id="Group 172">
    <path id="Vector_8" d="M11.4089 50.0222C11.4264 50.0535 11.4574 50.0743 11.4923 50.0743H14.6493C14.6842 50.0743 14.7152 50.0535 14.7326 50.0222L16.3111 47.0877C16.3286 47.0565 16.3286 47.0149 16.3111 46.9836L14.7326 44.0491C14.7152 44.0179 14.6842 43.9971 14.6493 43.9971H11.4923C11.4574 43.9971 11.4264 44.0179 11.4089 44.0491L9.83047 46.9836C9.81302 47.0149 9.81302 47.0565 9.83047 47.0877L11.4089 50.0222ZM11.5486 44.2052H14.593L16.1153 47.0357L14.593 49.8661H11.5486L10.0263 47.0357L11.5486 44.2052Z" fill="#646368"/>
    <path id="Vector_9" d="M13.0716 48.4171C13.7608 48.4171 14.3229 47.7967 14.3229 47.036C14.3229 46.2753 13.7608 45.6548 13.0716 45.6548C12.3824 45.6548 11.8203 46.2753 11.8203 47.036C11.8203 47.7967 12.3824 48.4171 13.0716 48.4171ZM13.0716 45.8673C13.6549 45.8673 14.1304 46.3921 14.1304 47.036C14.1304 47.6798 13.6549 48.2047 13.0716 48.2047C12.4883 48.2047 12.0128 47.6798 12.0128 47.036C12.0128 46.3921 12.4883 45.8673 13.0716 45.8673Z" fill="#646368"/>
    </g>
    <g id="Group 173">
    <path id="Vector_10" d="M79.621 136.908C79.6418 136.945 79.6789 136.97 79.7207 136.97H83.4957C83.5375 136.97 83.5746 136.945 83.5954 136.908L85.483 133.398C85.5038 133.361 85.5038 133.311 85.483 133.274L83.5954 129.765C83.5746 129.728 83.5375 129.703 83.4957 129.703H79.7207C79.6789 129.703 79.6418 129.728 79.621 129.765L77.7334 133.274C77.7126 133.311 77.7126 133.361 77.7334 133.398L79.621 136.908ZM79.7879 129.952H83.4285L85.2488 133.336L83.4285 136.721H79.7879L77.9676 133.336L79.7879 129.952Z" fill="#646368"/>
    <path id="Vector_11" d="M81.6076 134.988C82.4318 134.988 83.104 134.246 83.104 133.336C83.104 132.427 82.4318 131.685 81.6076 131.685C80.7835 131.685 80.1113 132.427 80.1113 133.336C80.1113 134.246 80.7835 134.988 81.6076 134.988ZM81.6076 131.939C82.3052 131.939 82.8738 132.566 82.8738 133.336C82.8738 134.106 82.3052 134.734 81.6076 134.734C80.9101 134.734 80.3415 134.106 80.3415 133.336C80.3415 132.566 80.9101 131.939 81.6076 131.939Z" fill="#646368"/>
    </g>
    <g id="Group 174">
    <path id="Vector_12" d="M77.5457 50.0222C77.5631 50.0535 77.5941 50.0743 77.629 50.0743H80.786C80.8209 50.0743 80.8519 50.0535 80.8694 50.0222L82.4478 47.0877C82.4653 47.0565 82.4653 47.0149 82.4478 46.9836L80.8694 44.0491C80.8519 44.0179 80.8209 43.9971 80.786 43.9971H77.629C77.5941 43.9971 77.5631 44.0179 77.5457 44.0491L75.9672 46.9836C75.9497 47.0149 75.9497 47.0565 75.9672 47.0877L77.5457 50.0222ZM77.6853 44.2052H80.7297L82.252 47.0357L80.7297 49.8661H77.6853L76.163 47.0357L77.6853 44.2052Z" fill="#646368"/>
    <path id="Vector_13" d="M79.2083 48.4171C79.8975 48.4171 80.4597 47.7967 80.4597 47.036C80.4597 46.2753 79.8975 45.6548 79.2083 45.6548C78.5192 45.6548 77.957 46.2753 77.957 47.036C77.957 47.7967 78.5192 48.4171 79.2083 48.4171ZM79.2083 45.8673C79.7916 45.8673 80.2671 46.3921 80.2671 47.036C80.2671 47.6798 79.7916 48.2047 79.2083 48.2047C78.625 48.2047 78.1495 47.6798 78.1495 47.036C78.1495 46.3921 78.625 45.8673 79.2083 45.8673Z" fill="#646368"/>
    </g>
    <g onClick={()=> props.optionmodel(props.e95options)}>
    {props.e95State?.find(o => o.name === "LP1").currentValue && <E95Wafer x={9} y={35} fill={props.waferState}  positionname="Load Port 1"  channelname="LP1.WfrPresence"   
            waferID={props.e95State?.find(o => o.name === "PM1.WaferID").currentValue} wafermodel={props.wafermodelchange}/>}
    <path id="Rectangle 777" opacity="0.75" d="M2.30097 40.1499H94.699C95.6937 40.1499 96.5 40.9562 96.5 41.9509V70.1499C96.5 96.6596 75.0097 118.15 48.5 118.15C21.9903 118.15 0.5 96.6596 0.5 70.1499V41.9509C0.5 40.9562 1.30632 40.1499 2.30097 40.1499Z" fill="#94a3b8" stroke="#6b7280"/>
    </g>

    {/* {props.e95State?.find(o => o.name === "LP1").currentValue && <E95Wafer x={9} y={35} fill={props.waferState}  positionname="Load Port 1"  channelname="LP1.WfrPresence"   
            waferID={props.e95State?.find(o => o.name === "PM1.WaferID").currentValue} wafermodel={props.wafermodelchange}/>} */}
    
    </g>
    <defs>
    <filter id="filter0_d_2102_160" x="19" y="42.6499" width="53" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="-2"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2102_160"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2102_160" result="shape"/>
    </filter>
    <filter id="filter1_d_2102_160" x="13" y="51.6499" width="66" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="-2"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2102_160"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2102_160" result="shape"/>
    </filter>
    <filter id="filter2_d_2102_160" x="18.6533" y="47.4116" width="59.085" height="62.354" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2102_160"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2102_160" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_2102_160" x1="79.129" y1="153.519" x2="-10.3221" y2="2.03317" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8C898C"/>
    <stop offset="0.25" stop-color="#B8B7B9"/>
    <stop offset="0.495" stop-color="#CAC9CB"/>
    <stop offset="0.695" stop-color="#B6B4B9"/>
    <stop offset="1" stop-color="#8B8B8B"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default LP1