import React, { useState, useEffect } from "react";

import {
  GetObjectMonitors,
  monitorWorkflowDisable,
  monitorWorkflowEnable,
  monitorWorkflowStart,
  monitorWorkflowStop,
} from "../../api/object";

const WorkflowStatus = {
  0: "Created",
  1: "Started",
  2: "Running",
  3: "Completed",
  4: "Failed",
  5: "Stopped",
  6: "Paused",
};

const MonitorsList = ({ selectedObject,refreshWindow }) => {
  const [currentMonitors, setMonitors] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedObject) {
      fetchObjectMonitors();
    }
  }, [selectedObject]);

  const fetchObjectMonitors = async () => {
    setLoading(true);
    try {
      const data = await GetObjectMonitors({
        objectId: selectedObject["$dtId"],
      });
      setMonitors(data);
    } catch (error) {
      console.log(error, "getObjectError");
    }
    setLoading(false);
  };

  const handleToggle = async (value, id) => {
    console.log(value, id);
    setLoading(true);
    if (value == false) {
      await enableMonitor(id);
    } else {
      await disableMonitor(id);
    }
    fetchObjectMonitors();

    return;
  };

  const enableMonitor = async (id) => {
    try {
      const response = await monitorWorkflowEnable(id);
      const res = await monitorWorkflowStart(id)
      // console.log(response, "response",res);
    } catch (error) {
      console.error("Error updating switch state:", error);
    }
  };

  const disableMonitor = async (id) => {
    try {
      const res = await monitorWorkflowStop(id)
      const response = await monitorWorkflowDisable(id);
      // console.log(response, "response",res);
    } catch (error) {
      console.error("Error updating switch state:", error);
    }
  };

  const MonitorCard = (monitors) => {
    return (
      <div className="relative overflow-x-auto">
        <table className="w-full table-fixed text-sm text-left rtl:text-right text-gray-500 cursor-pointer">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th
                scope="col"
                className="sticky top-0 bg-[#B6D3EA] px-4 py-2 w-4/12"
              >
                Name
              </th>
              <th
                scope="col"
                className="sticky top-0 bg-[#B6D3EA] px-3 py-2 w-4/12"
              >
                Description
              </th>
              <th
                scope="col"
                className="sticky top-0 bg-[#B6D3EA] px-3 py-2 w-4/12"
              >
                Expression
              </th>
              <th
                scope="col"
                className="sticky top-0 bg-[#B6D3EA] px-3 py-2 w-[86px]"
              >
                Status
              </th>
              <th
                scope="col"
                className="sticky top-0 bg-[#B6D3EA] px-3 py-2 w-20"
              >
                enable
              </th>
            </tr>
          </thead>
          <tbody>
            {monitors?.data?.map((data, index) => (
              <tr key={index} className="bg-white border-b ">
                <td className="px-4 py-[2px] font-medium text-gray-900">
                  <div className="w-full">
                    <span className="block truncate" title={data.name}>
                      {data.name}
                    </span>
                  </div>
                </td>
                <td className="px-2 py-[2px]">
                  <div className="w-full">
                    <span className="block truncate" title={data.description}>
                      {data.description}
                    </span>
                  </div>
                </td>
                <td className="px-2 py-[2px]">
                  <div className="w-full">
                    <span className="block truncate" title={data.expression}>
                      {data.expression}
                    </span>
                  </div>
                </td>
                <td className="px-2 py-[2px]">
                  <div className="w-full">
                    <span
                      className="block truncate"
                      title={WorkflowStatus[data.status]}
                    >
                      {WorkflowStatus[data.status]}
                    </span>
                  </div>
                </td>
                <td className="px-2 py-[2px]">
                  <div className="w-full flex justify-center items-center">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={data.enabled}
                        onChange={() => handleToggle(data.enabled, data._id)}
                      />
                      <div
                        className={` w-8 h-4 
        bg-gray-200 
        peer-focus:outline-none 
        peer-focus:ring-2 
        peer-focus:ring-blue-300 
        dark:peer-focus:ring-blue-800 
        rounded-full 
        peer 
        peer-checked:after:translate-x-full 
        peer-checked:after:border-white 
        after:content-[''] 
        after:absolute 
        after:top-[1px] 
        after:left-[1px] 
        after:bg-white 
        after:border-gray-300 
        after:border 
        after:rounded-full 
        after:h-3.5 
        after:w-3.5 
        after:transition-all 
        dark:border-gray-600 
        peer-checked:bg-blue-600`}
                      ></div>
                      {/* <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                        {data.enabled ? "On" : "Off"}
                      </span> */}
                    </label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return <div className="text-center py-4">Loading ...</div>;
  }

  return (
    <>
      <div className="flex flex-col space-y-2 px-1">
        <div className="flex flex-col bg-white space-y-2 p-2 overflow-auto h-[calc(100vh-324px)]">
          {currentMonitors && <MonitorCard data={currentMonitors} />}
          {(!currentMonitors || currentMonitors?.length == 0) && (
            <div className="flex flex-col items-center justify-center">
              <h2 className="text-xl font-bold text-gray-800 mb-2 mt-6">
                Ooops! It's Empty
              </h2>
              <p className="text-gray-600 mb-6">
                It looks like you don't have any Monitors
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MonitorsList;
