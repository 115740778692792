import React from 'react';

  export default function Pm3(props) { 
    return (
      <svg width="157" height="160" viewBox="0 0 157 160" fill="none" x={props.x} y={props.y}>
<g filter="url(#filter0_i_33_59)">
<path d="M5.12949 114.203L22.4187 96.8184L4.9834 80.7767L78.9767 6.79417L155.983 81.4373C131.542 105.988 108.609 129.188 84.1675 153.739L67.1053 137.705C54.6162 150.271 61.6176 144.228 49.1285 156.794L5.12949 114.203Z" fill="url(#paint0_linear_33_59)"/>
</g>
<path d="M5.12949 114.203L22.4187 96.8184L4.9834 80.7767L78.9767 6.79417L155.983 81.4373C131.542 105.988 108.609 129.188 84.1675 153.739L67.1053 137.705C54.6162 150.271 61.6176 144.228 49.1285 156.794L5.12949 114.203Z" stroke="#646368" stroke-miterlimit="10"/>
<g filter="url(#filter1_i_33_59)">
<path d="M80.6707 143.756L109.714 113.921C116.41 105.632 120.026 95.2804 119.948 84.6265C119.869 73.9726 116.1 63.675 109.282 55.4865C93.7712 37.6987 65.0986 35.165 45.4206 51.3671L16.3777 81.2021L80.6707 143.756Z" fill="#76777A"/>
</g>
<path d="M119.448 84.6302C119.526 95.1608 115.954 105.393 109.339 113.589L80.6611 143.049L17.0848 81.1925L45.7596 51.7356C65.2342 35.7198 93.5797 38.2447 108.902 55.8109C115.643 63.9099 119.37 74.0938 119.448 84.6302Z" stroke="#867B7B"/>
<rect x="50.4447" y="31.3535" width="3.55581" height="62.8886" rx="0.75" transform="rotate(45.8075 50.4447 31.3535)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<rect x="149.793" y="85.3535" width="3.55581" height="62.8886" rx="0.75" transform="rotate(44.9625 149.793 85.3535)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<g filter="url(#filter2_d_33_59)">
<rect x="89.949" y="147.708" width="9.2601" height="107.996" rx="1" transform="rotate(133.081 89.949 147.708)" fill="url(#paint1_linear_33_59)" shape-rendering="crispEdges"/>
<rect x="89.5956" y="147.72" width="8.7601" height="107.496" rx="0.75" transform="rotate(133.081 89.5956 147.72)" stroke="#646368" stroke-width="0.5" shape-rendering="crispEdges"/>
</g>
<g filter="url(#filter3_i_33_59)">
<path d="M61.8192 142.473L57.6378 147.073L13.7342 106.096L18.3336 101.497L61.8192 142.473Z" fill="#E4E4E4"/>
</g>
<g filter="url(#filter4_ii_33_59)">
<path d="M75.9834 114.794C93.6565 114.794 107.983 100.467 107.983 82.7942C107.983 65.1211 93.6565 50.7942 75.9834 50.7942C58.3103 50.7942 43.9834 65.1211 43.9834 82.7942C43.9834 100.467 58.3103 114.794 75.9834 114.794Z" fill="#E6E6E6"/>
</g>
<path d="M75.9834 115.294C93.9326 115.294 108.483 100.743 108.483 82.7942C108.483 64.8449 93.9326 50.2942 75.9834 50.2942C58.0341 50.2942 43.4834 64.8449 43.4834 82.7942C43.4834 100.743 58.0341 115.294 75.9834 115.294Z" stroke="#B8B7B7"/>
<g filter="url(#filter5_i_33_59)">
<rect x="95.9834" y="81.7942" width="2" height="3" rx="1" fill="#D9D9D9"/>
</g>
<g filter="url(#filter6_i_33_59)">
<rect x="74.9834" y="60.7942" width="3" height="4" rx="1" fill="#D9D9D9"/>
</g>
<g filter="url(#filter7_i_33_59)">
<rect x="72.9834" y="79.7942" width="6" height="6" rx="3" fill="#767070"/>
</g>
<g filter="url(#filter8_i_33_59)">
<rect x="61.9834" y="74.7942" width="4" height="3" rx="1" fill="#D9D9D9"/>
</g>
<g filter="url(#filter9_i_33_59)">
<rect x="74.9834" y="101.794" width="3" height="2" rx="1" fill="#D9D9D9"/>
</g>
<g filter="url(#filter10_d_33_59)">
<rect x="66.2217" y="138.355" width="4.63578" height="60.0849" transform="rotate(133.081 66.2217 138.355)" fill="#D9D9D9"/>
</g>
<path d="M76.781 28.5385C76.8093 28.5844 76.8595 28.6149 76.9161 28.6149H82.0323C82.0889 28.6149 82.1392 28.5844 82.1674 28.5385L84.7255 24.2299C84.7538 24.1841 84.7538 24.123 84.7255 24.0771L82.1674 19.7685C82.1392 19.7227 82.0889 19.6922 82.0323 19.6922H76.9161C76.8595 19.6922 76.8093 19.7227 76.781 19.7685L74.2229 24.0771C74.1946 24.123 74.1946 24.1841 74.2229 24.2299L76.781 28.5385ZM77.0072 19.9977H81.9412L84.4081 24.1535L81.9412 28.3094H77.0072L74.5403 24.1535L77.0072 19.9977Z" fill="#646368"/>
<path d="M79.4742 26.1814C80.5911 26.1814 81.5021 25.2704 81.5021 24.1535C81.5021 23.0366 80.5911 22.1256 79.4742 22.1256C78.3573 22.1256 77.4463 23.0366 77.4463 24.1535C77.4463 25.2704 78.3573 26.1814 79.4742 26.1814ZM79.4742 22.4376C80.4195 22.4376 81.1901 23.2082 81.1901 24.1535C81.1901 25.0989 80.4195 25.8695 79.4742 25.8695C78.5289 25.8695 77.7583 25.0989 77.7583 24.1535C77.7583 23.2082 78.5289 22.4376 79.4742 22.4376Z" fill="#646368"/>
<path d="M131.129 82.8865C131.157 82.9323 131.207 82.9629 131.264 82.9629H136.38C136.437 82.9629 136.487 82.9323 136.515 82.8865L139.073 78.5779C139.102 78.532 139.102 78.4709 139.073 78.4251L136.515 74.1165C136.487 74.0706 136.437 74.0401 136.38 74.0401H131.264C131.207 74.0401 131.157 74.0706 131.129 74.1165L128.571 78.4251C128.542 78.4709 128.542 78.532 128.571 78.5779L131.129 82.8865ZM131.355 74.3457H136.289L138.756 78.5015L136.289 82.6573H131.355L128.888 78.5015L131.355 74.3457Z" fill="#646368"/>
<path d="M133.822 80.5294C134.939 80.5294 135.85 79.6184 135.85 78.5015C135.85 77.3846 134.939 76.4736 133.822 76.4736C132.705 76.4736 131.794 77.3846 131.794 78.5015C131.794 79.6184 132.705 80.5294 133.822 80.5294ZM133.822 76.7855C134.767 76.7855 135.538 77.5561 135.538 78.5015C135.538 79.4468 134.767 80.2174 133.822 80.2174C132.877 80.2174 132.106 79.4468 132.106 78.5015C132.106 77.5561 132.877 76.7855 133.822 76.7855Z" fill="#646368"/>
<rect x="87.4937" y="15.2391" width="3.55581" height="91.0104" rx="0.75" transform="rotate(-45.7189 87.4937 15.2391)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<rect x="77.3423" y="5.02793" width="3.47207" height="14.9469" rx="0.75" transform="rotate(-44.7585 77.3423 5.02793)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<rect x="74.1267" y="11.856" width="5.4396" height="10.2353" rx="0.75" transform="rotate(-135.755 74.1267 11.856)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<g filter="url(#filter11_d_33_59)">
<rect x="60.6658" y="13.0233" width="10.7977" height="10.1015" rx="1" transform="rotate(-45.3876 60.6658 13.0233)" fill="#D9D9D9"/>
<rect x="61.0193" y="13.0209" width="10.2977" height="9.60147" rx="0.75" transform="rotate(-45.3876 61.0193 13.0209)" stroke="#646368" stroke-width="0.5"/>
</g>
<defs>
<filter id="filter0_i_33_59" x="4.26123" y="6.09256" width="152.434" height="161.404" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="10"/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
</filter>
<filter id="filter1_i_33_59" x="16.3777" y="40.5589" width="103.572" height="107.197" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
</filter>
<filter id="filter2_d_33_59" x="0.158447" y="73.3575" width="90.3772" height="85.7008" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_59"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_59" result="shape"/>
</filter>
<filter id="filter3_i_33_59" x="13.7341" y="101.497" width="49.085" height="46.5762" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1" dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
</filter>
<filter id="filter4_ii_33_59" x="38.9834" y="45.7942" width="73" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="3" dy="5"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-4" dy="-4"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_33_59" result="effect2_innerShadow_33_59"/>
</filter>
<filter id="filter5_i_33_59" x="95.9834" y="81.7942" width="2" height="4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
</filter>
<filter id="filter6_i_33_59" x="74.9834" y="60.7942" width="3" height="5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
</filter>
<filter id="filter7_i_33_59" x="72.9834" y="79.7942" width="6" height="7" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
</filter>
<filter id="filter8_i_33_59" x="61.9834" y="74.7942" width="4" height="4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
</filter>
<filter id="filter9_i_33_59" x="74.9834" y="101.794" width="3" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_59"/>
</filter>
<filter id="filter10_d_33_59" x="14.1697" y="96.3152" width="53.052" height="50.4257" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_59"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_59" result="shape"/>
</filter>
<filter id="filter11_d_33_59" x="60.0798" y="5.75088" width="17.946" height="17.9525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1" dy="2"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_59"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_59" result="shape"/>
</filter>
<linearGradient id="paint0_linear_33_59" x1="95.5424" y1="2.21851" x2="43.594" y2="168.937" gradientUnits="userSpaceOnUse">
<stop offset="0.0368962" stop-color="#B7B6B6"/>
<stop offset="0.404543" stop-color="#D3D3D3"/>
<stop offset="0.657325" stop-color="#BDBDBF"/>
<stop offset="1" stop-color="#C2C2C3"/>
</linearGradient>
<linearGradient id="paint1_linear_33_59" x1="85.1442" y1="126.021" x2="107.066" y2="268.222" gradientUnits="userSpaceOnUse">
<stop stop-color="#D9D9D9"/>
<stop offset="0.44579" stop-color="#F1F1F1" stop-opacity="0.841073"/>
<stop offset="0.627308" stop-color="#BCBCBC" stop-opacity="0.755"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

      
        
    )
  }