import React, { useState, useEffect, useRef } from "react";
import { useNotifications } from '@mantine/notifications'

export default function ObjectConstants({ selectedObject, refreshWindow }) {

  const notifications = useNotifications()
  const [objectConst, setObjectConst] = useState({});
  const [oldData, setOldData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [addConstModal, setAddConstModal] = useState(false);
  const [newConstantName, setNewConstantName] = useState("");
  const [newConstantVal, setNewConstantVal] = useState("");
  const [currentObjId, setCurrentObjId] = useState(selectedObject['$dtId']);
  const [editedConsts, setEditedConsts] = useState(null);


  const fetchObjectConst = async () => {
    try {
      // Replace 'API_ENDPOINT' with the actual endpoint URL
      const API_URL = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectConstant/${selectedObject["$dtId"]}`;
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      const data = await response.json();
      // console.log(data,'fetchObjectConst')
      setObjectConst(data);
      setOldData(data)
    } catch (error) {
      setError(error.message);
    }
  };

  const addObjectConst = async (edit) => {
    let constsData = null
    let notificationText = "Constant added successfully!"
    if(edit) {
      constsData = JSON.stringify(editedConsts)
      notificationText = "Constant updated successfully!"
    } else {
      constsData = JSON.stringify({
        [newConstantName]: newConstantVal 
      })
    }
    try {
      const API_URL = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectConstant/${currentObjId}`;
      const response = await fetch(API_URL, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
        },
        body: constsData,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      const data = await response.json();
      console.log('add ObjectConst', data)
      fetchObjectConst()
      setNewConstantName("")
      setNewConstantVal("")
      setAddConstModal(false)
      refreshWindow()
      notifications.showNotification({
        title: notificationText,
        message: '',
        autoClose: 5000,
        color: 'green',
        loading: false,
        disallowClose: false,
      })
    } catch (error) {
      setError(error.message);
    }
  };

  const init = async () => {
    setIsLoading(true);
    setObjectConst({})
    fetchObjectConst()
  };

  const setupEditedConsts = (editedKey, editedVal) => {
    setEditedConsts(prevState => ({
      ...prevState, 
      [editedKey]: editedVal
    }));
  };

  const revertChanges = () => {
    setEditedConsts(null)
    setObjectConst({})
    setTimeout(() => {
      setObjectConst(oldData)
    }, 0)
  }

  useEffect(() => {
    setCurrentObjId(selectedObject['$dtId'])
    init();
  }, [selectedObject]);


  return (
    <div>
    <div className="h-[calc(100vh-365px)] bg-white overflow-y-auto grid ">
    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr className="divide-x-2 w-full">
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-4 py-2 w-1/2">
                Name
              </th>
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-3 py-2 w-1/2">
                Value
              </th>
              
            </tr>
          </thead>
          <tbody >
            {Object.keys(objectConst).map((key,index) => (
              <tr key={index} class="bg-white border-b ">
                <th
                  scope="row"
                  class="px-4 py-[2px] font-medium text-gray-900 whitespace-nowrap "
                >
                    {key}
                </th>
                <td class="px-2 py-[2px] text-gray-900">
                  <input
                    className="p-1  rounded border-0 border-[#ccc]  w-[calc(100%-1px)]"
                    type="text"
                    name={key}
                    onChange={e => setupEditedConsts(key, e.target.value)}
                    defaultValue={objectConst[key]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
            
      </div>
        <div className="flex gap-2 mt-4 justify-end items-center ">
          <button
            type="button"
            className=" h-10 px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner uppercase font-semibold"
            onClick={() => setAddConstModal(!addConstModal)}
          >
            Add Constant
          </button>
          <button
            type="submit"
            className=" h-10 px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner uppercase font-semibold"
            onClick={() => addObjectConst(true)}
            disabled={editedConsts === null}
          >
            Save
          </button>
          <button onClick={revertChanges} disabled={editedConsts === null} className=" h-10 px-4 py-2 bg-[#EBF0F4] rounded shadow hover:shadow-inner uppercase font-semibold">
            Cancel
          </button>
        </div>

        {addConstModal &&
          <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
            <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setAddConstModal(!addConstModal)}></div>
            <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
              
              <div className="-mt-7 mb-3 flex">
                <div className="flex-1">
                  <div className="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Add Constant</div>
                </div>
                
                <div>
                  <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setAddConstModal(!addConstModal)}>✖</button>
                </div>
              </div>

              <div className="flex flex-col mt-5 gap-5">
                <div className="flex gap-3">
                  <label className="w-48">Name:</label>
                  <input type="text" className="dysing-input flex-1 p-1 px-2 rounded" onChange={(e) => setNewConstantName(e.target.value)} />
                </div>
                <div className="flex gap-3">
                  <label className="w-48">Value:</label>
                  <input type="text" className="dysing-input flex-1 p-1 px-2 rounded" onChange={(e) => setNewConstantVal(e.target.value)} />
                </div>
                <div className="grid grid-cols-2 gap-3 mt-5">
                  <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={() => addObjectConst(false)} disabled={!newConstantName || !newConstantVal}>Add</button>
                  <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={() => setAddConstModal(!addConstModal)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
    }
      </div>
  );
}
