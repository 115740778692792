import { PencilSquareIcon } from "@heroicons/react/16/solid";

export default function EventCard({action,onEventEdit,interlocks,monitors,sequences,dtdlList,expression,expressionConditions}) {
    const getInputLabel = (value) => {
        let label = "";
        switch (value) {
          case "EvaluateInterlock":
            label = "Selected Interlock";
            break;
          case "StartMonitor":
            label = "Selected Monitors";
            break;
          case "StopMonitor":
            label = "Selected Monitors";
            break;
          case "StartSequence":
            label = "Selected Sequence";
            break;
          case "StopSequence":
            label = "Selected Sequence";
            break;
          default:
            break;
        }
        return label;
      };
    const getInputValue = (actionName, val) => {
        let value = "";
        switch (actionName) {
          case "EvaluateInterlock":
            const selInterLock = interlocks?.find((e) => e.id == val);
            value = selInterLock?.name;
            break;
          case "StartMonitor":
            const selStartMonitor = monitors?.find((e) => e._id == val);
            value = selStartMonitor?.payload?.sequenceName;
            break;
          case "StopMonitor":
            const selStopMonitor = monitors?.find((e) => e._id == val);
            value = selStopMonitor?.payload?.sequenceName;
            break;
          case "StartSequence":
            const selStartSequence = sequences?.find((e) => e.sequenceName == val);
            value = selStartSequence?.sequenceName;
            break;
          case "StopSequence":
            const selStopSequence = sequences?.find((e) => e.sequenceName == val);
            value = selStopSequence?.sequenceName;
            break;
          case "SetObjectProperty":
            const selectedDevice = dtdlList?.find((e) => e.value == val);
            value = selectedDevice?.label;
            break;
          default:
            break;
        }
        return value;
      };
    

    return (
        <div className="flex flex-col bg-slate-100 border rounded px-2 space-y-1">
                            <div className="flex justify-between items-center pt-1">
                              <div className="font-semibold">
                                {action.actionName}
                              </div>
                              <div className="flex gap-2">
                                <button
                                  className="flex items-center justify-center border rounded p-1 hover:bg-white"
                                  onClick={() =>
                                    onEventEdit( action,expressionConditions)
                                  }
                                >
                                  {
                                    <PencilSquareIcon className="w-4 h-4 text-blue-300" />
                                  }
                                </button>
                              </div>
                            </div>
                            <div className="font-semibold text-base">{expression}</div>
                            {Array.isArray(action?.input) && (
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">
                                  {getInputLabel(action.actionName)}
                                </p>
                                <div className="flex flex-wrap gap-2">
                                  {action?.input?.map((v, index) => (
                                    <div
                                      key={index}
                                      className="flex flex-wrap "
                                    >
                                      {getInputValue(action.actionName, v)}
                                      {index < action.input.length - 1 && ", "}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            {!Array.isArray(action?.input) && (
                              <div className="flex flex-wrap gap-2">
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-semibold">
                                    Selected Device
                                  </p>
                                  <p>
                                    {getInputValue(
                                      action.actionName,
                                      action?.input?.id
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-semibold">
                                    Selected Property
                                  </p>
                                  <p>{action?.input?.property}</p>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-semibold">
                                    Selected value
                                  </p>
                                  <p>{action?.input?.value?.toString()}</p>
                                </div>
                              </div>
                            )}
                          </div>
    )
}