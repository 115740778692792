import React from 'react'
import E95Wafer from '../../../../Images/svg/e95Wafer';

function Lp4(props) {
  return (
    <svg width="121" height="139" viewBox="0 0 121 139" fill="none" xmlns="http://www.w3.org/2000/svg" x={props.x} y={props.y}>
    <g id="Group 170">
    <path id="Vector" d="M85.8995 1.04866L85.9419 36.9879L103.447 36.9645L103.566 137.891L21.6527 138L21.5336 37.074L39.7523 37.0496L39.7099 1.11038C59.9931 0.995655 65.6163 1.16344 85.8995 1.04866Z" fill="url(#paint0_linear_2101_138)" stroke="#646368" stroke-miterlimit="10"/>
    <g id="Rectangle 775" filter="url(#filter0_d_2101_138)">
    <rect x="46" y="63" width="35" height="21" rx="3" fill="#D9D9D9"/>
    <rect x="46.5" y="63.5" width="34" height="20" rx="2.5" stroke="#7A7A7D"/>
    </g>
    <g onClick={()=>props.optionmodel(props.e95options)}>
        <g id="Rectangle 776" filter="url(#filter1_d_2101_138)">
        <rect x="42" y="73" width="44" height="54" rx="3" fill="#D9D9D9"/>
        <rect x="42.5" y="73.5" width="43" height="53" rx="2.5" stroke="#7A7A7D"/>
        </g>

        {/* {props.e95State?.find(o => o.name === "LP2").currentValue && 
        <E95Wafer x={24} y={44}  fill={props.waferState} positionname="Load Port 2"  channelname="LP2.WfrPresence"     
        waferID={props.e95State?.find(o => o.name === "PM1.WaferID").currentValue}  />} */}

    </g>
    

    <g id="Rectangle 777" filter="url(#filter2_d_2101_138)">
    <rect x="12" y="44" width="101" height="11" rx="3" fill="#D9D9D9"/>
    <rect x="12.5" y="44.5" width="100" height="10" rx="2.5" stroke="#7A7A7D"/>
    </g>
    <g id="Group 166">
    <path id="Vector_2" d="M26.4421 134.339C26.4632 134.376 26.5009 134.4 26.5432 134.4H30.3702C30.4125 134.4 30.4501 134.376 30.4713 134.339L32.3848 130.872C32.406 130.835 32.406 130.785 32.3848 130.749L30.4713 127.281C30.4501 127.244 30.4125 127.22 30.3702 127.22H26.5432C26.5009 127.22 26.4632 127.244 26.4421 127.281L24.5286 130.749C24.5074 130.785 24.5074 130.835 24.5286 130.872L26.4421 134.339ZM26.6113 127.466H30.302L32.1474 130.81L30.302 134.154H26.6113L24.766 130.81L26.6113 127.466Z" fill="#646368"/>
    <path id="Vector_3" d="M28.4574 132.442C29.2928 132.442 29.9743 131.709 29.9743 130.81C29.9743 129.911 29.2928 129.178 28.4574 129.178C27.6219 129.178 26.9404 129.911 26.9404 130.81C26.9404 131.709 27.6219 132.442 28.4574 132.442ZM28.4574 129.429C29.1645 129.429 29.7409 130.049 29.7409 130.81C29.7409 131.57 29.1645 132.191 28.4574 132.191C27.7502 132.191 27.1738 131.57 27.1738 130.81C27.1738 130.049 27.7502 129.429 28.4574 129.429Z" fill="#646368"/>
    </g>
    <g id="Group 167">
    <path id="Vector_4" d="M19.6799 51.8158C19.6976 51.8467 19.729 51.8672 19.7644 51.8672H22.9648C23.0002 51.8672 23.0317 51.8467 23.0494 51.8158L24.6496 48.9162C24.6673 48.8853 24.6673 48.8442 24.6496 48.8134L23.0494 45.9137C23.0317 45.8829 23.0002 45.8623 22.9648 45.8623H19.7644C19.729 45.8623 19.6976 45.8829 19.6799 45.9137L18.0797 48.8134C18.062 48.8442 18.062 48.8853 18.0797 48.9162L19.6799 51.8158ZM19.8214 46.068H22.9078L24.451 48.8648L22.9078 51.6616H19.8214L18.2782 48.8648L19.8214 46.068Z" fill="#646368"/>
    <path id="Vector_5" d="M21.3642 50.23C22.0629 50.23 22.6328 49.6169 22.6328 48.8652C22.6328 48.1136 22.0629 47.5005 21.3642 47.5005C20.6656 47.5005 20.0957 48.1136 20.0957 48.8652C20.0957 49.6169 20.6656 50.23 21.3642 50.23ZM21.3642 47.7105C21.9556 47.7105 22.4376 48.2291 22.4376 48.8652C22.4376 49.5014 21.9556 50.02 21.3642 50.02C20.7729 50.02 20.2909 49.5014 20.2909 48.8652C20.2909 48.2291 20.7729 47.7105 21.3642 47.7105Z" fill="#646368"/>
    </g>
    <g id="Group 168">
    <path id="Vector_6" d="M93.489 134.339C93.5101 134.376 93.5477 134.4 93.59 134.4H97.4171C97.4594 134.4 97.497 134.376 97.5182 134.339L99.4317 130.872C99.4529 130.835 99.4529 130.785 99.4317 130.749L97.5182 127.281C97.497 127.244 97.4594 127.22 97.4171 127.22H93.59C93.5477 127.22 93.5101 127.244 93.489 127.281L91.5754 130.749C91.5543 130.785 91.5543 130.835 91.5754 130.872L93.489 134.339ZM93.6582 127.466H97.3489L99.1943 130.81L97.3489 134.154H93.6582L91.8129 130.81L93.6582 127.466Z" fill="#646368"/>
    <path id="Vector_7" d="M95.5042 132.442C96.3397 132.442 97.0212 131.709 97.0212 130.81C97.0212 129.911 96.3397 129.178 95.5042 129.178C94.6688 129.178 93.9873 129.911 93.9873 130.81C93.9873 131.709 94.6688 132.442 95.5042 132.442ZM95.5042 129.429C96.2113 129.429 96.7878 130.049 96.7878 130.81C96.7878 131.57 96.2113 132.191 95.5042 132.191C94.7971 132.191 94.2207 131.57 94.2207 130.81C94.2207 130.049 94.7971 129.429 95.5042 129.429Z" fill="#646368"/>
    </g>
    <g id="Group 169">
    <path id="Vector_8" d="M101.166 51.8158C101.184 51.8467 101.215 51.8672 101.251 51.8672H104.451C104.487 51.8672 104.518 51.8467 104.536 51.8158L106.136 48.9162C106.154 48.8853 106.154 48.8442 106.136 48.8134L104.536 45.9137C104.518 45.8829 104.487 45.8623 104.451 45.8623H101.251C101.215 45.8623 101.184 45.8829 101.166 45.9137L99.566 48.8134C99.5483 48.8442 99.5483 48.8853 99.566 48.9162L101.166 51.8158ZM101.308 46.068H104.394L105.937 48.8648L104.394 51.6616H101.308L99.7646 48.8648L101.308 46.068Z" fill="#646368"/>
    <path id="Vector_9" d="M102.851 50.23C103.549 50.23 104.119 49.6169 104.119 48.8652C104.119 48.1136 103.549 47.5005 102.851 47.5005C102.152 47.5005 101.582 48.1136 101.582 48.8652C101.582 49.6169 102.152 50.23 102.851 50.23ZM102.851 47.7105C103.442 47.7105 103.924 48.2291 103.924 48.8652C103.924 49.5014 103.442 50.02 102.851 50.02C102.259 50.02 101.777 49.5014 101.777 48.8652C101.777 48.2291 102.259 47.7105 102.851 47.7105Z" fill="#646368"/>
    </g>
    </g>
    <defs>
    <filter id="filter0_d_2101_138" x="34" y="51" width="55" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="-2"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2101_138"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2101_138" result="shape"/>
    </filter>
    <filter id="filter1_d_2101_138" x="30" y="61" width="64" height="74" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="-2"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2101_138"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2101_138" result="shape"/>
    </filter>
    <filter id="filter2_d_2101_138" x="0" y="32" width="121" height="31" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="-2"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2101_138"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2101_138" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_2101_138" x1="94.0304" y1="151.705" x2="6.41742" y2="0.640934" gradientUnits="userSpaceOnUse">
    <stop stop-color="#979499"/>
    <stop offset="0.25" stop-color="#B7B6B8"/>
    <stop offset="0.495" stop-color="#B9B8BA"/>
    <stop offset="0.695" stop-color="#C1BFC3"/>
    <stop offset="1" stop-color="#A8A6AA"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default Lp4