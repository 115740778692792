import React, { useState } from 'react';
import E95Wafer from '../../../../Images/svg/e95Wafer';
import { ChannelService } from '@getvia/sdk';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

  export default function SlotOpen(props) { 




    return (    <>
      <svg width="101" height="8" viewBox="0 0 101 8" x={props.x} y={props.y} fill="none" xmlns="http://www.w3.org/2000/svg" 
      onClick={()=>props.optionmodel(props.e95options)}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={props.content}
      data-tooltip-variant="info"
      data-tooltip-place ={props.place}
      style={{ cursor: "pointer",
        outline:"none"
       }}>
        <g filter="url(#filter0_ii_21_357)" transform={`rotate(${props.rotate} 50 50)`}>
        
        <rect width="100.54" height="7.08269" rx="2" transform="matrix(-0.999997 -0.00237102 -0.0147797 -0.999891 100.751 7.5303)" fill="#14E002"/>
        </g>
        <defs>
        <filter id="filter0_ii_21_357" x="-2.86426" y="-2.78525" width="106.586" height="13.3108" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="3" dy="3"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_21_357"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-3" dy="-3"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0765555 0 0 0 0 0.541667 0 0 0 0 0.0361111 0 0 0 1 0"/>
        <feBlend mode="normal" in2="effect1_innerShadow_21_357" result="effect2_innerShadow_21_357"/>
        </filter>
        </defs>
      </svg>
      <Tooltip id="my-tooltip" />

      </>)
  }