/**
 * Check if a user has permission to access a specific feature
 * @param {Object} user - The user details
 * @param {Object} rolePermissions - Role permissions object
 * @param {string[]} permissionRole - Array of keys defining permission path
 * @param {string} permissionType - The type of permission to check
 * @returns {boolean} - Whether the user has the permission
 */
function hasPermission(user, rolePermissions, permissionRole, permissionType) {
  // console.log("nn",user, rolePermissions, permissionRole, permissionType)
  // Early return if user is super admin
  if (user?.user_type === "super_admin") {
    return true;
  }
  console.log("cc",user,rolePermissions,Array.isArray(permissionRole),permissionType)
  
  // Validate inputs
  if (!user || !rolePermissions || !Array.isArray(permissionRole) || !permissionType) {
    return false;
  }

  // Get nested permission using reduce
  const rolePermission = permissionRole.reduce(
    (acc, key) => acc?.[key], 
    rolePermissions.permissions
  );
  // console.log("role",rolePermissions,permissionType)

  // Check if base access permission exists
  const hasAccess = Boolean(rolePermission?.access);
  
  // For control-based permissions, check both access and specific control permission
  if (rolePermission?.control) {
    return hasAccess && Boolean(rolePermission.control[permissionType]);
  }

  // For other permission types, check the specific permission
  return Boolean(rolePermission?.[permissionType]);
}

const enumPermission = {
  USER_MANAGEMENT: ["configuration", "user_management_tab"],
  PERMISSION_TAB:["configuration", "user_management_tab","permission_tab"],
  USER_LIST_tAB:["configuration", "user_management_tab","user_list_tab"],
  TOOL_CONFIGURATION_TAB:["configuration", "tool_configuration_tab"],
  CONFIGURATION:["configuration"]


};

export { hasPermission, enumPermission };
export default enumPermission;