
import React, { useEffect, useRef, useState, useReducer } from 'react';
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";

import IconAlarm from "../../../icons/alarm";
const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const defaultE95Configuration = [
  {
    object: "Sequence",
    name: "Sequence",
    feedBackChannel: "Sequence_Event",
    currentValue: '',
  },
  {
    object: "Gate_valve",
    name: "MAIN.Gate_valve.IN.OP_State",
    feedBackChannel: "MAIN.Gate_valve.IN.OP_State",
    currentValue: null,
    close:false
  },{
    object: "MFC7",
    name: "MAIN.MFC7.In.OP_State",
    feedBackChannel: "MAIN.MFC7.In.OP_State",
    currentValue: null,
    close:false
  },{
    object: "MFC8",
    name: "MAIN.MFC8.In.OP_State",
    feedBackChannel: "MAIN.MFC8.In.OP_State",
    currentValue: null,
    close:false
  },{
    object: "MFC9",
    name: "MAIN.MFC9.In.OP_State",
    feedBackChannel: "MAIN.MFC9.In.OP_State",
    currentValue: null,
    close:false
  },{
    object: "MFC10",
    name: "MAIN.MFC10.In.OP_State",
    feedBackChannel: "MAIN.MFC10.In.OP_State",
    currentValue: null,
    close:false
  },{
    object: "MFC11",
    name: "MAIN.MFC11.In.OP_State",
    feedBackChannel: "MAIN.MFC11.In.OP_State",
    currentValue: null,
    close:false
  },{
    object: "MFC12",
    name: "MAIN.MFC12.In.OP_State",
    feedBackChannel: "MAIN.MFC12.In.OP_State",
    currentValue: null,
    close:false
  },{
    object: "DicingChamberThrottleValve",
    name: "MAIN.DicingChamberThrottleValve.In.HW_state",
    feedBackChannel: "MAIN.DicingChamberThrottleValve.In.HW_state",
    currentValue: null,
    close:false
  },
  {
    object: "DicingChamberBias_Power",
    name: "MAIN.DicingChamberBias_Power.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberBias_Power.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "DicingChamberSource_Power",
    name: "MAIN.DicingChamberSource_Power.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberSource_Power.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "DicingChamberPiraniGuage",
    name: "MAIN.DicingChamberPiraniGuage.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberPiraniGuage.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "DicingChamberPumpPiraniGuage",
    name: "MAIN.DicingChamberPumpPiraniGuage.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberPumpPiraniGuage.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "DicingChamberManometer",
    name: "MAIN.DicingChamberManometer.In.OP_State",
    feedBackChannel: "MAIN.DicingChamberManometer.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "Manifold1",
    name: "MAIN.Manifold1.In.OP_State",
    feedBackChannel: "MAIN.Manifold1.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "Manifold2",
    name: "MAIN.Manifold2.In.OP_State",
    feedBackChannel: "MAIN.Manifold2.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "DDI_CUSTOM",
    name: "MAIN.DDI_CUSTOM.In.OP_State",
    feedBackChannel: "MAIN.DDI_CUSTOM.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "DicingController",
    name: "MAIN.DicingController.In.OP_State",
    feedBackChannel: "MAIN.DicingController.In.OP_State",
    currentValue: null,
    close:false
  },
  {
    object: "Manifold3",
    name: "MAIN.Manifold3.In.OP_State",
    feedBackChannel: "MAIN.Manifold3.In.OP_State",
    currentValue: null,
    close:false
  },
 
];
const channelService = new ChannelService(viaConfig.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfig.channelServiceOptions);

const formatDate = (dateString) => {
  const date = new Date(dateString)

  const padZero = (number, length) => String(number).padStart(length, '0')

  const year = date.getFullYear()
  const month = padZero(date.getMonth() + 1, 2)
  const day = padZero(date.getDate(), 2)

  let hours = date.getHours()
  const minutes = padZero(date.getMinutes(), 2)
  const seconds = padZero(date.getSeconds(), 2)
  const milliseconds = padZero(date.getMilliseconds(), 3)

  const period = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12 // Convert to 12-hour format and handle midnight (0 hours)

  const formattedHours = padZero(hours, 2)

  return `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds}.${milliseconds} ${period}`
}

export default function DeviceAlarmNotification(props) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };
  const e95Configuration = useRef(defaultE95Configuration);
  const channels = useRef();
  const subscriptionRef = useRef();
  const [e95State, sete95State] = useState();
  const [popup, setPopup] = useState();
  const [alarmpopup, setAlarmpopup] = useState(false);
  const [alarmdata,setAlarmdata] = useState([]);
  const [flag, setflag] = useState(false);
  const [f, setF] = useState(false);
  const [seqs,setSeqs] = useState([{ "timeStamp": "", "description":"", "isSuccess":true, "eventSource":"" }]);
  // const [seqs,setSeqs] = useState([{ "timeStamp": "2024-09-12T05:52:25.094Z", "description":"Completed Seq: DicingOnlineDTDLSeq for Inst: DicingOnlineDTDLSeq", "isSuccess":true, "eventSource":"Sequence" }]);
  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    channels.current = response;
    updateState(channels.current);
  };
  
  const updateState = (updatedChannels) => {

    let index = -1
    let e95ConfigurationState = e95Configuration.current;
    updatedChannels.forEach((item) => {
      const config = e95ConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
      })
      if (config) {
        config.currentValue = item.data.value;
        config.close=true;
        setAlarmpopup(true);
        e95ConfigurationState[index] = config;

      }
    })
    console.log('e95ConfigurationState',e95ConfigurationState)
    sete95State(() => e95ConfigurationState);
    setflag(false)
    let data =[];
    e95ConfigurationState?.map((channel, i) => {
      if (channel.name !== "Sequence") {
        if (channel?.currentValue !== 8) {
          // Check if the value is already in alarmdata
          const alreadyExists = alarmdata.some(
            (alarm) => alarm.alarmChannl === channel.name 
          );
     
          if (!alreadyExists) {
            if (!flag) {
              data = [{ alarmChannl: channel.name, value: channel?.currentValue }];
            } else {
              data = [...alarmdata, { alarmChannl: channel.name, value: channel?.currentValue }];
            }            
          }
          setflag(true);
        }
      }
    })
    setAlarmdata(()=>[...data])
    let currentseq = e95ConfigurationState?.find(o => o.name === "Sequence").currentValue;
    if(currentseq!='') {
    const parsedObject = JSON.parse(currentseq);
    let date = new Date().toLocaleString('en-GB', { hour12: true })
    console.log(seqs);
    setSeqs((seqs) => {
      if (seqs[seqs.length - 1].description !== parsedObject?.description) {
        const newSeqs = [...seqs, parsedObject];
        console.log(newSeqs); // This will log the updated sequence
        return newSeqs;
      } 
        return  seqs; 
    });
    }

  } 
  const handleClick = async () => {
    let currentseq = e95State?.find(o => o.name === "Sequence").currentValue;
    console.log(seqs);
    setSeqs((seqs) => {
      if (seqs[seqs.length - 1] !== currentseq) {
        const newSeqs = [...seqs, currentseq];
        console.log(newSeqs); // This will log the updated sequence
        return newSeqs;
      } else {
        return [currentseq]; 
      }
    });
  };
  const subscribeToNotification = () => {
    const onNewMessage = (message) => { 
      const channel = JSON.parse(message);
      updateState([channel]) 
      

    };

    let channels = [];

    e95Configuration.current.forEach(config => {
      channels?.push(config.feedBackChannel);
    })
    const subscription = {
      channels: channels,
      onNewMessage: onNewMessage,
    };

    subscriptionRef.current = subscription;
    channelSubscriptionService.subscribe(subscription);
  };
  const unSubscribe = () => {
    if (subscriptionRef.current && subscriptionRef.current.channels) {
      channelSubscriptionService.unSubscribe(subscriptionRef.current);
    }


  };

  const IOWrite = (channelName, value) => {
    if (channelName) {
      const channel = channels.current.find(obj => {
        return obj.channelName === channelName
      })
      console.log(channel)
      console.log(value)
      if (channel) {
        channel.data.value = value;
        channelService.Write(channel).then(() => {
        });
      }
    }
    setSeqs([{ "timeStamp": "", "description":"", "isSuccess":true, "eventSource":"" }])
  }


  useEffect(() => {
    const fetChannelData = async () => {
      await fetchChannelsStates();
      subscribeToNotification();
    };
    fetChannelData();
    // handleClick()
    return () => {
      unSubscribe();
    };
  }, []);
  // useEffect(() => {
  //   handleClick()   }, [e95State]);

  return (
<>
<button className='bg-white h-7 w-7 mt-10 rounded-md p-1 shadow hover:shadow-none' onClick={()=>setPopup(true)}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5">
    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
    <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clip-rule="evenodd" />
  </svg>
</button>
<button
      // onClick={() => {
      //   props.setCurrentTab("events");
      //   props.setHead("Alarms");
      //   props.setsubhead("");
      //   props.updatePage("setpageAlarms");
      // }}
      onClick={()=>setAlarmpopup(true)} 
      className="mx-2"
    >
      <div
        className={`flex flex-col justify-center items-center rounded-md space-y-2 w-[60px] h-[60px] px-2 py-2 ${
          props.currentTab == "events" ? "btn_active" : "btn_inactive"
        } ${flag ? 'blink-button' : ''}`}
      >
        <IconAlarm className="w-6 h-6 text-[#FF2424]" />
        <p className="uppercase font-semibold text-xs">Alarm</p>
      </div>
    </button>


 {/* ALARM POPUP START */}
 {alarmpopup&&<>
  <div className='fixed top-0 left-0 h-full w-full z-50 bg-black bg-opacity-20 ' >
    <div className='absolute top-10 right-10  bg-red-500 text-white border hover:bg-red-600 cursor-pointer px-4 py-2  rounded-full shadow-xl font-bold' 
    onClick={()=>setAlarmpopup(false)}>Close All</div>
   
      
    {e95State?.map((i, j) => (
      (i?.currentValue !== 8 && i.name !== "Sequence"&&i.close==true) && (
        <div style={{'left':240+(j*20)+'px','top':40+(j*20)+'px'}} className='w-1/3 pb-2 fixed z-20  bg-gray-50 
        mx-auto mt-20 rounded-xl overflow justify-between shadow-xl shadow-slate-600 border border-gray-500 ' key={j}>
            <div className={`text-lg bg-gray-600 hover:bg-red-600 text-white px-3 py-2 flex rounded-t-lg  active:bg-gray-600 `}  >
              <div className='flex-1'>Alarm </div> 
              <button className='text-gray-300 hover:text-white transition-all hover:shadow-gray-200 shadow-xl rounded-full' 
                onClick={() => {
                  const updatedState = e95State.map((item, index) =>
                    index === j ? { ...item, close: false } : item
                  );
                  sete95State(updatedState); // Ensure you have a `setE95State` function to update the state
                }}
                >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-8">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8 5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06Z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          <div className='p-4  overflow-y-auto '>
            <div className='text-md font-semibold text-center'>
              {i.name.includes("MFC")? 'MFC not Operational':
              i.name
              .replace("MAIN.", "")
              .replace(".In.OP_State", "")
              .replace(".In.HW_state", " Hardware State ")
              .replace(/([a-z])([A-Z])/g, "$1 $2")
              .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") +' Not Operational'
              }
              </div>
            <div className="px-2"></div>
            {/* <div className="px-2">Value: {i.currentValue}</div> */}
          </div> 
          <div className='w-full text-center p-2 '>
            <button className='p-2 px-5 rounded font-bold mx-auto text-white bg-red-500 hover:bg-red-400'
             onClick={() => {
              const updatedState = e95State.map((item, index) =>
                index === j ? { ...item, close: false } : item
              );
              sete95State(updatedState); // Ensure you have a `setE95State` function to update the state
            }}
            >Ok</button>
          </div>
        
        </div>
      )
    ))}  
    </div>

</>}
 {/* ALARM POPUP END */}
    {popup&&
    <div className='fixed top-0 left-0 h-full w-full z-50 bg-black bg-opacity-20' >
      <div className='absolute top-0 left-0 h-full w-full' onClick={()=>setPopup(false)}></div>
      <div  id="drager" className='w-3/4 pb-2 relative z-20 bg-gray-50 mx-auto mt-20 rounded-xl overflow justify-between shadow-xl border border-gray-500' 
      style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
     >
          <div className='text-lg bg-gray-800 text-white px-3 py-2 flex rounded-t-lg cursor-grab active:cursor-grabbing active:bg-gray-600 '  onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}>
           <div className='flex-1'>Sequence Event</div> 
           <button className=' hover:text-gray-300 hover:bg-gray-600 mr-4 bg-gray-400 px-2 rounded-md text-black font-semibold transition-all' 
            onClick={()=>IOWrite('Sequence_Event','')}>
              Clear 
            </button>
            <button className='text-gray-300 hover:text-white transition-all hover:shadow-gray-200 shadow-xl rounded-full' onClick={()=>setPopup(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-8">
                <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8 5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06Z" clip-rule="evenodd" />
              </svg>

            </button>
          </div>
          <div className='p-4 h-80 overflow-y-auto '>
            <div className='text-sm capitalize border-b border-gray-400  py-2 grid grid-cols-10 w-full font-bold'>
              <div className='col-span-2 border-r-2 border-gray-400'>Date</div>
              <div  className='col-span-2 border-r-2 pl-2 border-gray-400'>Event Source</div>
              <div  className='col-span-6 pl-2'>Event description</div>
            </div>
              {/* {e95State?.find(o => o.name === "Sequence").currentValue} */}
              {seqs.slice().reverse().map((fruit, index) => (
                fruit.description!=''&&
                <div  key={index} className='text-sm capitalize border-b border-gray-400  py-2 grid grid-cols-10 w-full '>
                  {/* <div className='px-2'>{index}</div> */}
                  <div title={'date'} className='col-span-2 border-r-2 border-gray-400 capitalize'> 
                    {/* {fruit.timeStamp && new Date(fruit.timeStamp).toLocaleString('en-CA', { hour12: true })} */}                     
                       {formatDate(fruit.timeStamp)}
                  </div>
                  <div  title={'Event Source'} className='font-bold col-span-2 pl-2 border-r-2 border-gray-400'>{fruit.eventSource}</div>
                  <div  title={'Event description'} className={`flex-1 font-medium col-span-6 pl-2 ${fruit.isSuccess? 'text-green-700':'text-red-700'}`}>{fruit.description}</div>
                </div>
              ))}
              
          </div>
      </div>
     
    </div>
    }
    </>
  )
}