import React, { useState, useEffect,useRef  } from 'react'
import DevicesAlarm from './devicesAlarm'

export default function EventsDisplay(props) {

    const [eventdata, seteventdata] = useState([])

    /* const formatDate = (dateString) => {
        let date = new Date(dateString)
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const localTimeString = date.toLocaleString(undefined, {
          timeZone: timezone,
        })
        
        return localTimeString
      } */
        const formatDate = (dateString) => {
          const date = new Date(dateString)
          const pstFormat = new Date(
            date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
          )
          const padZero = (number, length) => String(number).padStart(length, '0')
          const year = pstFormat.getFullYear()
          const month = padZero(pstFormat.getMonth() + 1, 2)
          const day = padZero(pstFormat.getDate(), 2)
          const hours = padZero(pstFormat.getHours(), 2)
          const minutes = padZero(pstFormat.getMinutes(), 2)
          const seconds = padZero(pstFormat.getSeconds(), 2)
          const milliseconds = padZero(date.getMilliseconds(), 3)
          return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`
        }

    const fetchData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_VIA_EVENTS_API}/api/EventAlarms/GetAllEventsByPage?page=1&pageSize=30`
          )
          if (response.ok) {
            const data = await response.json()
    
            const sortedData = data.slice().sort((a, b) => {
              return (
                new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime()
              )
            })
            seteventdata(sortedData)
          } else {
            throw new Error('Failed to fetch data')
          }
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      const scrollBoxRef = useRef(null);

      const handleMouseLeave = () => {
        // Scrolls the div back to the top when the hover ends
        if (scrollBoxRef.current) {
          scrollBoxRef.current.scrollTop = 0;
        }
      };

      //TODO : Revert this if required.. causing issue loading the site 
      useEffect(() => {
        const intervalId = setInterval(() => {
          fetchData()
        }, 6000)
        return () => clearInterval(intervalId)
      }, [])

  return (
    <div className=" w-5/12 h-7 relative z-50  mx-2 pt-10">
      {/* <div className="font-semibold text-red-500 p-1 text-right">
        <span className='inline-block'><b>Alarm</b> | MFC Operational status error </span>
        <button className='h-6 w-6  text-black rounded-full '>
          <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-4 -mt-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
      </div> */}
      <div className='flex  h-7 relative z-30 mb-1'>
        <div className=" text-xs font-semibold whitespace-nowrap text-gray-500 p-1 pr-1.5">
          Latest&nbsp;Events
        </div>
        <div ref={scrollBoxRef}      onMouseLeave={handleMouseLeave}
        className="overflow-hidden hover:overflow-y-scroll h-7 flex-1 rounded-md no-scrollbar eventScrollBox transition-all hidescroll inset-0 shadow-inner bg-gray-100 ">
          {eventdata?.map(
            (event, i) =>
              i <= 10 && (
                <div className="border-b border-gray-400 px-2 py-1 justify-between flex text-md  text-gray-500">
                  <div className="flex-1">{event?.description}</div>

                  <div className="text-md font-semibold leading-6 -mt-0.5 w-40 text-center">
                    {formatDate(event?.timeStamp)}
                  </div>
                </div>
              )
          )}
          {/* <DevicesAlarm /> */}
        </div>
      </div>
      <div className='flex  h-7 relative z-0 top-[-1px] '>
        <div className=" text-xs font-bold whitespace-nowrap text-gray-500  p-1 pr-1.5">
        Latest&nbsp;Alarm
        </div>
        
        <DevicesAlarm props={props} /> 
      </div>
 
    </div>
  )
}
