import { PencilSquareIcon, TrashIcon } from "@heroicons/react/16/solid";
import { useEffect, useState } from "react";
import { ExpressionBuild } from "../../api/object";

export default function ExpressionBuilder({
  dtdlList,
  dtdlSchemaList,
  onAddCondition,
  expressions,
  currentObject,
}) {
  const conditionPrefixList = [
    { value: "", label: "-" },
    { value: "!", label: "Not" },
  ];
  const [conditionDtdlPropertyList, setConditionDtdlPropertyList] = useState(
    []
  );
  const [conditionSchema, setConditionSchema] = useState([
    {
      value: "==",
      label: "Equals",
    },
    {
      value: "!=",
      label: "Not Equal",
    },
  ]);
  const [conditionExpressions, setConditionExpressions] = useState([]);
  const [showExpressionUI, setShowExpressionUI] = useState(false);
  const [currentRuleIndex, setCurrentRuleIndex] = useState(0);
  const [selectedExpression, setSelectedExpression] = useState({
    object: "",
    property: "",
    conditionPrefix: "",
    operator: "",
    value: "",
    outerCondition: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  // useEffect(() => {
  //   console.log(
  //     selectedExpression,
  //     "selectedExpression",
  //     conditionExpressions,
  //     conditionExpressions?.length
  //   );
  // }, [selectedExpression]);

  useEffect(() => {
    setCurrentRuleIndex(expressions?.length || 0);
    setConditionExpressions(expressions || []);
    if (expressions?.length == 0) {
      updateConditionPropertyList(currentObject["$dtId"]);
    }
  }, [expressions]);

  const updateConditionSchema = (object) => {
    setSelectedExpression((state) => ({
      ...state,
      property: object,
    }));
    const field = conditionDtdlPropertyList.find((e) => e.value == object);
    console.log(field, "field");
    let options = [
      { value: "==", label: "Equals" },
      { value: "!=", label: "Not Equal" },
      { value: ">", label: "Greater Than" },
      { value: "<", label: "Less Than" },
      { value: ">=", label: "Greater Than Or Equal" },
      { value: "<=", label: "Less Than Or Equal" },
    ];
    if (field) {
      switch (field?.schema) {
        case "double":
        case "integer":
          options = [
            { value: "==", label: "Equals" },
            { value: "!=", label: "Not Equal" },
            { value: ">", label: "Greater Than" },
            { value: "<", label: "Less Than" },
            { value: ">=", label: "Greater Than Or Equal" },
            { value: "<=", label: "Less Than Or Equal" },
          ];

          break;
        case "boolean":
        case "string":
          options = [
            {
              value: "==",
              label: "Equals",
            },
            {
              value: "!=",
              label: "Not Equal",
            },
          ];
          break;
        default:
          options = [
            { value: "==", label: "Equals" },
            { value: "!=", label: "Not Equal" },
            { value: ">", label: "Greater Than" },
            { value: "<", label: "Less Than" },
            { value: ">=", label: "Greater Than Or Equal" },
            { value: "<=", label: "Less Than Or Equal" },
          ];
          break;
      }
    }
    setConditionSchema(options);
  };
  const resetExpressionSelections = () => {
    setSelectedExpression({
      object: "",
      property: "",
      conditionPrefix: "",
      operator: "",
      value: "",
      outerCondition: "",
    });
    setConditionDtdlPropertyList([]);
    setCurrentRuleIndex("");
  };
  const handleAddCondition = async () => {
    const operator = selectedExpression;
    if (operator?.property && operator?.operator && operator.value !== "") {
      const prevExpressionIndex = conditionExpressions?.length - 1;
      const updatedExpressions = conditionExpressions.map(
        (expression, index) => {
          if (index === prevExpressionIndex) {
            return {
              ...expression,
              outerCondition: "AND",
            };
          }
          return expression;
        }
      );
      setConditionExpressions([...updatedExpressions, operator]);

      setShowExpressionUI(false);
      resetExpressionSelections();
    }
  };
  const handleUpdateCondition = () => {
    const operator = selectedExpression;
    if (operator?.property && operator?.operator && operator.value !== "") {
      const prevConditionExpression = conditionExpressions;
      prevConditionExpression[currentRuleIndex] = operator;
      setConditionExpressions(prevConditionExpression);
      setShowExpressionUI(false);
      resetExpressionSelections();
    }
  };
  const handleConditionDelete = (deleteRuleIndex) => {
    const ruleConditions = conditionExpressions.filter(
      (_, index) => index !== deleteRuleIndex
    );
    const updatedExpressions = ruleConditions.map((expression, index) => {
      if (index === ruleConditions.length - 1) {
        return {
          ...expression,
          outerCondition: "",
        };
      }
      return expression;
    });
    setConditionExpressions([...updatedExpressions]);

    if (currentRuleIndex == deleteRuleIndex) {
      setShowExpressionUI(false);
    } else {
      if (currentRuleIndex > deleteRuleIndex) {
        setCurrentRuleIndex((state) => state - 1);
      }
    }
  };
  const handleConditionEdit = (currentRuleIndex) => {
    setCurrentRuleIndex(currentRuleIndex);
    const ruleConditions = conditionExpressions.find(
      (_, index) => index === currentRuleIndex
    );
    updateConditionPropertyList(ruleConditions?.object);
    setSelectedExpression((state) => ({
      ...state,
      ...ruleConditions,
    }));

    setShowExpressionUI(true);
  };
  const getDeviceName = (value) => {
    const selectedDevice = dtdlList?.find((x) => x.value == value);
    return selectedDevice.label;
  };
  const getConditionValue = (value, property) => {
    const field = conditionDtdlPropertyList.find((e) => e.value == property);
    let convertedValue = convertValue(value?.toString(), field?.schema);
    return convertedValue?.toString();
  };
  const updateConditionPropertyList = (object) => {
    setSelectedExpression((state) => ({
      ...state,
      object: object,
    }));

    const selectedDevice = dtdlList?.find((x) => x.value == object);
    const selectedDeviceModel = selectedDevice?.$metadata?.$model;
    const filteredPropertiesList = dtdlSchemaList
      ?.find((e) => e["@id"] == selectedDeviceModel)
      ?.contents?.map((info) => ({
        ...info,
        value: info.name,
        label: info.displayName || info.name,
      }));
    setConditionDtdlPropertyList(filteredPropertiesList || []);
  };
  const convertValue = (value, schema) => {
    switch (schema) {
      case "double":
      case "float":
      case "long":
        return parseFloat(value) || 0;
      case "integer":
        return parseInt(value, 10);
      case "boolean":
        return value == "true";
      default:
        return value;
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const field = conditionDtdlPropertyList.find(
      (e) => e.value == selectedExpression.property
    );
    let convertedValue = convertValue(value, field?.schema);
    setSelectedExpression((state) => ({
      ...state,
      value: convertedValue,
    }));
  };
  const handleExpressionChange = (value, type) => {
    setSelectedExpression((state) => ({
      ...state,
      [type]: value,
    }));
  };

  const handleOuterConditionChange = (value, conditionIndex) => {
    const updatedExpressions = conditionExpressions.map((expression, index) => {
      if (index === conditionIndex) {
        return {
          ...expression,
          outerCondition: value,
        };
      }
      return expression;
    });

    // Update state with the new array
    setConditionExpressions(updatedExpressions);
  };
  const renderInputField = () => {
    const field = conditionDtdlPropertyList.find(
      (e) => e.value == selectedExpression.property
    );

    if (!field) {
      return (
        <input
          object="number"
          onChange={(e) => handleInputChange(e)}
          value={selectedExpression?.value}
          className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
        />
      );
    }

    switch (field.schema) {
      case "dateTime":
        return (
          <input
            object="datetime-local"
            name={field.name}
            onChange={(e) => handleInputChange(e)}
            value={selectedExpression?.value}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "double":
        return (
          <input
            type="number"
            step=""
            name={field.name}
            onChange={(e) => handleInputChange(e)}
            value={selectedExpression?.value}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "integer":
        return (
          <input
            type="number"
            name={field.name}
            onChange={(e) => handleInputChange(e)}
            value={selectedExpression?.value}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "string":
        return (
          <input
            value={selectedExpression?.value}
            name={field.name}
            onChange={(e) => handleInputChange(e)}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "boolean":
        return (
          <select
            name={field.name}
            onChange={(e) => handleInputChange(e)}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
            value={selectedExpression?.value}
          >
            <option value="">Select a value</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        );
      default:
        return null;
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    const expressionRes = await ExpressionBuild(conditionExpressions || []);
    const expression = expressionRes?.expression;
    onAddCondition({ conditionExpressions, expression });
    setIsSaving(false);
  };
  const renderExpression = () => {
    return (
      <div className="flex flex-col flex-1 gap-1 rounded my-2 border border-gray p-2">
        <div className="grid grid-cols-2 gap-2 items-start">
          <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
            <label
              htmlFor="event"
              className="block text-sm font-medium text-gray-700"
            >
              Select Device
            </label>
            <select
              className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
              onChange={(e) => updateConditionPropertyList(e.target.value)}
              value={selectedExpression?.object}
            >
              <option value="">Select a Device</option>
              {dtdlList.map((dtdl, index) => (
                <option key={index} value={dtdl.value}>
                  {dtdl.label}
                </option>
              ))}
            </select>
          </div>
          <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
            <label
              htmlFor="event"
              className="block text-sm font-medium text-gray-700"
            >
              Select Property
            </label>
            <select
              className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
              onChange={(e) => updateConditionSchema(e.target.value)}
              value={selectedExpression.property}
            >
              <option value="">Select a Property</option>
              {conditionDtdlPropertyList.map((prop, index) => (
                <option key={index} value={prop.value}>
                  {prop.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 items-start">
          {/* <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
            <label
              htmlFor="event"
              className="block text-sm font-medium text-gray-700"
            >
              Condition Prefix
            </label>
            <select
              className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
              onChange={(e) =>
                handleExpressionChange(e.target.value, "conditionPrefix")
              }
              value={selectedExpression?.conditionPrefix}
            >
              {conditionPrefixList.map((prop, index) => (
                <option key={index} value={prop.value}>
                  {prop.label}
                </option>
              ))}
            </select>
          </div> */}

          <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
            <label
              htmlFor="event"
              className="block text-sm font-medium text-gray-700"
            >
              Condition
            </label>
            <select
              className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
              onChange={(e) =>
                handleExpressionChange(e.target.value, "operator")
              }
              value={selectedExpression?.operator}
            >
              <option value="">{`Select operator`}</option>
              {conditionSchema.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
            <label
              htmlFor="event"
              className="block text-sm font-medium text-gray-700"
            >
              Select Value
            </label>
            {renderInputField()}
          </div>
        </div>
        {/* <select
          className="w-[200px] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          onChange={(e) =>
            handleExpressionChange(e.target.value, "outerCondition")
          }
          value={selectedExpression?.outerCondition}
        >
          <option value="">Select Conjunction</option>
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </select> */}
        <div className="flex justify-end space-x-2">
          {/* {`currentRuleIndex${currentRuleIndex}`} */}
          {conditionExpressions.length > 0 && (
            <button
              className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
              onClick={() => {
                setShowExpressionUI(false);
                resetExpressionSelections();
              }}
            >
              Cancel
            </button>
          )}
          {currentRuleIndex < conditionExpressions?.length && (
            <button
              className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
              onClick={() => {
                handleUpdateCondition();
              }}
            >
              Update Condition
            </button>
          )}
          {currentRuleIndex == conditionExpressions.length && (
            <button
              className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
              onClick={() => {
                handleAddCondition();
              }}
            >
              Add Condition
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderExpressionValue = () => {
    return (
      <div>
        <div className="max-h-[20vh] overflow-auto flex flex-col flex-1">
          {conditionExpressions?.map((e, index) => (
            <div className="flex flex-col relative">
              <div
                className={`flex justify-between p-1 pr-2 border border-blue-300 rounded ${
                  e.outerCondition ? "mb-10" : ""
                }`}
              >
                <div>
                  <span class="font-medium min-w-0 truncate">
                    {getDeviceName(e.object)}.{e.property}
                  </span>
                  <span class="rounded  px-1">
                    {e.conditionPrefix} {e.operator}
                  </span>
                  <span>{getConditionValue(e.value, e.property)}</span>
                </div>
                <div className="flex gap-3">
                  <button
                    className="flex items-center justify-center border rounded p-1 hover:bg-white"
                    onClick={() => handleConditionEdit(index)}
                  >
                    {<PencilSquareIcon className="w-4 h-4 text-blue-300" />}
                  </button>
                  <button
                    className="flex items-center justify-center border rounded p-1 hover:bg-slate-100"
                    onClick={() => handleConditionDelete(index)}
                  >
                    {<TrashIcon className="w-4 h-4 text-blue-300" />}
                  </button>
                </div>
              </div>

              {e.outerCondition && (
                <div className="absolute left-3 top-9">
                  <div className="border border-r-1  h-10 border-blue-300"></div>
                  <select
                    className="border-0  p-0 m-0 text-center bg-white absolute top-[10px] -left-4 text-xs w-12 focus-visible:outline-none"
                    onChange={(e) =>
                      handleOuterConditionChange(e.target.value, index)
                    }
                    value={e.outerCondition}
                  >
                    <option value="AND">AND</option>
                    <option value="OR">OR</option>
                  </select>
                </div>
              )}
            </div>
          ))}
        </div>

        {!showExpressionUI && (
          <div className="flex justify-start mt-4">
            <button
              onClick={() => {
                setShowExpressionUI(true);
                setCurrentRuleIndex(conditionExpressions.length);
              }}
              className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
            >
              + Add
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Add Expression</h2>
      {conditionExpressions && conditionExpressions.length > 0 && (
        <div className=" flex flex-col flex-1 gap-1 rounded my-2 border border-gray p-2">
          {renderExpressionValue()}
        </div>
      )}
      {(conditionExpressions.length == 0 || showExpressionUI) && (
        <div className="w-full">{renderExpression()}</div>
      )}
      <button
        disabled={isSaving}
        onClick={() => handleSave()}
        className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
      >
        Save {isSaving && "..."}
      </button>
    </div>
  );
}
