import React, { useState, useEffect } from 'react';

const slotStyle = {
  fontSize: '6px',
  padding: '0px 6px',
}


export default function RightInterface(props) {
  const { efemDigitalTwins, execute } = props
  const [slots, setSlots] = useState([])
  const [lps, setLps] = React.useState([])


  const genrageDymmySlots = () => {
    const slots = []
    for (let i = 1; i <= 25; i++) {
      slots.push(`Slot${i}_PanelPresence`) // Initial value 'empty'
    }
    setSlots(() => slots)
  }

  const arrageLps = () => {
    if (efemDigitalTwins) {
      const keys = Object.keys(efemDigitalTwins)
      const lp = []
      // const robot = []
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].includes('LP')) {
          setLps([...lps, efemDigitalTwins[keys[i]]])
          lp.push(efemDigitalTwins[keys[i]])
          setLps(() => lp)
        }
        // if (keys[i].includes('PTRobot1')) {
        //   robot.push(efemDigitalTwins[keys[i]])
        //   setRobotData(() => robot)
        // }
      }
    }
  }

  const handleButtonClick = (lp, value) => {
    execute(lp.$dtId, 'LPCmd', {
      LPCmdRequest: value,
    })
  }

  useEffect(() => {
    genrageDymmySlots()
    arrageLps()
  }, [efemDigitalTwins])


  return (<>
    <div className='flex gap-2 mt-7'>
      {lps?.map((lp, index) => {
        return(<div className='border border-gray-400 border-opacity-60 w-4/5 bg-customGray rounded p-2 relative'>
          <div className='absolute top-[-5%] bg-customGray w-3/4 left-1/2 transform -translate-x-1/2'>
            <h1 className='font-bold text-xs font-sans border border-gray-400 text-gray-600 border-opacity-70 rounded-sm text-center px-1 py-[6%]'>
              <span
                  className={`rounded-2xl p-1 px-3 ${
                    lp.ConnectedStatus === 1
                      ? 'bg-green-500 text-white'
                      : 'bg-gray-500 text-white'
                  }`}
                >
              {lp.Name}
              </span>
            </h1>
          </div>
          <div className='space-y-3'>
            <div className='grid grid-cols-2 gap-1 mt-3'>
              <div className='col-span-1 space-y-[2px]'>
                {slots.slice(1, 13).map((slot, i) => (
                    <h1
                      key={slot + lp + i + index}
                      className={`slot-indicator font-bold rounded-xl flex items-center mb-0.5 ${
                        lp[`Slot${i + 1}_PanelPresence`] < 1
                          ? 'bg-gray-500 text-white'
                          : 'bg-green-500 text-black'
                      }`}
                      style={slotStyle}
                    >
                      {(i + 1).toString().padStart(2, '0')}
                    </h1>
                  ))}
              </div>
              <div className='col-span-1 space-y-[2px]'>
                {slots.map((slot, i) => (
                    <h1
                      key={slot + lp + i + index}
                      className={`font-bold rounded-xl flex items-center mb-0.5 ${
                        lp[`Slot${i + 13}_PanelPresence`] < 1
                          ? 'bg-gray-500 text-white'
                          : 'bg-green-500 text-black'
                      }`}
                      style={slotStyle}
                    >
                      {i + 13 < 26 && (i + 13).toString().padStart(2, '0')}
                    </h1>
                  ))}
              </div>
            </div>
            <div className='text-center text-xs font-bold space-y-3'>
              <button 
                className={`bg-btnBg w-full rounded text-gray-600 px-1 py-[6%] custom-shadow custom-shadow-hover ${lp.CMDProcessing !== 0 || lp.OperationSts !== 0 ? 'disabled-btn' : ''}`}
                onClick={() => handleButtonClick(lp, 5)}
                disabled={lp.CMDProcessing !== 0 || lp.OperationSts !== 0}
              >LOAD</button>
              <button 
                className={`bg-btnBg w-full rounded text-gray-600 px-1 py-[6%] custom-shadow custom-shadow-hover ${lp.CMDProcessing !== 0 || lp.OperationSts !== 0 ? 'disabled-btn' : ''}`}
                onClick={() => handleButtonClick(lp, 7)}
                disabled={lp.CMDProcessing !== 0 || lp.OperationSts !== 0}
                >UNLOAD</button>
            </div>
          </div>
        </div>  
        )        
      })}
      
    </div>
      <div className='border border-gray-400 border-opacity-60 bg-customGray rounded p-3 mt-3'>
        <button className='bg-btnBg w-full rounded text-gray-600 px-1 py-[6%] custom-shadow custom-shadow-hover font-bold'>Make All Online</button>
      </div>
  </>);
}

