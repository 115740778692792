import React from 'react'
import { useNavigate } from 'react-router-dom';


function Chamber(props) {
    console.log("props.status",props.status);
    const navigate = useNavigate();
    const handleClick = () => {
      // navigate('/dicingRIEChamber');
    };
    
    
    return (
        <svg width="186" height="190" viewBox="0 0 186 190" fill="none" xmlns="http://www.w3.org/2000/svg" x={props.x} y={props.y} onClick={handleClick}>
        <g id="Group 176">
        <path id="Vector" d="M117.474 169.861L93.3572 141.089L75.6694 155.915L7.94212 75.1156L90.711 5.7376L158.438 86.5372L140.029 101.968L164.147 130.74C143.71 147.99 137.911 152.612 117.474 169.861Z" fill="url(#paint0_linear_2108_2)" stroke="#646368" stroke-miterlimit="10"/>
        <g id="Vector_2">
        <g filter="url(#filter0_i_2108_2)">
        <path d="M141.38 83.3112L115.391 52.3045C108.02 45.0298 98.2944 40.5768 87.8686 39.7035C77.4428 38.8301 66.9612 41.5903 58.2076 47.5144C39.1156 61.0672 33.4966 88.8259 47.1827 109.477L73.1726 140.483L141.38 83.3112Z" fill={props.fill}/>
        </g>
        <path d="M87.8268 40.2017C98.1294 41.0648 107.738 45.4621 115.023 52.6439L140.676 83.2492L73.2346 139.779L47.584 109.177C34.0675 88.7548 39.6319 61.3176 58.4925 47.9253C67.1516 42.0667 77.518 39.3382 87.8268 40.2017Z" stroke="#A2A2A2"/>
        </g>
        <g id="Vector_3">
        <g filter="url(#filter1_ii_2108_2)">
        <path d="M106.044 61.3899C95.2231 48.4805 75.9859 46.7874 63.0765 57.6082C50.1671 68.4291 48.474 87.6662 59.2949 100.576C70.1157 113.485 89.3528 115.178 102.262 104.357C115.172 93.5365 116.865 74.2994 106.044 61.3899Z" fill="#E6E6E6"/>
        </g>
        <path d="M106.427 61.0687C95.4289 47.9477 75.8764 46.2268 62.7553 57.225C49.6343 68.2233 47.9134 87.7758 58.9117 100.897C69.9099 114.018 89.4624 115.739 102.583 104.741C115.704 93.7423 117.425 74.1898 106.427 61.0687Z" stroke="#B1B1B1"/>
        </g>
        <rect id="Rectangle 756" x="98.5467" y="15.9297" width="3.55581" height="62.8886" rx="0.75" transform="rotate(-40.3257 98.5467 15.9297)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <g id="Rectangle 757" filter="url(#filter2_d_2108_2)">
        <rect x="149.169" y="75.4785" width="16" height="108" rx="1" transform="rotate(50.0298 149.169 75.4785)" fill="url(#paint1_linear_2108_2)" shape-rendering="crispEdges"/>
        <rect x="149.138" y="75.8307" width="15.5" height="107.5" rx="0.75" transform="rotate(50.0298 149.138 75.8307)" stroke="#646368" stroke-width="0.5" shape-rendering="crispEdges"/>
        </g>
        <g id="Rectangle 758" filter="url(#filter3_i_2108_2)">
        <rect x="74.876" y="64.6802" width="3" height="2" rx="1" transform="rotate(-129.97 74.876 64.6802)" fill="#D9D9D9"/>
        </g>
        <g id="Rectangle 759" filter="url(#filter4_i_2108_2)">
        <rect x="73.2871" y="90.8047" width="3" height="3" rx="1" transform="rotate(-129.97 73.2871 90.8047)" fill="#D9D9D9"/>
        </g>
        <g id="Rectangle 760" filter="url(#filter5_i_2108_2)">
        <rect x="81.1904" y="78.2932" width="6" height="6" rx="3" transform="rotate(-1.97  77.1904 78.2932)" fill="#767070"/>
        </g>
        <g id="Rectangle 761" filter="url(#filter6_i_2108_2)">
        <rect x="94.8125" y="96.2485" width="2" height="2" rx="1" transform="rotate(-129.97 94.8125 96.2485)" fill="#D9D9D9"/>
        </g>
        <g id="Rectangle 762" filter="url(#filter7_i_2108_2)">
        <rect x="101.643" y="67.0366" width="3" height="3" rx="1" transform="rotate(-129.97 101.643 67.0366)" fill="#D9D9D9"/>
        </g>
        <g id="Rectangle 763" filter="url(#filter8_d_2108_2)">
        <rect x="140.287" y="103.8" width="4" height="60" transform="rotate(50.0298 140.287 103.8)" fill="#D9D9D9"/>
        </g>
        <rect id="Rectangle 764" x="18.3735" y="65.9894" width="3.55581" height="91.0104" rx="0.75" transform="rotate(-130.336 18.3735 65.9894)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <rect id="Rectangle 765" x="7.25535" y="75.1389" width="3.47207" height="14.9469" rx="0.75" transform="rotate(-129.376 7.25535 75.1389)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <rect id="Rectangle 766" x="13.7511" y="78.9805" width="5.4396" height="10.2353" rx="0.75" transform="rotate(139.627 13.7511 78.9805)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <g id="Group 72">
        <path id="Vector_4" d="M36.2556 74.004C36.2725 73.9529 36.2636 73.8947 36.2273 73.8514L32.9407 69.9304C32.9044 69.8871 32.8487 69.8682 32.7954 69.876L27.85 70.6833C27.7968 70.691 27.7499 70.7303 27.733 70.7814L26.0742 75.5097C26.0573 75.5608 26.0662 75.619 26.1025 75.6623L29.3891 79.5833C29.4254 79.6266 29.4811 79.6455 29.5344 79.6378L34.4798 78.8304C34.533 78.8227 34.5799 78.7834 34.5968 78.7323L36.2556 74.004ZM29.5647 79.3171L26.3952 75.5359L27.9954 70.9756L32.7651 70.1966L35.9346 73.9778L34.3344 78.5381L29.5647 79.3171Z" fill="#646368"/>
        <path id="Vector_5" d="M32.7187 73.4542C32.0012 72.5982 30.7178 72.4853 29.8618 73.2028C29.0059 73.9203 28.8929 75.2037 29.6104 76.0596C30.3279 76.9156 31.6113 77.0286 32.4672 76.3111C33.3232 75.5936 33.4362 74.3102 32.7187 73.4542ZM29.8495 75.8592C29.2422 75.1347 29.3378 74.0491 30.0622 73.4419C30.7867 72.8346 31.8723 72.9302 32.4796 73.6546C33.0868 74.3791 32.9913 75.4647 32.2668 76.072C31.5423 76.6792 30.4567 76.5837 29.8495 75.8592Z" fill="#646368"/>
        </g>
        <g id="Group 73">
        <path id="Vector_6" d="M97.1774 22.9381C97.1944 22.887 97.1855 22.8288 97.1492 22.7855L93.8626 18.8645C93.8263 18.8212 93.7705 18.8023 93.7172 18.81L88.7719 19.6174C88.7186 19.6251 88.6718 19.6644 88.6548 19.7155L86.9961 24.4438C86.9791 24.4949 86.988 24.5531 87.0244 24.5964L90.311 28.5174C90.3473 28.5607 90.403 28.5796 90.4563 28.5719L95.4016 27.7645C95.4549 27.7568 95.5018 27.7175 95.5187 27.6664L97.1774 22.9381ZM90.4866 28.2512L87.3171 24.47L88.9173 19.9097L93.687 19.1307L96.8565 22.9119L95.2563 27.4722L90.4866 28.2512Z" fill="#646368"/>
        <path id="Vector_7" d="M93.6405 22.3883C92.9231 21.5323 91.6397 21.4194 90.7837 22.1369C89.9277 22.8544 89.8148 24.1377 90.5323 24.9937C91.2497 25.8497 92.5331 25.9626 93.3891 25.2451C94.2451 24.5277 94.358 23.2443 93.6405 22.3883ZM90.7714 24.7933C90.1641 24.0688 90.2596 22.9832 90.9841 22.376C91.7086 21.7687 92.7942 21.8643 93.4014 22.5887C94.0087 23.3132 93.9132 24.3988 93.1887 25.0061C92.4642 25.6133 91.3786 25.5178 90.7714 24.7933Z" fill="#646368"/>
        </g>
        <g id="Rectangle 767" filter="url(#filter9_i_2108_2)">
        <rect x="144.937" y="108.672" width="9.36905" height="60.4805" transform="rotate(50.0853 144.937 108.672)" fill="#EAEAEA"/>
        </g>
        {/* <g id="Group 74" filter="url(#filter10_d_2108_2)">
        <path id="Vector_8" d="M63.0665 97.2479C72.0365 107.949 88.04 109.358 98.7413 100.388C109.443 91.4178 110.851 75.4143 101.881 64.7129C92.9112 54.0116 76.9077 52.6031 66.2063 61.5731C55.505 70.5431 54.0965 86.5466 63.0665 97.2479ZM88.6685 75.788C91.481 83.6234 90.2624 80.8896 87.4919 86.9671C81.4191 90.1798 84.0718 88.2244 76.2226 86.2203C74.726 78.3548 73.8155 82.0686 77.5827 74.0827C83.523 71.2493 83.5577 74.1708 88.6685 75.788Z" fill="#2F3034"/>
        <path id="Vector_9" d="M80.6664 78.8241C79.4775 79.8206 79.321 81.5991 80.3175 82.788C81.314 83.9768 83.0925 84.1334 84.2814 83.1368C85.4703 82.1403 85.6268 80.3618 84.6302 79.173C83.6337 77.9841 81.8553 77.8276 80.6664 78.8241ZM83.0764 81.6993C82.6803 82.0312 82.0871 81.979 81.7551 81.583C81.4231 81.1869 81.4753 80.5937 81.8714 80.2617C82.2674 79.9297 82.8607 79.9819 83.1927 80.378C83.5246 80.774 83.4724 81.3673 83.0764 81.6993Z" fill="#2F3034"/>
        <path id="Vector_10" d="M77.0509 74.5113C73.4842 77.5009 73.0141 82.8356 76.0043 86.403C78.9939 89.9696 84.3292 90.4392 87.8959 87.4496C91.4632 84.4594 91.9321 79.1246 88.9425 75.558C85.9523 71.9906 80.6182 71.5211 77.0509 74.5113ZM86.6909 86.012C83.9163 88.3376 79.7675 87.9725 77.4418 85.198C75.1168 82.4241 75.4813 78.2746 78.2559 75.9489C81.0297 73.6239 85.1799 73.9891 87.5049 76.763C89.8306 79.5375 89.4647 83.6869 86.6909 86.012Z" fill="#2F3034"/>
        <path id="Vector_11" d="M63.0229 77.2537C63.5306 77.3549 64.0248 77.0249 64.126 76.5172C66.0478 66.8805 75.45 60.6036 85.0868 62.5244C85.5946 62.6256 86.0886 62.2966 86.19 61.788C86.2914 61.2793 85.9613 60.786 85.4535 60.6848C74.803 58.5619 64.4102 65.5002 62.2873 76.1507C62.1852 76.6583 62.5142 77.1523 63.0229 77.2537Z" fill="#F5F4F9"/>
        </g> */}
        <rect id="Rectangle 769" x="30.3045" y="105.752" width="3.55581" height="62.8886" rx="0.75" transform="rotate(-40.3257 30.3045 105.752)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <g id="Rectangle 770" filter="url(#filter11_d_2108_2)">
        <rect x="13.6504" y="92.4917" width="10.7977" height="10.1015" rx="1" transform="rotate(-130.005 13.6504 92.4917)" fill="#D9D9D9"/>
        <rect x="13.6812" y="92.1395" width="10.2977" height="9.60147" rx="0.75" transform="rotate(-130.005 13.6812 92.1395)" stroke="#646368" stroke-width="0.5"/>
        </g>
        </g>
        {props.status==='filter' && 
        <svg width="97" height="96" viewBox="-10 -30 87 106" x={30} y={22} >
          <defs>
            <filter id="drop-shadow2" x="-20%" y="-20%" width="140%" height="140%" >
              <feGaussianBlur in="SourceAlpha" stdDeviation="10"/> 
              <feOffset dx="0" dy="0" result="offsetblur"/> 
              <feFlood flood-color="#cf00f7" flood-opacity="1"/> 
              <feComposite in2="offsetblur" operator="in"/>
              <feMerge>
                  <feMergeNode/>
                  <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
            <g filter="url(#drop-shadow2)" >
            <g filter="url(#filter0_ii_0_1)">
              <path d="M31.3857 1.13056C14.5861 1.18694 1.01365 15.033 1.07078 32.0566C1.12792 49.0802 14.793 62.8348 31.5926 62.7785C48.3922 62.7221 61.9647 48.876 61.9075 31.8524C61.8504 14.8288 48.1853 1.07417 31.3857 1.13056Z" 
              fill="#cf00f7"/>
              </g>
              <path d="M31.3841 0.63056C14.3021 0.687891 0.512745 14.7648 0.570785 32.0583C0.628826 49.3518 14.5123 63.3358 31.5943 63.2785C48.6763 63.2211 62.4656 49.1442 62.4075 31.8507C62.3495 14.5573 48.466 0.57323 31.3841 0.63056Z" 
              />
              <g >
              <rect x="14.0518" y="32.8242" width="3.24465" height="2.43348" rx="1" transform="rotate(179.808 14.0518 32.8242)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="31.9543" y="49.7985" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 31.9543 49.7985)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="33.5249" y="34.3816" width="5.67813" height="5.67813" rx="2.83906" transform="rotate(179.808 33.5249 34.3816)" fill="#767070"/>
              </g>
              <g >
              <rect x="50.5537" y="32.7017" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 50.5537 32.7017)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="31.8318" y="13.2966" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 31.8318 13.2966)" fill="#D9D9D9"/>
              </g>
              
            </g>
        </svg> 
        }


        <defs>
        <filter id="filter0_i_2108_2" x="39.623" y="39.5452" width="101.758" height="104.938" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2108_2"/>
        </filter>
        <filter id="filter1_ii_2108_2" x="47.1689" y="45.4819" width="70.001" height="72.0017" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="3" dy="5"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2108_2"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-4" dy="-4"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="effect1_innerShadow_2108_2" result="effect2_innerShadow_2108_2"/>
        </filter>
        <filter id="filter2_d_2108_2" x="61.8086" y="74.8872" width="98.2295" height="86.8225" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-2" dy="2"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2108_2"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2108_2" result="shape"/>
        </filter>
        <filter id="filter3_i_2108_2" x="73.3574" y="61.5051" width="2.64258" height="3.76636" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2108_2"/>
        </filter>
        <filter id="filter4_i_2108_2" x="71.7686" y="86.9871" width="3.40918" height="4.40894" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2108_2"/>
        </filter>
        <filter id="filter5_i_2108_2" x="81.1904" y="78.2932" width="6" height="7" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2108_2"/>
        </filter>
        <filter id="filter6_i_2108_2" x="93.9365" y="93.8398" width="2" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2108_2"/>
        </filter>
        <filter id="filter7_i_2108_2" x="100.124" y="63.219" width="3.40918" height="4.40894" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2108_2"/>
        </filter>
        <filter id="filter8_d_2108_2" x="89.3047" y="102.8" width="54.5518" height="47.6089" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-2" dy="2"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2108_2"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2108_2" result="shape"/>
        </filter>
        <filter id="filter9_i_2108_2" x="98.5479" y="108.672" width="53.4004" height="46.9932" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="1"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2108_2"/>
        </filter>
        <filter id="filter10_d_2108_2" x="55.1611" y="53.668" width="60.625" height="60.625" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="3" dy="3"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2108_2"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2108_2" result="shape"/>
        </filter>
        <filter id="filter11_d_2108_2" x="6.11816" y="78.136" width="17.8613" height="17.9468" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2108_2"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2108_2" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_2108_2" x1="8.33208" y1="55.9765" x2="181.382" y2="138.203" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EAEAEA"/>
        <stop offset="0.25" stop-color="#CDCDCD"/>
        <stop offset="0.495" stop-color="#A6A6A7"/>
        <stop offset="0.695" stop-color="#DBDBDC"/>
        <stop offset="1" stop-color="#8B8B8B"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2108_2" x1="157.169" y1="75.4785" x2="157.169" y2="183.479" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BFBFBF"/>
        <stop offset="0.485" stop-color="#E2E2E2"/>
        <stop offset="1" stop-color="#A9A6A6" stop-opacity="0"/>
        </linearGradient>
        </defs>
        </svg>       
    )
}

export default Chamber