export const GetObjectEventById = async ({ objectId }) => {
  const url = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectEvent/${objectId}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};
export const SaveObjectEvent = async (payload) => {
  const url = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectEvent`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};

export const DeleteObjectEventByName = async ({ objectId, eventName }) => {
  const url = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectEvent/${objectId}/${eventName}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};
export const ExpressionBuild = async (payload) => {
  const url = `${process.env.REACT_APP_VIA_OBJECT_API}/api/Expression/build`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};
export const GetObjectMonitors = async ({ objectId }) => {
  const url = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectMonitors?objectId=${objectId}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};

export const monitorWorkflowDisable = async (id) => {
  const url = `${process.env.REACT_APP_VIA_MONITORWORKFLOW_API}/MonitorWorkflow/disable/${id}`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};

export const monitorWorkflowEnable = async (id) => {
  const url = `${process.env.REACT_APP_VIA_MONITORWORKFLOW_API}/MonitorWorkflow/enable/${id}`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};

export const monitorWorkflowStart = async (id) => {
  const url = `${process.env.REACT_APP_VIA_MONITORWORKFLOW_API}/MonitorWorkflow/start/${id}`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};

export const monitorWorkflowStop = async (id) => {
  const url = `${process.env.REACT_APP_VIA_MONITORWORKFLOW_API}/MonitorWorkflow/stop/${id}`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
};