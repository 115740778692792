import React, { useState, useEffect, useRef } from "react";
import { useNotifications } from '@mantine/notifications'


export default function SystemConfigEditor({ selectedObject, refreshWindow } ) {

  const notifications = useNotifications()
  const [objectConfig, setObjectConfig] = useState({});
  const [oldConfig, setOldConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [addConfigModal, setAddConfigModal] = useState(false);
  const [newConfigName, setNewConfigName] = useState("");
  const [newConfigVal, setNewConfigVal] = useState("");
  const [editedConfigs, setEditedConfigs] = useState(null);
  const [currentObjId, setCurrentObjId] = useState(selectedObject['$dtId']);



  const fetchObjectConfiguration = async () => {
    try {
      // Replace 'API_ENDPOINT' with the actual endpoint URL
      const API_URL = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectConfiguration/${currentObjId}`;
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      const data = await response.json();
      // console.log(data,'fetchObjectConfiguration')
      setObjectConfig(data);
      setOldConfig(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const addObjectConfig = async (edit) => {
    let constsData = null
    let notificationText = "Configuration added successfully!"
    if(edit) {
      constsData = JSON.stringify(editedConfigs)
      notificationText = "Configuration updated successfully!"
    } else {
      constsData = JSON.stringify({
        [newConfigName]: newConfigVal 
      })
    }
    try {
      const API_URL = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectConfiguration/${currentObjId}`;
      const response = await fetch(API_URL, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
        },
        body: constsData,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      const data = await response.json();
      fetchObjectConfiguration()
      setNewConfigName("")
      setNewConfigVal("")
      setAddConfigModal(false)
      refreshWindow()
      notifications.showNotification({
        title: notificationText,
        message: '',
        autoClose: 5000,
        color: 'green',
        loading: false,
        disallowClose: false,
      })
    } catch (error) {
      setError(error.message);
    }
  };

  const init = async () => {
    setIsLoading(true);
    setObjectConfig({})
    fetchObjectConfiguration()
  };

  const setupEditedCofigs = (editedKey, editedVal) => {
    setEditedConfigs(prevState => ({
      ...prevState, 
      [editedKey]: editedVal
    }));
  };

  const revertChanges = () => {
    setEditedConfigs(null)
    setObjectConfig({})
    setTimeout(() => {
      setObjectConfig(oldConfig)
    }, 0)
  }

  useEffect(() => {
    setCurrentObjId(selectedObject['$dtId'])
    init();
  }, [selectedObject]);


  return (
    <div>
    <div className="h-[calc(100vh-365px)] bg-white overflow-y-auto grid ">
    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr className="divide-x-2 w-full">
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-4 py-2 w-1/2">
                Name
              </th>
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-3 py-2 w-1/2">
                Value
              </th>
              
            </tr>
          </thead>
          <tbody >
            {Object.keys(objectConfig).map((key,index) => (
              <tr key={index} class="bg-white border-b ">
                <th
                  scope="row"
                  class="px-4 py-[2px] font-medium text-gray-900 whitespace-nowrap "
                >
                  {/* <p className="w-20 truncate" title={getPropertyDisplayName(key)}> */}
                    {key}
                    {/* </p> */}
                </th>
                <td class="px-4 py-[2px] text-gray-900">
                  <input
                    className="p-1  rounded border-0 border-[#ccc]  w-[calc(100%-1px)]"
                    type="text"
                    name={key}
                    onChange={e => setupEditedCofigs(key, e.target.value)}
                    defaultValue={objectConfig[key]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
      <div className="flex gap-2 mt-4 justify-end items-center ">
        <div className="opacity-0 w-0 h-0 overflow-hidden">{selectedObject['$dtId']}</div>
          <button
            type="button"
            className=" h-10 px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner uppercase font-semibold"
            onClick={() => setAddConfigModal(!addConfigModal)}
          >
            Add Configuration
          </button>
          <button
            type="submit"
            className="h-10 px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner uppercase font-semibold"
            onClick={() => addObjectConfig(true)}
            disabled={editedConfigs === null}
          >
            Save
          </button>
          <button onClick={revertChanges} disabled={editedConfigs === null} className=" h-10 px-4 py-2 bg-[#EBF0F4] rounded shadow hover:shadow-inner uppercase font-semibold">
            Cancel
          </button>
        </div>

        {addConfigModal &&
          <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
            <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setAddConfigModal(!addConfigModal)}></div>
            <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
              
              <div className="-mt-7 mb-3 flex">
                <div className="flex-1">
                  <div className="  bg-gray-100 w-36 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Add Configuration</div>
                </div>
                
                <div>
                  <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setAddConfigModal(!addConfigModal)}>✖</button>
                </div>
              </div>

              <div className="flex flex-col mt-5 gap-5">
                <div className="flex gap-3">
                  <label className="w-48">Name:</label>
                  <input type="text" className="dysing-input flex-1 p-1 px-2 rounded" onChange={(e) => setNewConfigName(e.target.value)} />
                </div>
                <div className="flex gap-3">
                  <label className="w-48">Value:</label>
                  <input type="text" className="dysing-input flex-1 p-1 px-2 rounded" onChange={(e) => setNewConfigVal(e.target.value)} />
                </div>
                <div className="grid grid-cols-2 gap-3 mt-5">
                  <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={() => addObjectConfig(false)} disabled={!newConfigName || !newConfigVal}>Add</button>
                  <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={() => setAddConfigModal(!addConfigModal)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
    }
      </div>
  );
}
