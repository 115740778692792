import React, { useEffect, useState } from 'react';
import IconOpenArrow from "../../../icons/OpenArrow";
import IconCloseArrow from "../../../icons/CloseArrow";
import IconFile from "../../../icons/File";
import IconFolder from "../../../icons/Folder";
import { CreatePermission, GetRolesData } from '../../api/user';
import { hasPermission, enumPermission } from '../../../utils/permissionUtils';
import { useAuth } from '../../../context/AuthContext';

const RoleConfig = ({ onBackClick, roleId }) => {
    const [permissions, setPermissions] = useState({});
    const [expandedKeys, setExpandedKeys] = useState(new Set());
    const [selectedPermission, setSelectedPermission] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal visibility
    const [modalData, setModalData] = useState();
    const [newControlKey, setNewControlKey] = useState(''); // State for new control key
    const [newControlValue, setNewControlValue] = useState(false);
    const { user } = useAuth();
    const userHasPermission = hasPermission(user);
    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedPer, setSelectedPer] = useState(null);
    const [isAddingControl, setIsAddingControl] = useState(false);
    const [showFolderModal, setShowFolderModal] = useState(false);
    const [newFolderId, setNewFolderId] = useState('');
    const [controlValues, setControlValues] = useState({
        read: false,
        write: false,
        update: false,
        delete: false,
    });
    const [newFolderName, setNewFolderName] = useState('');
    const [newFolderAccess, setNewFolderAccess] = useState(false);
    const [showFolderDelete, setShowFolderDelete] = useState(false);



    const fetchRolesData = async () => {
        const response = await GetRolesData({ roleId });
        if (response.status === 200 && response.data) {
            setPermissions(response.data.permissions || {});
        } else {
            console.error('Failed to fetch roles data:', response.error);
        }
    };

    useEffect(() => {

        fetchRolesData();
    }, [roleId]);

    const toggleExpansion = (key) => {
        setExpandedKeys((prev) => {
            const newExpandedKeys = new Set(prev);
            if (newExpandedKeys.has(key)) {
                newExpandedKeys.delete(key);
            } else {
                newExpandedKeys.add(key);
            }
            return newExpandedKeys;
        });
    };

    const handlePermissionClick = (permission, key) => {
        setSelectedPermission(permission);
        setSelectedKey(key);
        setSelectedPer(permission);
        console.log("id", { key });

    };

    const openEditModal = (data) => {
        setModalData(data);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsAddingControl(false);
    };

    const handleModalChange = (type, value) => {
        if (type === 'control') {
            setModalData((prev) => ({
                ...prev,
                control: value,
            }));
        }
    };

    const handleAddControlClick = () => {
        setIsAddingControl(true);
        setNewControlKey(''); // Clear input field
        setNewControlValue('false'); // Reset to default value
    };

    const toggleFolderModal = () => {
        setShowFolderModal((prev) => !prev);
        console.log("idd", selectedKey)
    };

    const toggleFolderDelete = () => {
        setShowFolderDelete((prev) => !prev);
        console.log("Selected folder ID:", selectedKey);
    };
    const deleteFolder = async () => {
        if (!selectedKey) {
            alert("Please select a folder to delete.");
            return;
        }

        // Clone the permissions object for safe modification
        let updatedPermissions = { ...permissions };

        // Helper function to delete the folder by key
        const deleteFolderByKey = (obj, key) => {
            if (obj && obj.hasOwnProperty(key)) {
                delete obj[key];
                return true;
            }
            for (let prop in obj) {
                if (typeof obj[prop] === 'object' && deleteFolderByKey(obj[prop], key)) {
                    return true;
                }
            }
            return false;
        };

        const folderDeleted = deleteFolderByKey(updatedPermissions, selectedKey);

        if (folderDeleted) {
            try {
                const response = await CreatePermission({ roleId, permissions: updatedPermissions });
                if (response.status === 200) {
                    fetchRolesData(); // Refresh data after deletion
                    toggleFolderDelete(); // Close delete modal
                    setSelectedKey(null); // Reset selected key after deletion
                } else {
                    console.error("Failed to delete folder:", response.error);
                }
            } catch (error) {
                console.error("Error deleting folder:", error);
            }
        } else {
            alert("Folder not found.");
        }
    };

    const onDeleteControl = (controlKey) => {

        const updatedControl = { ...modalData.control };
        delete updatedControl[controlKey]; // Delete the control
        handleModalChange('control', updatedControl); // Update the state
        console.log("Control after deletion:", updatedControl); // Log to verify
    };

    const saveModalData = async () => {
        const existingPermission = selectedPermission[selectedKey];

        const updatedtab = {
            ...selectedPermission,
            name: modalData.name,
            access: modalData.access,
            control: {
                // Check if existingPermission and its control property exist
                ...(existingPermission ? existingPermission.control : {}), // Merge existing control if it exists
                ...modalData.control, // Merge new control data from modal
                // add control key
                ...(newControlKey ? { [newControlKey]: newControlValue } : {}),
            },
        }

        const data = updateOrAddObject(permissions, selectedKey, updatedtab);
        console.log("data", JSON.stringify(data, null, 2));

        try {
            const response = await CreatePermission({ roleId: roleId, permissions: data });

            if (response.status === 200) {
                fetchRolesData()
                setNewControlKey('');
                setNewControlValue('false');
                console.log("Permission updated successfully");
            } else {
                console.error("Failed to update permission:", response.error);
            }
        } catch (error) {
            console.error("Error saving modal data:", error);
        } finally {
            closeModal();
            setIsAddingControl(false);

            const newPremission = findUserManagementTab(permissions, selectedKey)
            console.log("data", selectedKey, permissions, newPremission)
            setSelectedPermission(newPremission)

        }


    }

    const handleControlChange = (permission, value) => {
        setControlValues((prev) => ({
            ...prev,
            [permission]: value === 'true',
        }));
    };

    const saveNewFolder = async () => {
        if (!newFolderName.trim() || !newFolderId.trim()) {
            alert("Folder name and ID cannot be empty.");
            return;
        }

        const newFolder = {
            [newFolderId]: {
                name: newFolderName,
                access: newFolderAccess,
                control: { ...controlValues },
            },
        };

        let updatedPermissions = { ...permissions };

        if (selectedKey) {
            const parentFolder = findUserManagementTab(updatedPermissions, selectedKey);
            if (parentFolder) {
                parentFolder[newFolderId] = newFolder[newFolderId];
            } else {
                alert("Selected parent folder not found.");
                return;
            }
        } else {
            updatedPermissions[newFolderId] = newFolder[newFolderId];
        }

        try {
            const response = await CreatePermission({ roleId, permissions: updatedPermissions });
            if (response.status === 200) {
                fetchRolesData();
                resetFolderForm();
                toggleFolderModal();
            } else {
                console.error("Failed to create folder:", response.error);
            }
        } catch (error) {
            console.error("Error creating folder:", error);
        }
    };

    const resetFolderForm = () => {
        setNewFolderName('');
        setNewFolderId('');
        setNewFolderAccess(false);
        setControlValues({
            read: false,
            write: false,
            update: false,
            delete: false,
        });
        setSelectedKey(null);
    };

    const findUserManagementTab = (obj, key) => {
        if (!obj || typeof obj !== 'object') return null;
        if (obj.hasOwnProperty(key)) return obj[key];
        for (let prop in obj) {
            const result = findUserManagementTab(obj[prop], key);
            if (result) return result;
        }
        return null;
    };

    function updateOrAddObject(data, key, newObject) {
        // Recursive function to traverse and update/add
        function traverse(obj) {
            // If we found the key, update it
            if (obj && obj.hasOwnProperty(key)) {
                obj[key] = newObject;
                return true;
            }

            // If object is array or object, traverse deeper
            if (obj && typeof obj === 'object') {
                for (let prop in obj) {
                    if (traverse(obj[prop])) {
                        return true;
                    }
                }
            }

            return false;
        }

        // If object wasn't found and updated, add it at appropriate level
        if (!traverse(data)) {
            // Find a suitable parent object to add to (first object with nested tabs)
            function findParent(obj) {
                if (!obj || typeof obj !== 'object') return null;

                for (let prop in obj) {
                    if (obj[prop] && typeof obj[prop] === 'object') {
                        // Check if current object has any *_tab properties
                        if (Object.keys(obj[prop]).some(k => k.endsWith('_tab'))) {
                            return obj[prop];
                        }
                        const parent = findParent(obj[prop]);
                        if (parent) return parent;
                    }
                }
                return null;
            }

            const parent = findParent(data);
            if (parent) {
                parent[key] = newObject;
            }
        }

        return data;
    }

    const renderPermissions = (permissions) => {
        return Object.keys(permissions)?.map((key) => {
            if (key === "access" || key === "control") {
                return null;
            }

            const { name, ...children } = permissions[key];
            const isExpanded = expandedKeys.has(key);
            const hasChildren = Object.keys(children).length > 0;
            const isSelected = selectedPermission?.name === permissions[key]?.name;

            return (
                <div key={key}>
                    <h4
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (hasChildren) {
                                toggleExpansion(key);
                            }
                            handlePermissionClick(permissions[key], key);
                        }}
                        className={`flex items-center text-lg gap-2 hover:bg-gray-100 ${isSelected ? 'bg-blue-100' : ''}`}
                    >
                        {hasChildren ? (
                            isExpanded ? (
                                <IconOpenArrow className="text-blue-300" />
                            ) : (
                                <IconCloseArrow className="text-blue-300" />
                            )
                        ) : (
                            <IconFile className="text-blue-300" />
                        )}
                        {hasChildren && <IconFolder className="text-blue-300" />}
                        {name}
                    </h4>
                    {isExpanded && hasChildren && (
                        <div style={{ marginLeft: '20px' }}>
                            {renderPermissions(children)}
                        </div>
                    )}
                </div>
            );
        });
    };

    const renderTable = () => {
        if (!selectedPermission) {
            return <p>Select a permission to view details</p>;
        }

        const { access, control } = selectedPermission;
        const userHasPermission = hasPermission(user);

        return (
            <>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="sticky top-0 bg-[#B6D3EA] px-4 py-2 w-4/12">Name</th>
                            <th className="sticky top-0 bg-[#B6D3EA] px-3 py-2 w-3/12">
                                <div className="flex justify-between items-center">
                                    <span>Value</span>
                                    {userHasPermission && (
                                        <button
                                            onClick={() => openEditModal({ name: selectedPermission.name, access, control })}
                                            className='px-1 hover:bg-gray-300 hover:text-blue-500 rounded'>
                                            <svg className="h-4 w-4" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                <line x1="16" y1="5" x2="19" y2="8" />
                                            </svg>
                                        </button>
                                    )}
                                </div>
                            </th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr className="bg-white border-b">
                            <th className="px-4 py-[2px] font-medium text-gray-900 whitespace-nowrap">
                                ID
                            </th>
                            <td className="px-2 py-[2px] text-black font-semibold ">
                                {selectedKey}
                            </td>
                        </tr>
                        <tr className="bg-white border-b">
                            <th className="px-4 py-[2px] font-medium text-gray-900 whitespace-nowrap">
                                Name
                            </th>
                            <td className="px-2 py-[2px] text-black font-medium">
                                {selectedPermission?.name}
                            </td>
                        </tr>
                        <tr className="bg-white border-b">
                            <th className="px-4 py-[2px] font-medium text-gray-900 whitespace-nowrap">
                                Access
                            </th>
                            <td className="px-2 py-[2px] flex justify-between">
                                <input
                                    className="p-1 rounded border-0 border-[#ccc] w-[calc(100%-1px)]"
                                    type="text"
                                    value={access}
                                    readOnly
                                />

                            </td>
                        </tr>
                        {control && Object.keys(control).map((controlKey) => (
                            <tr key={controlKey} className="bg-white border-b">
                                <th className="px-4 py-[2px] font-medium text-gray-900 whitespace-nowrap">
                                    {controlKey.charAt(0).toUpperCase() + controlKey.slice(1)}
                                </th>
                                <td className="px-2 py-[2px] flex justify-between">
                                    <input
                                        className="p-1 rounded border-0 border-[#ccc] w-[calc(100%-1px)]"
                                        type="text"
                                        value={control[controlKey] ? "True" : "False"}
                                        readOnly
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>

        );
    };

    const renderModal = () => {
        if (!isModalOpen) return null;

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-40">
                <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg">
                    <h2 className="text-lg font-semibold mb-4">Update Permission</h2>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Access</label>
                        <select
                            value={modalData.access ? "True" : "False"}
                            onChange={(e) => handleModalChange('access', e.target.value === "True")}
                            className="mt-1 p-1 border rounded w-full"
                        >
                            <option value="True">True</option>
                            <option value="False">False</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-semibold text-gray-700">Control</label>
                        {Object.keys(modalData.control).map((key) => (
                            <div key={key} className="flex items-center gap-1 mb-1">
                                <label className="text-sm capitalize">{key}</label>
                                <select
                                    value={modalData.control[key] ? "True" : "False"}
                                    onChange={(e) =>
                                        handleModalChange('control', {
                                            ...modalData.control,
                                            [key]: e.target.value === "True",
                                        })
                                    }
                                    className="mt-1 w-full p-1 border rounded"
                                >
                                    <option value="True">True</option>
                                    <option value="False">False</option>
                                </select>
                                {key !== "read" && key !== "write" && key !== "delete" && key !== "update" && (
                                    <button className='hover:text-red-500' onClick={() => onDeleteControl(key)}>
                                        <svg className="h-4 w-4" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <line x1="4" y1="7" x2="20" y2="7" />
                                            <line x1="10" y1="11" x2="10" y2="17" />
                                            <line x1="14" y1="11" x2="14" y2="17" />
                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={handleAddControlClick}
                        className="px-3 py-1 bg-gray-200 rounded shadow-md shadow-gray-500 hover:shadow-inner hover:shadow-gray-500 mb-2">Add Control</button>
                    {/* add control feild */}
                    {isAddingControl && (<div className='mb-4'>
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th className="sticky top-0 bg-[#c8e0f4] px-2 py-1 w-4/12">Name</th>
                                    <th className="sticky top-0 bg-[#c8e0f4] px-2 py-1 w-3/12">Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-white border">
                                    <td className="px-4 py-[2px]">
                                        <input
                                            className="p-1 rounded border-0 border-[#ccc] w-full placeholder:text-red-400"
                                            type="text"
                                            placeholder="New Control Key"
                                            value={newControlKey}
                                            onChange={(e) => setNewControlKey(e.target.value)}
                                        />
                                    </td>
                                    <td className="px-2 py-[2px] flex justify-between">
                                        <select
                                            className="p-1 rounded border-0 border-[#ccc] w-full"
                                            value={newControlValue}
                                            onChange={(e) => setNewControlValue(e.target.value === 'true')}
                                        >
                                            <option value="false">False</option>
                                            <option value="true">True</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>)}
                    <div className="flex justify-end gap-2">
                        <button onClick={closeModal} className="px-3 py-1 bg-gray-200 rounded shadow-md shadow-gray-500 hover:shadow-inner hover:shadow-gray-500">Cancel</button>
                        <button onClick={saveModalData} className="px-3 py-1 bg-[#5ea7e6] text-white rounded shadow-md shadow-gray-500 hover:shadow-inner hover:shadow-gray-500">Save</button>
                    </div>
                </div>
            </div>
        );

    };

    const renderFolder = (key) => {
        if (!showFolderModal) return null;

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg">
                    <h1 className="text-lg font-semibold mb-4">New Screen</h1>

                    <div className="flex items-center gap-1 mb-1">
                        <label className="block text-sm font-medium text-gray-700">ID </label>
                        <input
                            type="text"
                            className="mt-1 p-1 border rounded w-full"
                            placeholder="ID Name"
                            value={newFolderId}
                            onChange={(e) => setNewFolderId(e.target.value)}
                        />
                    </div>

                    <div className="flex items-center gap-1 mb-1">
                        <label className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            className="mt-1 p-1 border rounded w-full"
                            placeholder="Folder Name"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                        />
                    </div>

                    <div className="flex items-center gap-1 mb-1">
                        <label className="block text-sm font-medium text-gray-700">Access</label>
                        <select
                            className="mt-1 p-1 border rounded w-full"
                            value={newFolderAccess}
                            onChange={(e) => setNewFolderAccess(e.target.value === 'true')}
                        >
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-semibold text-gray-700">Control</label>
                        <div className="flex items-center gap-1 mb-1">
                            <label className="text-sm capitalize">Read</label>
                            <select
                                className="mt-1 p-1 border rounded w-full"
                                value={controlValues.read.toString()}
                                onChange={(e) => handleControlChange('read', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                        <div className="flex items-center gap-1 mb-1">
                            <label className="text-sm capitalize">Write</label>
                            <select
                                className="mt-1 p-1 border rounded w-full"
                                value={controlValues.write.toString()}
                                onChange={(e) => handleControlChange('write', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                        <div className="flex items-center gap-1 mb-1">
                            <label className="text-sm capitalize">Update</label>
                            <select
                                className="mt-1 p-1 border rounded w-full"
                                value={controlValues.update.toString()}
                                onChange={(e) => handleControlChange('update', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                        <div className="flex items-center gap-1 mb-1">
                            <label className="text-sm capitalize">Delete</label>
                            <select
                                className="mt-1 p-1 border rounded w-full"
                                value={controlValues.delete.toString()}
                                onChange={(e) => handleControlChange('delete', e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Parent Folder</label>
                        <input
                            className="mt-1 p-1 border rounded w-full"
                            value={selectedKey}
                        >
                        </input>
                    </div>
                    <div className="flex justify-end gap-2">
                        <button onClick={toggleFolderModal} className="px-3 py-1 bg-gray-200 rounded shadow-md">
                            Cancel
                        </button>
                        <button onClick={saveNewFolder} className="px-3 py-1 bg-blue-500 text-white rounded shadow-md">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        );
    };
    const renderDelete = () => {
        return (
            <div className={`fixed inset-0 flex items-center justify-center z-50 ${showFolderDelete ? 'block' : 'hidden'}`}>
                <div className="fixed inset-0 bg-black opacity-50" onClick={toggleFolderDelete}></div>
                <div className="bg-white rounded-lg shadow-lg max-w-sm w-full z-10 p-6">
                    <h2 className="text-lg font-semibold mb-4">Confirm Deletion</h2>
                    <p>Are you sure you want to delete <span className='text-red-500'>{selectedKey}</span></p>
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={toggleFolderDelete}
                            className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={deleteFolder}
                            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="p-2 h-[calc(100vh-186px)] px-20 bg-blue-50 flex gap-4">
                <div className="w-full flex flex-1">
                    <div className="w-full h-full bg-blue-100 p-2">
                        <button
                            onClick={onBackClick}
                            className="h-10 px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner text-xs uppercase font-semibold mb-3"
                        >
                            Back
                        </button>
                        <div className='flex space-x-4'>
                            <div className="rightPanel shadow-sm" style={{ width: "340px" }}>
                                <div className="h-[calc(100vh-284px)] p-2 overflow-auto bg-white">
                                    {userHasPermission && (
                                        <div className='flex justify-end gap-1'>
                                            {selectedKey && (
                                                <button
                                                    onClick={toggleFolderDelete}
                                                    className=" flex items-center h-7 px-2 bg-blue-100 text-gray-500 rounded shadow hover:shadow-inner text-xs uppercase font-semibold mb-3 hover:text-red-500">
                                                    <svg className="h-4 w-4" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <line x1="4" y1="7" x2="20" y2="7" />
                                                        <line x1="10" y1="11" x2="10" y2="17" />
                                                        <line x1="14" y1="11" x2="14" y2="17" />
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                    </svg>
                                                </button>
                                            )}
                                            <button
                                                onClick={toggleFolderModal}
                                                className=" flex items-center h-7 px-2 bg-blue-100 text-gray-500 rounded shadow hover:shadow-inner text-xs uppercase font-semibold mb-3 hover:text-blue-500">
                                                <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />  <line x1="12" y1="10" x2="12" y2="16" />  <line x1="9" y1="13" x2="15" y2="13" /></svg>
                                            </button>
                                        </div>
                                    )}
                                    {Object.keys(permissions).length > 0 ? (
                                        renderPermissions(permissions)
                                    ) : (
                                        <p>Loading permissions...</p>
                                    )}
                                </div>
                            </div>
                            <div className="w-full">
                                {renderTable()}
                            </div>
                            {renderModal()}
                            {renderFolder()}
                            {renderDelete()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoleConfig;
