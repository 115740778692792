import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, X, Search } from "heroicons-react";

const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback]);

  return ref;
};

const CustomMultiSelect = ({ options, value, onChange, placeholder, enableSearch = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const ref = useOutsideClick(() => setIsOpen(false));

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    const newValue = value.includes(option.value)
      ? value.filter((v) => v !== option.value)
      : [...value, option.value];
    onChange(newValue);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className="relative w-[calc(100%-8px)]" ref={ref}>
      <div
        className="p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white"
        onClick={handleToggle}
      >
        <div className="flex flex-wrap gap-1">
          {value.length > 0 ? (
            value.map((v) => (
              <span key={v} className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                {options.find((opt) => opt.value === v)?.label}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect({ value: v });
                  }}
                  className="ml-1 text-blue-600 hover:text-blue-800"
                >
                  <X size={14} />
                </button>
              </span>
            ))
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>
        <ChevronDown size={20} className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
          {enableSearch && (
            <div className="p-2 sticky top-0 bg-white border-b">
              <div className="relative">
                <Search size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search options..."
                  className="w-full pl-10 pr-4 py-2 border rounded-md"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
                {searchTerm && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      clearSearch();
                    }}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <X size={20} />
                  </button>
                )}
              </div>
            </div>
          )}
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`p-2 m-1 hover:bg-gray-100 cursor-pointer ${
                  value.includes(option.value) ? 'bg-blue-50' : ''
                }`}
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className="p-2 m-1 text-gray-500">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;