import { createContext, useContext, useEffect, useState } from "react";
import { GetUserDetail, GetRolesData } from "../components/api/user";
import Cookies from "js-cookie";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [permissionRole, setPermissionRole] = useState(null);

    useEffect(() => {
        const userId = Cookies.get("userId");
        if (userId) {
            setUserDetail(userId);
        }
    }, []);

    const setUserSession = async (userData) => {
        const userId = userData.user?.global_id;
        await setUserDetail(userId);
    };

    const clearUserSession = () => {
        setUser(null);
        setPermissionRole(null);
        // Cookies.remove("userId");
    };

    const setUserDetail = async (userId) => {
        const res = await GetUserDetail(userId);
        const userDetail = res?.data?.result || null;
        console.log(userDetail, 'userDetail');
        setUser(userDetail);

        if (userDetail && userDetail?.role_permissions[0]?.id) {
            await fetchUserPermissions(userDetail.role_permissions[0]?.id);
        }
    };

    const fetchUserPermissions = async (roleId) => {
        const res = await GetRolesData({ roleId });
        const rolePermissions = res?.data || null;
        console.log(rolePermissions, 'rolePermissions');
        setPermissionRole(rolePermissions);
    };

    return (
        <AuthContext.Provider value={{ user, permissionRole, setUserSession, clearUserSession }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
