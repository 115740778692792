import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import Header from '../Parts/headerComponents/header';
import TraficIcon from '../../Images/svg/icons/traficIcon';
import EventsIcon from '../../Images/svg/icons/eventsIcon';
import RecepiIcon from '../../Images/svg/icons/recepiIcon';
import IoeditorIcon from '../../Images/svg/icons/ioeditorIcon';
import Analyticsicon from '../../Images/svg/icons/analyticsicon';
import ScreensIcon from '../../Images/svg/icons/screensIcon';
import E95 from './e95/e95';
import Iec from './Iec/iec';
import Analytics from './analytics/analytics';
import IO from './io/io';
import Recipes from './recipes/recipes';
import Events from './events/events';
import CookieCheck from './Auth/cookieCheck';
import Images from '../../Images';
import Copilot from './copilot/copilot';
import CopilotTab from '../Parts/copilot/copilotTab';
import AlarmNotificationTab from '../Parts/alarmNotification/alarmNotificationTab';
import Alarm from '../../Images/svg/alarm';
import AlarmNotification from '../Parts/alarmNotification/alarmNotification';
import Alarms from './alarms/alarms';
import TabNav from '../Parts/tabNav/tabNav';
import TabsDinamic from './Iec/tabsDinamic';
import Gasbox from './Iec/gasbox';
import Motor2 from './Iec/motor2';
import DicingRIE from './dicingRIE/dicingRIE';
import GasBoxdicingrie from './dicingRIE/gasBoxdicingrie';
import Dicingmotor from './dicingRIE/dicingmotor';
import E95Maintenance from './e95/e95Maintenance';
import E30 from './e30/e30';
import Sqns from './sqns/sqns';
import Interlocks from './interlocks/interlocks';
import ConfigEditorTab from '../Parts/configEditor/configEditorTab';
import ConfigEditor from '../Parts/configEditor/configEditor';
import EFEM from './efem/efem';
import Efemtesting from './efem/efemtesting';
import Iecnew from './Iec/iecnew';
import UserList from '../Parts/configEditor/userList';
import Permission from '../Parts/configEditor/permission';
import RoleConfig from '../Parts/configEditor/roleConfig';
import { useAuth } from '../../context/AuthContext';
import DicingDtdl from './dicingRIE/dicingDtdl';
import DeviceAlarmNotification from '../Parts/alarmNotification/deviceAlarmNotification';
import { hasPermission ,enumPermission } from '../../utils/permissionUtils';
import IecDtdl from './Iec/iecDtdl';
import WaferFlow from './waferflow/waferflow';
import JobSequence from './jobsequence/jobsequence';
import IoService from './ioService';

export default function TabScreen(props) {
  const { user,permissionRole } = useAuth();
  const [pageHome, setpageHome] = useState(1);
  const [pageSecondary, setPageSecondary] = useState(null);
  const [pageIsotopic, setpageIsotopic] = useState(null);
  const [pageIsotopicnew, setpageIsotopicnew] = useState(null);
  const [pageIsotopicDtdl, setpageIsotopicDtdl] = useState(null);
  const [pageIsotopicgasbox, setpageIsotopicgasbox] = useState(null);
  const [pageIsotopicmotor, setpageIsotopicmotor] = useState(null);
  const [pagedicing, setpagedicing] = useState(null);
  const [pagedicingGasbox, setpagedicingGasbox] = useState(null);
  const [pagedicingmotor, setpagedicingmotor] = useState(null);
  const [pagedicingDtdl, setpagedicingDtdl] = useState(null);
  const [pageTransfer, setpageTransfer] = useState(null);
  const [pageAnalytics, setpageAnalytics] = useState(null);
  const [pageIO, setpageIO] = useState(null);
  const [pageEvents, setpageEvents] = useState(null);
  const [pageAlarms, setpageAlarms] = useState(null);
  const [pageRecipes, setpageRecipes] = useState(null);
  const [pageSequence, setpageSequence] = useState(null);
  const [pageInterlocks, setpageInterlocks] = useState(null);
  const [pageCopilot, setpageCopilot] = useState(null);
  const [pageConfigEditor, setpageConfigEditor] = useState(null);
  const [pageUserList, setpageUserList] = useState(null);
  const [pagePermission, setpagePermission] = useState(null);
  const [pageRoleConfig, setpageRoleConfig] = useState(null);


  const [pageefem, setpageefem] = useState(null);
  const [pageefemtesting, setpageefemtesting] = useState(null);
  const [pageJobSequence, setpageJobSequence] = useState(null);
  const [pageWaferflow, setpageWaferflow] = useState(null);
  // Dicing chamber navigation
  const [dicingChamber_A, setDicingChamber_A] = useState(false)


  const stateSetters = {
    setpageHome,
    setPageSecondary,
    setpageIsotopic, setpageIsotopicgasbox, setpageIsotopicnew, setpageIsotopicDtdl,
    setpageIsotopicmotor, setpagedicing,
    setpagedicingGasbox, setpagedicingDtdl, setpageSequence, setpageefem, setpageefemtesting,
    setpageInterlocks, setpagedicingmotor,
    setpageAnalytics, setpageTransfer,
    setpageEvents, setpageAlarms, setpageRecipes,
    setpageIO, setpageCopilot,
    setpageConfigEditor, setpagePermission, setpageRoleConfig, setpageUserList, setpageWaferflow, setpageJobSequence
  };

  const updatePage = (setterName) => {
    if (stateSetters[setterName]) {
      stateSetters[setterName](1);
      console.log(setterName)
    } else {
      console.error(`Setter function ${setterName} not found.`);
    }
  };
  const [currentTab, setCurrentTab] = useState('home');
  const [head, setHead] = useState('Main');
  const [subhead, setsubhead] = useState('');

  let layoutdesign = props.layoutdesign;

  const changeCurrentTab = (a) => {
    setCurrentTab(a)
  }
  let header = [
    {
      name: 'IoService',
      module: <IoService />,
      type: 'image',
      data: {  }
    },
    {
      name: 'Images.svg.logo',
      module: <Images.svg.Logo />,
      type: 'image',
      data: { className: 'flex-1 pt-4', height: 101, width: 100 }
    },
    {
      name: 'Images.svg.EventsDisplay',
      module: <Images.svg.EventsDisplay currentTab={currentTab} setCurrentTab={setCurrentTab} setHead={setHead} setsubhead={setsubhead} updatePage={updatePage}/>,
      type: 'image',
      data: { className: '', height: 101, width: 500 }
    },
    {
      name: 'Images.svg.AlarmNotification',
      module: <DeviceAlarmNotification currentTab={currentTab} setCurrentTab={setCurrentTab} setHead={setHead} setsubhead={setsubhead} updatePage={updatePage} />,
      type: 'image',
      data: { className: '', height: 86, width: 86 }
    },
    {
      name: 'Images.svg.AlarmNotification',
      module: <CopilotTab currentTab={currentTab} setCurrentTab={setCurrentTab} setHead={setHead} setsubhead={setsubhead} updatePage={updatePage} />,
      type: 'image',
      data: { className: '', height: 86, width: 86 }
    },
    
    {
      name: 'Images.svg.AlarmNotification',
      module: <ConfigEditorTab currentTab={currentTab} setCurrentTab={setCurrentTab} setHead={setHead} setsubhead={setsubhead} updatePage={updatePage} />,
      type: 'image',
      data: { className: '', height: 86, width: 86 }
    },
    // {
    //   name:'Images.svg.Alarm',
    //   module:<Images.svg.Alarm />,
    //   type:'image',
    //   data:{className:'',height:101,width:100}
    // },
    {
      name: 'Images.svg.CommunicationHost',
      module: <Images.svg.CommunicationHost />,
      type: 'image',
      title: 'chost',
      data: { class: 'pb-12' }
    },
    {
      name: 'Images.svg.Alarmpause',
      module: <Images.svg.Alarmpause />,
      type: 'image',
      data: { class: 'pb-12' }
    },
    {
      name: 'Images.svg.Alarmseveritylevel',
      module: <Images.svg.Alarmseveritylevel />,
      type: 'image',
      data: { className: 'pt-2' }
    },
    {
      name: 'Images.svg.Logout',
      module: <Images.svg.Logout />,
      type: 'image',
      title: 'chost',
      data: { class: 'pb-12' }
    },
  ];
  let tabs = [
    {
      name: 'home',
      tabs: [
        { link: '/', name: 'Main', isActive: true, tab: 'setpageHome' },
        { link: '/e30', name: 'Secondary', isActive: true, tab: 'setPageSecondary' },
        // { link: '', name: 'Other' },
      ]

    },
    {
      name: 'maintenance',
      tabs: [
        // {
        //   link: '/iec', name: 'Isotropic Etch Chamber', sub: 'Main', isActive: true, tab: 'setpageIsotopicgasbox',
        //   subtabs: [
        //     { link: '/iec', name: 'Main', isActive: true, tab: 'setpageIsotopic' },
        //     { link: '/iecNEW', name: 'IecChamber', isActive: true, tab: 'setpageIsotopicnew' },
        //     { link: '/iecdtdl', name: 'IecChamber', isActive: true, tab: 'setpageIsotopicDtdl' },
        //     { link: '/gasbox', name: 'GasBox', isActive: true, tab: 'setpageIsotopicgasbox' },
        //     { link: '/motor2', name: 'MotorOption', isActive: true, tab: 'setpageIsotopicmotor' }
        //   ]
        // },
        {
          link: '/dicingRIEChamber', name: 'Dicing RIE Chamber', isActive: true, sub: 'DicingDtdl', tab: 'setpagedicingDtdl',
          // subtabs: [
          //   { link: '/dicingRIEChamber', name: 'Main', isActive: true, tab: 'setpagedicing' },
          //   { link: '/gasBoxdicingrie', name: 'GasBox', isActive: true, tab: 'setpagedicingGasbox' },
          //   { link: '/dicingmotor', name: 'MotorOption', isActive: true, tab: 'setpagedicingmotor' },
          //   { link: '/dicingDtdl', name: 'DicingDTDL', isActive: true, tab: 'setpagedicingDtdl' },
          // ]
        },
        {
          link: '/transferchamber', name: 'Transfer Chamber', isActive: true, sub: 'Main', tab: 'setpageTransfer',
         
        },
        // { link: '/efem', name: 'EFEM Testing', isActive: true, sub: 'efem', tab: 'setpageefem' },
        { link: '/efemtesting', name: 'EFEM', isActive: true, sub: 'Main', tab: 'setpageefemtesting' }
      ],

    },
    {
      name: 'events',
      tabs: [
        { link: '/events', name: 'Events', isActive: true, tab: 'setpageEvents' },
        { link: '/alarms', name: 'Alarms', isActive: true, tab: 'setpageAlarms' }
      ]

    }, {
      name: 'recipes',
      tabs: [
        { link: '/recipes', name: 'Recipe Editor', isActive: true },
        { link: '/sqns', name: 'Workflow', isActive: true, tab: 'setpageSequence' },
        { link: '/interlocks', name: 'Interlock', isActive: true, tab: 'setpageInterlocks' },
        { link: '/wfrflow', name: 'Wafer Flow', isActive: true, tab: 'setpageWaferflow' },
        { link: '/jbsequence', name: 'Job Sequence', isActive: true, tab: 'setpageJobSequence' },
      ]

    },
    {
      name: 'configEditor',
      tabs: [
        { link: '/config', name: 'Tool Configuration', isActive:true, sub: 'Users List', tab: 'setpageConfigEditor' },
        {
          link: '/userList', name: 'User Management', isActive: hasPermission(user, permissionRole,enumPermission.USER_MANAGEMENT,'read'), sub: 'Main', tab: 'setpageUserList', subtabs: [
            { link: '/userList', name: 'Users List', isActive: true, tab: 'setpageUserList' },
            { link: '/permission', name: 'Permission', isActive: true, tab: 'setpagePermission' },
            { link: '/roleconfig', name: 'Role Config', isActive: true, tab: 'setpageRoleConfig' },
          ]
        },
      ]
    }
  ]



  const [lastHomeHead, setlastHomeHead] = useState(null);
  const [lastmaintenanceHead, setlastmaintenanceHead] = useState(null);
  const [lastmaintenancesubHead, setlastmaintenancesubHead] = useState(null);
  const [lastrecipeHead, setlastrecipeHead] = useState(null);
  const [lasteventsHead, setlasteventsHead] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const headchecker = (theCurrentTab, Head) => {

    setCurrentTab(theCurrentTab);
    if (theCurrentTab == "home") {
      if (lastHomeHead == null) { setHead(Head); setlastHomeHead('Main'); } else { setHead(lastHomeHead); }
    }
    if (theCurrentTab == "maintenance") {
      if (lastmaintenanceHead == null) { 
        setHead(Head); setlastmaintenanceHead('Dicing RIE Chamber'); setsubhead("DicingDtdl"); 
      } else {
        setHead(lastmaintenanceHead);
        if (lastmaintenancesubHead != null) {
          setsubhead(lastmaintenancesubHead);
          // alert(lastmaintenancesubHead)
        } else { setsubhead("DicingDtdl"); }
      }
    }
    if (theCurrentTab == "recipes") {
      if (lastrecipeHead == null) { setHead(Head); setlastrecipeHead('Recipe Editor'); } else { setHead(lastrecipeHead); }
    }
    if (theCurrentTab == "events") {
      if (lasteventsHead == null) { setHead(Head); setlasteventsHead('Events'); } else { setHead(lasteventsHead); }
    }
  }
  const Tabheadchecker = (Head) => {
    if (currentTab == "home") {
      setlastHomeHead(Head);
    }
    if (currentTab == "maintenance") {
      setlastmaintenanceHead(Head);
    }
    if (currentTab == "recipes") {
      setlastrecipeHead(Head);
    }
    if (currentTab == "events") {
      setlasteventsHead(Head);
    }
  }
  const subheadchecker = (subh) => {
    setsubhead(subh);
    if (currentTab == "maintenance") {
      setlastmaintenancesubHead(subh);
      // alert('hii')
    }

  }

  useEffect(() => {
    const userCookie = Cookies.get('token');
    if (!userCookie) {
      // Redirect to the home page if the 'user' cookie exists
      window.location.href = '/login';
    } else {
      setIsLoading(false);
    }

  }, []);

  if (isLoading) {
    return <div></div>; // Or any loading indicator
  }
  // Dicing chamber navigation
  if(dicingChamber_A){
    headchecker('maintenance', 'Dicing RIE Chamber')
    updatePage('setpagedicingDtdl')
    setsubhead('DicingDtdl')
    setDicingChamber_A(false)

  }
  

  return (
    <div className="">
      <>
        <header className="flex  header-bg  px-20">
          {header.map(({ name, module, type, data }, index) => (

            React.cloneElement(module, data)
          ))}
        </header>
        {/* <CookieCheck /> */}
      </>
      <div className="px-20 bg-blue-50  gap-4 middle-screen pt-1">
        {/* {currentTab}-  {head} - {subhead} - {lastmaintenancesubHead} */}
        <TabsDinamic tabs={tabs} type={currentTab} setCurrentTab={setCurrentTab} Tabheadchecker={Tabheadchecker} setHead={setHead} head={head} setsubhead={subheadchecker} subhead={subhead} updatePage={updatePage}></TabsDinamic>
      </div>
      <div className=''>
        {pageHome && <div className={`relative ${currentTab == "home" && head == "Main" ? '' : 'hidden'}`}><E95 layoutdesign={props.layoutdesign} setDicingChamber_A = {setDicingChamber_A} /></div>}
        {pageSecondary && <div className={`${currentTab == "home" && head == "Secondary" ? '' : 'hidden'}`}><E30 layoutdesign={props.layoutdesign} /></div>}

        {/* {pageIsotopic && <div className={`${currentTab == "maintenance" && head == "Isotropic Etch Chamber" && subhead == "Main" ? '' : 'hidden'}`}> <Iec layoutdesign={layoutdesign} /></div>}
        {pageIsotopicnew && <div className={`${currentTab == "maintenance" && head == "Isotropic Etch Chamber" && subhead == "IecChamber" ? '' : 'hidden'}`}> <Iecnew layoutdesign={layoutdesign} /></div>}
        {pageIsotopicDtdl && <div className={`${currentTab == "maintenance" && head == "Isotropic Etch Chamber" && subhead == "Iecdtdl" ? '' : 'hidden'}`}> <IecDtdl layoutdesign={layoutdesign} /></div>}
        {pageIsotopicgasbox && <div className={`${currentTab == "maintenance" && head == "Isotropic Etch Chamber" && subhead == "GasBox" ? '' : 'hidden'}`}> <Gasbox layoutdesign={layoutdesign} /></div>}
        {pageIsotopicmotor && <div className={`${currentTab == "maintenance" && head == "Isotropic Etch Chamber" && subhead == "MotorOption" ? '' : 'hidden'}`}> <Motor2 layoutdesign={layoutdesign} /></div>} */}

        {pagedicing && <div className={`${currentTab == "maintenance" && head == "Dicing RIE Chamber" && subhead == "Main" ? '' : 'hidden'}`}> <DicingRIE layoutdesign={layoutdesign} /></div>}
        {pagedicingGasbox && <div className={`${currentTab == "maintenance" && head == "Dicing RIE Chamber" && subhead == "GasBox" ? '' : 'hidden'}`}> <GasBoxdicingrie layoutdesign={layoutdesign} /></div>}
        {pagedicingmotor && <div className={`${currentTab == "maintenance" && head == "Dicing RIE Chamber" && subhead == "MotorOption" ? '' : 'hidden'}`}> <Dicingmotor layoutdesign={layoutdesign} /></div>}
        {pagedicingDtdl && <div className={`${currentTab == "maintenance" && head == "Dicing RIE Chamber" && subhead == "DicingDtdl" ? '' : 'hidden'}`}> <DicingDtdl layoutdesign={layoutdesign} /></div>}

        {pageTransfer && <div className={`relative ${currentTab == "maintenance" && head == "Transfer Chamber"  ? '' : 'hidden'}`}> <E95Maintenance layoutdesign={layoutdesign} /></div>}
        {pageefem && <div className={`relative ${currentTab == "maintenance" && head == "EFEM Testing" ? '' : 'hidden'}`}> <EFEM layoutdesign={layoutdesign} /></div>}
        {pageefemtesting && <div className={`relative ${currentTab == "maintenance" && head == "EFEM" ? '' : 'hidden'}`}> <Efemtesting layoutdesign={layoutdesign} /></div>}

        {pageAnalytics && <div className={`${currentTab != "Analytics" && 'hidden'}`}> <Analytics layoutdesign={layoutdesign} /></div>}

        {pageIO && <div className={`${currentTab != "io" && 'hidden'}`}> <IO layoutdesign={layoutdesign} /></div>}

        {pageRecipes && <div className={`${currentTab == "recipes" && head == "Recipe Editor" ? '' : 'hidden'}`}> <Recipes layoutdesign={layoutdesign} /></div>}
        {pageSequence && <div className={`${currentTab == "recipes" && head == "Workflow" ? '' : 'hidden'}`}> <Sqns layoutdesign={layoutdesign} /></div>}
        {pageInterlocks && <div className={`${currentTab == "recipes" && head == "Interlock" ? '' : 'hidden'}`}> <Interlocks layoutdesign={layoutdesign} /></div>}
        {pageWaferflow && <div className={`${currentTab == "recipes" && head == "Wafer Flow" ? '' : 'hidden'}`}> <WaferFlow layoutdesign={layoutdesign} /></div>}
        {pageJobSequence && <div className={`${currentTab == "recipes" && head == "Job Sequence" ? '' : 'hidden'}`}> <JobSequence layoutdesign={layoutdesign} /></div>}
        {pageEvents && <div className={`${currentTab == "events" && head == "Events" ? '' : 'hidden'}`}> <Events layoutdesign={layoutdesign} /></div>}

        {pageCopilot && <div className={`${currentTab != "copilot" && 'hidden'}`}> <Copilot layoutdesign={layoutdesign} /></div>}
        {pageAlarms && <div className={`${currentTab == "events" && head == "Alarms" ? '' : 'hidden'}`}>  <Alarms layoutdesign={layoutdesign} /></div>}

        {pageConfigEditor && <div className={`${currentTab == "configEditor" && head == "Tool Configuration" ? '' : 'hidden'}`}>  <ConfigEditor layoutdesign={layoutdesign} /></div>}
        {pageUserList && <div className={`${currentTab == "configEditor" && head == "User Management" && subhead == "Main" ? '' : 'hidden'}`}>  <UserList layoutdesign={layoutdesign} /></div>}
        {/* {pagePermission && <div className={`${currentTab == "configEditor" && head == "User Management" && subhead == "Permission" ? '' : 'hidden'}`}>  <Permission layoutdesign={layoutdesign} /></div>} */}
        {pageRoleConfig && <div className={`${currentTab == "configEditor" && head == "User Management" && subhead == "Role" ? '' : 'hidden'}`}>  <RoleConfig layoutdesign={layoutdesign} /></div>}
      </div>
      <footer className="flex  gap-2 items-stretch header-bg  px-20 fixed bottom-0 w-full py-1">

        <button onClick={() => { headchecker('home', 'Main'); setsubhead("") }}
          className={`flex gap-2 flex-1 my-2 bg-gray-300 navbtn ${currentTab == 'home' && 'navactive'}`}>
          <TraficIcon className="navicon" /> Home
        </button>
        <button onClick={() => { headchecker('maintenance', 'Dicing RIE Chamber'); updatePage('setpagedicingDtdl');setsubhead('DicingDtdl'); }}
          className={`flex flex-1  my-2 bg-gray-300 navbtn  ${currentTab == 'maintenance' && 'navactive'}`}>
          <ScreensIcon className="navicon mr-2" /> Maintenance
        </button>

        <button onClick={() => { setCurrentTab('Analytics'); setHead(''); setsubhead(""); updatePage('setpageAnalytics') }}
          className={`flex flex-1  my-2 bg-gray-300 navbtn  ${currentTab == 'Analytics' && 'navactive'}`}>
          <Analyticsicon className="navicon mr-2" /> Analytics
        </button>

        <button onClick={() => { setCurrentTab('io'); setHead(''); setsubhead(""); updatePage('setpageIO') }}
          className={`flex flex-1  my-2 bg-gray-300 navbtn  ${currentTab == 'io' && 'navactive'}`}>
          <IoeditorIcon className="navicon mr-2" /> I/O Editor
        </button>

        <button onClick={() => { headchecker('recipes', 'Recipe Editor'); setsubhead(""); updatePage('setpageRecipes') }}
          className={`flex flex-1  my-2 bg-gray-300 navbtn  ${currentTab == 'recipes' && 'navactive'}`}>
          <RecepiIcon className="navicon mr-2" /> Recipes
        </button>
        <button onClick={() => { headchecker('events', 'Events'); setsubhead(""); updatePage('setpageEvents') }}
          className={`flex flex-1  my-2 bg-gray-300 navbtn  ${currentTab == 'events' && 'navactive'}`}>
          <EventsIcon className="navicon mr-2" /> Events & Alarms
        </button>
      </footer>
    </div>
  )
}