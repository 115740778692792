import React, { useState } from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import { useLocation } from "react-router-dom";

const analytics_url = process.env.REACT_APP_VIA_ANALYTICS;
export default function Analytics(props) {
  const [currentTab, setCurrentTab] = useState(1);
  const location = useLocation();
  return (
    <div>
      {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign} />}
     
      <div className=" bg-blue-50 flex h-full  px-20">
        
        <div className="flex-1">

          <div className="theme-lite-bg pt-0 overflow-hidden p-2">
            {/* <div class="flex border-b border-gray-300 relative z-20">
              <a class={`p-1 px-6 font-semibold rounded-t-lg  ${currentTab==1&& 'bg-white' } cursor-pointer`}  onClick={()=>setCurrentTab(1)}>Tab 1</a>
              <a  class={`p-1 px-6 font-semibold rounded-t-lg  ${currentTab==2&& 'bg-white' } cursor-pointer`} onClick={()=>setCurrentTab(2)}>Tab 2</a>
              <a  class={`p-1 px-6 font-semibold rounded-t-lg  ${currentTab==3&& 'bg-white' } cursor-pointer`}  onClick={()=>setCurrentTab(3)}>Tab 3</a>
            </div> */}
              <iframe title="Analytics" src={analytics_url} className={`w-full h-screen  ${currentTab!=1&& 'hidden' }`} ></iframe>
              {/* <iframe title="Analytics" src={analytics_url} className={`w-full h-screen  ${currentTab!=2&& 'hidden' }`} ></iframe>
              <iframe title="Analytics" src={analytics_url} className={`w-full h-screen  ${currentTab!=3&& 'hidden' }`}  ></iframe> */}
            
          </div>
        </div>
      </div>
      {location.pathname!="/" &&<Footer layoutdesign={props.layoutdesign} />}
    </div>
  );
}
