import React, { useEffect, useState } from "react";
import AccountIcon from "../../../Images/svg/accountIcon";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { ClearSession } from "../../api/user";
import { useAuth } from "../../../context/AuthContext";
import { combineNames } from "../../../utils/utils";

export default function Logout() {
  const navigate = useNavigate();
  const { user: userData, clearUserSession } = useAuth();
  const [username, setUsername] = useState("");
  const [showAccount, setShowAccount] = useState(false);

  const animateCss = {
    transition: "all 0.5s ease-in",
  };

  const getUserDetails = async () => {
    console.log("username", userData);
    const username = combineNames(
      userData?.profile?.first_name,
      userData?.profile?.middle_name,
      userData?.profile?.last_name
    );
    console.log(username, "username");
    setUsername(username);
  };

  const showUserDetails = () => {
    setShowAccount(true);
  };

  const hideUserDetails = () => {
    setShowAccount(false);
  };

  const logout = async (e) => {
    e.preventDefault();
    try {
      const currentDomain = window.location.hostname;
      const token = Cookies.get("token");

      if (token) {
        const res = await ClearSession();
        clearUserSession();
        Cookies.remove("refresh_token");
        Cookies.remove("userId");
        // Log the current domain and token value
        console.log("Current domain:", currentDomain);
        console.log("Token before removal:", token);

        // Attempt to remove the token cookie
        Cookies.remove("token", { path: "/", domain: currentDomain });

        // Verify if the token is removed
        const tokenAfterRemoval = Cookies.get("token");
        console.log("Token after removal:", tokenAfterRemoval);

        if (!tokenAfterRemoval) {
          console.log("Token successfully removed");
        } else {
          console.log("Failed to remove token");
        }
      } else {
        console.log("No token found");
      }

      // Navigate to the login page
      navigate("/login");
    } catch (error) {
      console.log("Error during logout:", error);
    }
  };

  useEffect(() => {
    if (userData) {
      getUserDetails();
    }
  }, [userData]);
  return (
    <button
      type="button"
      className="rounded-full w-9 h-9 border-0"
      onMouseEnter={showUserDetails}
      onMouseLeave={hideUserDetails}
    >
      <AccountIcon />
      <div
        style={animateCss}
        className={`text-left absolute  z-30 right-10 top-24 bg-white  rounded-xl ${
          showAccount
            ? " p-5 overflow-visible opacity-1"
            : "h-0 overflow-hidden opacity-0"
        }`}
      >
        <p>User: {username}</p>
        <div className="mt-3 text-gray-500">Version:0.1</div>
        <a
          href="#"
          className="cursor-pointer block font-bold underline my-4"
          onClick={logout}
        >
          Logout
        </a>
      </div>
    </button>
  );
}
