import React from 'react';

  export default function Pm4(props) { 
    return (
      <svg width="149" height="120" viewBox="0 0 149 120" fill="none" x={props.x} y={props.y}>
<path d="M1.0098 29.8738H38.553V6.79417H143.983V114.794H38.553L38.553 90.7735H1.0098C0.918262 64.0304 1.10139 56.6168 1.0098 29.8738Z" fill="url(#paint0_linear_33_58)" stroke="#646368" stroke-miterlimit="10"/>
<g filter="url(#filter0_i_33_58)">
<path d="M51.9834 103.794L92.4418 103.794C102.752 102.819 112.412 98.2258 119.779 90.7967C127.146 83.3677 131.763 73.5616 132.847 63.0475C134.724 39.7096 117.06 17.5715 92.4423 14.7944L51.9839 14.7942L51.9834 103.794Z" fill="#76777A"/>
</g>
<path d="M119.424 90.4447C112.144 97.7859 102.601 102.325 92.4182 103.294L52.4834 103.294L52.4839 15.2942L92.4141 15.2944C116.748 18.0547 134.201 39.9445 132.349 63.0019C131.276 73.4016 126.708 83.0989 119.424 90.4447Z" stroke="#A2A2A2"/>
<g filter="url(#filter1_ii_33_58)">
<path d="M91.4834 90.7942C108.328 90.7942 121.983 77.1389 121.983 60.2942C121.983 43.4495 108.328 29.7942 91.4834 29.7942C74.6387 29.7942 60.9834 43.4495 60.9834 60.2942C60.9834 77.1389 74.6387 90.7942 91.4834 90.7942Z" fill="#E6E6E6"/>
</g>
<path d="M91.4834 91.2942C108.604 91.2942 122.483 77.415 122.483 60.2942C122.483 43.1733 108.604 29.2942 91.4834 29.2942C74.3625 29.2942 60.4834 43.1733 60.4834 60.2942C60.4834 77.415 74.3625 91.2942 91.4834 91.2942Z" stroke="#B1B1B1"/>
<rect x="131.139" y="114.252" width="3.55581" height="62.8886" rx="0.75" transform="rotate(89.6445 131.139 114.252)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<g filter="url(#filter2_d_33_58)">
<rect x="52.9834" y="114.794" width="16" height="108" rx="1" transform="rotate(-180 52.9834 114.794)" fill="url(#paint1_linear_33_58)" shape-rendering="crispEdges"/>
<rect x="52.7334" y="114.544" width="15.5" height="107.5" rx="0.75" transform="rotate(-180 52.7334 114.544)" stroke="#646368" stroke-width="0.5" shape-rendering="crispEdges"/>
</g>
<g filter="url(#filter3_i_33_58)">
<rect x="110" y="59" width="3" height="2" rx="1" fill="#D9D9D9"/>
</g>
<g filter="url(#filter4_i_33_58)">
<rect x="89.9834" y="41.7942" width="3" height="3" rx="1" fill="#D9D9D9"/>
</g>
<g filter="url(#filter5_i_33_58)">
<rect x="88.9834" y="57.7942" width="6" height="6" rx="3" fill="#767070"/>
</g>
<g filter="url(#filter6_i_33_58)">
<rect x="71.9834" y="59.7942" width="2" height="2" rx="1" fill="#D9D9D9"/>
</g>
<g filter="url(#filter7_i_33_58)">
<rect x="88.9834" y="79.7942" width="3" height="3" rx="1" fill="#D9D9D9"/>
</g>
<g filter="url(#filter8_d_33_58)">
<rect x="36.9834" y="89.7942" width="4" height="60" transform="rotate(180 36.9834 89.7942)" fill="#D9D9D9"/>
</g>
<rect x="144.276" y="20.6504" width="3.55581" height="91.0104" rx="0.75" transform="rotate(-0.365837 144.276 20.6504)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<rect x="144.408" y="6.25257" width="3.47207" height="14.9469" rx="0.75" transform="rotate(0.594551 144.408 6.25257)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<rect x="137.29" y="8.7631" width="5.4396" height="10.2353" rx="0.75" transform="rotate(-90.4024 137.29 8.7631)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<path d="M126.647 29.2062C126.675 29.2521 126.726 29.2826 126.782 29.2826H131.899C131.955 29.2826 132.005 29.2521 132.034 29.2062L134.592 24.8976C134.62 24.8518 134.62 24.7907 134.592 24.7448L132.034 20.4362C132.005 20.3904 131.955 20.3598 131.899 20.3598H126.782C126.726 20.3598 126.675 20.3904 126.647 20.4362L124.089 24.7448C124.061 24.7907 124.061 24.8518 124.089 24.8976L126.647 29.2062ZM126.873 20.6654H131.807L134.274 24.8212L131.807 28.977H126.873L124.406 24.8212L126.873 20.6654Z" fill="#646368"/>
<path d="M129.34 26.8491C130.457 26.8491 131.368 25.9381 131.368 24.8212C131.368 23.7043 130.457 22.7933 129.34 22.7933C128.223 22.7933 127.312 23.7043 127.312 24.8212C127.312 25.9381 128.223 26.8491 129.34 26.8491ZM129.34 23.1053C130.286 23.1053 131.056 23.8759 131.056 24.8212C131.056 25.7665 130.286 26.5371 129.34 26.5371C128.395 26.5371 127.624 25.7665 127.624 24.8212C127.624 23.8759 128.395 23.1053 129.34 23.1053Z" fill="#646368"/>
<path d="M126.647 108.7C126.675 108.746 126.726 108.776 126.782 108.776H131.899C131.955 108.776 132.005 108.746 132.034 108.7L134.592 104.391C134.62 104.346 134.62 104.284 134.592 104.239L132.034 99.93C132.005 99.8842 131.955 99.8536 131.899 99.8536H126.782C126.726 99.8536 126.675 99.8842 126.647 99.93L124.089 104.239C124.061 104.284 124.061 104.346 124.089 104.391L126.647 108.7ZM126.873 100.159H131.807L134.274 104.315L131.807 108.471H126.873L124.406 104.315L126.873 100.159Z" fill="#646368"/>
<path d="M129.34 106.343C130.457 106.343 131.368 105.432 131.368 104.315C131.368 103.198 130.457 102.287 129.34 102.287C128.223 102.287 127.312 103.198 127.312 104.315C127.312 105.432 128.223 106.343 129.34 106.343ZM129.34 102.599C130.286 102.599 131.056 103.37 131.056 104.315C131.056 105.26 130.286 106.031 129.34 106.031C128.395 106.031 127.624 105.26 127.624 104.315C127.624 103.37 128.395 102.599 129.34 102.599Z" fill="#646368"/>
<g filter="url(#filter9_i_33_58)">
<rect x="30.2634" y="90.2272" width="9.36905" height="60.4805" transform="rotate(-179.944 30.2634 90.2272)" fill="#EAEAEA"/>
</g>
<rect x="106.139" y="4.25153" width="3.55581" height="62.8886" rx="0.75" transform="rotate(89.6445 106.139 4.25153)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
<g filter="url(#filter10_d_33_58)">
<rect x="127" y="0.00648499" width="10.7977" height="10.1015" rx="1" transform="rotate(-0.0344908 127 0.00648499)" fill="#D9D9D9"/>
<rect x="127.25" y="0.256334" width="10.2977" height="9.60147" rx="0.75" transform="rotate(-0.0344908 127.25 0.256334)" stroke="#646368" stroke-width="0.5"/>
</g>
<defs>
<filter id="filter0_i_33_58" x="51.9834" y="14.7942" width="81" height="93" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_58"/>
</filter>
<filter id="filter1_ii_33_58" x="55.9834" y="24.7942" width="70" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="3" dy="5"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_58"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-4" dy="-4"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_33_58" result="effect2_innerShadow_33_58"/>
</filter>
<filter id="filter2_d_33_58" x="31.9834" y="5.79417" width="22" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_58"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_58" result="shape"/>
</filter>
<filter id="filter3_i_33_58" x="110" y="59" width="3" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_58"/>
</filter>
<filter id="filter4_i_33_58" x="89.9834" y="41.7942" width="3" height="4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_58"/>
</filter>
<filter id="filter5_i_33_58" x="88.9834" y="57.7942" width="6" height="7" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_58"/>
</filter>
<filter id="filter6_i_33_58" x="71.9834" y="59.7942" width="2" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_58"/>
</filter>
<filter id="filter7_i_33_58" x="88.9834" y="79.7942" width="3" height="4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_58"/>
</filter>
<filter id="filter8_d_33_58" x="27.9834" y="28.7942" width="10" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_58"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_58" result="shape"/>
</filter>
<filter id="filter9_i_33_58" x="20.8943" y="29.7377" width="10.4277" height="61.4895" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1" dy="1"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_58"/>
</filter>
<filter id="filter10_d_33_58" x="126" y="0.000587463" width="14.8027" height="14.1068" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1" dy="2"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_58"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_58" result="shape"/>
</filter>
<linearGradient id="paint0_linear_33_58" x1="158.401" y1="19.3878" x2="-15.7818" y2="99.1883" gradientUnits="userSpaceOnUse">
<stop stop-color="#EAEAEA"/>
<stop offset="0.25" stop-color="#CDCDCD"/>
<stop offset="0.495" stop-color="#A6A6A7"/>
<stop offset="0.695" stop-color="#DBDBDC"/>
<stop offset="1" stop-color="#8B8B8B"/>
</linearGradient>
<linearGradient id="paint1_linear_33_58" x1="60.9834" y1="114.794" x2="60.9834" y2="222.794" gradientUnits="userSpaceOnUse">
<stop stop-color="#BFBFBF"/>
<stop offset="0.485" stop-color="#E2E2E2"/>
<stop offset="1" stop-color="#A9A6A6" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>


      
        
    )
  }