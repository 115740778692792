import React from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import "./efem.css";
import { useLocation } from "react-router-dom";

// const io_url = process.env.REACT_APP_VIA_IO;
const efemUrl = process.env.REACT_APP_VIA_EFEM_URL;
// const efemUrl = "https://automation.getvia.ai/efemtest";
// const efemUrl = "http://localhost:4202/efemtest";
export default function Efemtesting(props) {
  const location = useLocation();
  return (
    <div>
       {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign} />}
     
       <div className="p-2 px-20 bg-blue-50 flex gap-4 middle-screen">
        <div className="flex-1 overflow-hidden">
          <div className="theme-lite-bg pt-0 overflow-hidden">
            <iframe
              title="Events & Alarms"
              src={efemUrl}
              className="w-auto m-2 io-iframe "
              style={{width: "99%"}}
            ></iframe>
          </div>
        </div>
      </div>
       {location.pathname!="/" &&<Footer layoutdesign={props.layoutdesign} />}
    </div>
  );
}