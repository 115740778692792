import axios from "axios";
import Cookies from "js-cookie";
import { getIpAddress } from "../../utils/ipUtils";

const token = Cookies.get("token");
const userId = Cookies.get("userId");

const config = {
  headers: { Authorization: `Bearer ${token}` },
};

const AUTH_URL = process.env.REACT_APP_VIA_AUTH_URL;
export const AuthApi = axios.create({
  baseURL: AUTH_URL,
});

export const ProtectedAuthApi = axios.create({
  baseURL: AUTH_URL,
});

ProtectedAuthApi.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ProtectedAuthApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status == 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // const refreshToken = Cookies.get('refreshToken');
        // const response = await ProtectedAuthApi.post('/refresh', { refreshToken });
        // const newToken = response.data.token;
        // Cookies.set('token', newToken, { secure: true, sameSite: 'strict' });
        // Retry the original request with the new token
        // return ProtectedAuthApi(originalRequest);

        removeSession();
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const removeSession = async () => {
  try {
    const currentDomain = window.location.hostname;
    const token = Cookies.get("token");

    if (token) {
      Cookies.remove("refresh_token");
      Cookies.remove("userId");
      // Log the current domain and token value
      console.log("Current domain:", currentDomain);
      console.log("Token before removal:", token);

      // Attempt to remove the token cookie
      Cookies.remove("token", { path: "/", domain: currentDomain });

      // Verify if the token is removed
      const tokenAfterRemoval = Cookies.get("token");
      console.log("Token after removal:", tokenAfterRemoval);

      if (!tokenAfterRemoval) {
        console.log("Token successfully removed");
      } else {
        console.log("Failed to remove token");
      }
    } else {
      console.log("No token found");
    }

    // Navigate to the login page
    window.location.href = "/login";
  } catch (error) {
    console.log("Error during logout:", error);
  }
};

export const UserAuthenticate = async (args) => {
  const url = `api/v1/user/authenticate`;

  try {
    const ipAddress = await getIpAddress();

    const payload = {
      username: args.username,
      password: args.password,
      source: {
        classification: "web_portal",
        device: ipAddress,
      },
    };

    const config = {
      headers: {
        Authorization:
          "Bearer 35a5a74fc3e74b9918bf484ec66eb160b9237ae6aa7dccfac50ced9c2a987810",
      },
    };

    const res = await AuthApi.post(url, payload, config);

    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const ClearSession = async () => {
  const url = `api/v1/user/logout/${userId}`;
  const ipAddress = await getIpAddress();

  const payload = {
    source: {
      classification: "web_portal",
      device: ipAddress,
    },
  };

  try {
    const res = await ProtectedAuthApi.post(url, payload, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const CreateUser = async (args) => {
  const url = `api/v1/user`;
  const ipAddress = await getIpAddress();
  const payload = {
    profile: {
      first_name: args.first_name,
      middle_name: args.middle_name,
      last_name: args.last_name,
    },
    username: args.username,
    source: {
      classification: "web_portal",
      device: ipAddress,
    },
  };

  try {
    const res = await ProtectedAuthApi.post(url, payload, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const GetUserDetail = async (id) => {
  const url = `api/v1/user/details/${id}`;

  try {
    const res = await ProtectedAuthApi.get(url, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const GetUsers = async () => {
  const url = `api/v1/user`;

  try {
    const res = await ProtectedAuthApi.get(url, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};
export const DeleteUser = async (roleId) => {
  const url = `/api/v1/user/${roleId}`;

  try {
    const res = await ProtectedAuthApi.delete(url); 
    return {
      status: res.status || 200,
      data: res?.data?.result || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const CreateRole = async (args) => {
  const url = `/api/v1/role`;
  const ipAddress = await getIpAddress();
  const payload = {
    name: args.name,
    is_active: true,
    role_id: "",
    admin_access: args.admin_access,
    source: {
      classification: "web_portal",
      device: ipAddress,
    },
  };

  try {
    const res = await ProtectedAuthApi.post(url, payload, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const DeleteRole = async (roleId) => {
  const url = `/api/v1/role/${roleId}`;

  try {
    const res = await ProtectedAuthApi.delete(url); 
    return {
      status: res.status || 200,
      data: res?.data?.result || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const GetRoles = async () => {
  const url = `api/v1/role`;

  try {
    const res = await ProtectedAuthApi.get(url, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const UpdateRole = async ({ roleId, name, admin_access }) => {
  const url = `/api/v1/role/${roleId}`; 
  const ipAddress = await getIpAddress();
  const payload = {
    name: name,
    admin_access: admin_access,
    source: {
      classification: "web_portal",
      device: ipAddress,
    },
  };

  try {
    const res = await ProtectedAuthApi.put(url, payload, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const CreatePermission = async ({ roleId, permissions }) => {
  const url = `/api/v1/permission`;
  const ipAddress = await getIpAddress();
  const payload = {
      role_id: roleId,
      permissions,
      source: {
          classification: "web_portal",
          device: ipAddress,
      },
  };
  try {
      const res = await ProtectedAuthApi.post(url, payload, config);
      return {
          status: res.status || 200,
          data: res?.data?.data || null,
      };
  } catch (error) {
      if (axios.isAxiosError(error)) {
          if (error.response) {
              return {
                  status: error.response.status,
                  error: error.response.data.message || "An error occurred",
              };
          } else if (error.request) {
              return {
                  status: 0,
                  error: "No response received from server",
              };
          }
      }
      return {
          status: 500,
          error: "An unexpected error occurred",
      };
  }
};




export const GetRolesData = async ({roleId}) => {
  const url =`api/v1/permission/${roleId}`;

  try {
    const res = await ProtectedAuthApi.get(url, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const AssignRole = async (args) => {
  const ipAddress = await getIpAddress();
  const url = `api/v1/user/assign-role/${args.userId}`;
  const payload = {
    roles: args.roles,
    source: {
      classification: "web_portal",
      device: ipAddress,
    },
  };

  try {
    const res = await ProtectedAuthApi.post(url, payload, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const GetPassword = async (id) => {
  const url = `api/v1/user/password/${id}`;
  try {
    const res = await ProtectedAuthApi.get(url, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const ResetPassword = async (args) => {
  const url = `api/v1/user/reset-password/${args?.userId}`;
  const ipAddress = await getIpAddress();
  const payload = {
    old_password: args.old_password,
    new_password: args.new_password,
    confirm_password: args.confirm_password,

    source: {
      classification: "web_portal",
      device: ipAddress,
    },
  };
  try {
    const res = await ProtectedAuthApi.post(url, payload, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};

export const UserLogEvent = async (args) => {
  const url = `api/v1/logs/client-log`;
  const ipAddress = await getIpAddress();
  const payload = {
    user_id: args.user_id,
    page: args.page,
    section: args.section,
    message: args.message,
    content: args.content,

    source: {
      classification: "web_portal",
      device: ipAddress,
    },

  };
  const config = {
    headers: {
      Authorization:
        "Bearer 35a5a74fc3e74b9918bf484ec66eb160b9237ae6aa7dccfac50ced9c2a987810",
    },
  };
  try {
    const res = await AuthApi.post(url, payload, config);
    return {
      status: res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        return {
          status: error.response.status,
          error: error.response.data.message || "An error occurred",
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          error: "No response received from server",
        };
      }
    }
    // Something happened in setting up the request that triggered an Error
    return {
      status: 500,
      error: "An unexpected error occurred",
    };
  }
};
