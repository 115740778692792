import React from 'react'

function EFEM_layout(props) {
  return (
    <svg width="423" height="104" viewBox="0 0 423 104" fill="none" xmlns="http://www.w3.org/2000/svg" x={props.x} y={props.y}>
    <g id="Group 177">
    <path id="Rectangle 554" d="M420 103.5H2.99999C1.61928 103.5 0.5 102.381 0.5 101V3C0.5 1.61929 1.61929 0.5 3 0.5H161.613H218.775L360.641 0.500262L420 0.500013C421.381 0.500007 422.5 1.6193 422.5 3.00001V101C422.5 102.381 421.381 103.5 420 103.5Z" fill="url(#paint0_linear_2113_18)" stroke="#646368"/>
    <g id="Group 67">
    <path id="Vector" d="M12.446 22.9229C12.4728 22.9692 12.5205 23 12.5741 23H17.4259C17.4795 23 17.5272 22.9692 17.554 22.9229L19.9799 18.5771C20.0067 18.5308 20.0067 18.4692 19.9799 18.4229L17.554 14.0771C17.5272 14.0308 17.4795 14 17.4259 14H12.5741C12.5205 14 12.4728 14.0308 12.446 14.0771L10.0201 18.4229C9.99329 18.4692 9.99329 18.5308 10.0201 18.5771L12.446 22.9229ZM12.6606 14.3082H17.3394L19.6789 18.5L17.3394 22.6918H12.6606L10.3211 18.5L12.6606 14.3082Z" fill="#646368"/>
    <path id="Vector_2" d="M15.0002 20.5453C16.0594 20.5453 16.9233 19.6264 16.9233 18.4998C16.9233 17.3732 16.0594 16.4543 15.0002 16.4543C13.9411 16.4543 13.0771 17.3732 13.0771 18.4998C13.0771 19.6264 13.9411 20.5453 15.0002 20.5453ZM15.0002 16.769C15.8967 16.769 16.6274 17.5463 16.6274 18.4998C16.6274 19.4533 15.8967 20.2306 15.0002 20.2306C14.1038 20.2306 13.373 19.4533 13.373 18.4998C13.373 17.5463 14.1038 16.769 15.0002 16.769Z" fill="#646368"/>
    </g>
    <g id="Group 67_2">
    <path id="Vector_3" d="M393.446 20.9229C393.473 20.9692 393.52 21 393.574 21H398.426C398.48 21 398.527 20.9692 398.554 20.9229L400.98 16.5771C401.007 16.5308 401.007 16.4692 400.98 16.4229L398.554 12.0771C398.527 12.0308 398.48 12 398.426 12H393.574C393.52 12 393.473 12.0308 393.446 12.0771L391.02 16.4229C390.993 16.4692 390.993 16.5308 391.02 16.5771L393.446 20.9229ZM393.661 12.3082H398.339L400.679 16.5L398.339 20.6918H393.661L391.321 16.5L393.661 12.3082Z" fill="#646368"/>
    <path id="Vector_4" d="M396 18.5453C397.059 18.5453 397.923 17.6264 397.923 16.4998C397.923 15.3732 397.059 14.4543 396 14.4543C394.941 14.4543 394.077 15.3732 394.077 16.4998C394.077 17.6264 394.941 18.5453 396 18.5453ZM396 14.769C396.897 14.769 397.627 15.5463 397.627 16.4998C397.627 17.4533 396.897 18.2306 396 18.2306C395.104 18.2306 394.373 17.4533 394.373 16.4998C394.373 15.5463 395.104 14.769 396 14.769Z" fill="#646368"/>
    </g>
    <g id="Group 67_3">
    <path id="Vector_5" d="M397.446 90.9229C397.473 90.9692 397.52 91 397.574 91H402.426C402.48 91 402.527 90.9692 402.554 90.9229L404.98 86.5771C405.007 86.5308 405.007 86.4692 404.98 86.4229L402.554 82.0771C402.527 82.0308 402.48 82 402.426 82H397.574C397.52 82 397.473 82.0308 397.446 82.0771L395.02 86.4229C394.993 86.4692 394.993 86.5308 395.02 86.5771L397.446 90.9229ZM397.661 82.3082H402.339L404.679 86.5L402.339 90.6918H397.661L395.321 86.5L397.661 82.3082Z" fill="#646368"/>
    <path id="Vector_6" d="M400 88.5453C401.059 88.5453 401.923 87.6264 401.923 86.4998C401.923 85.3732 401.059 84.4543 400 84.4543C398.941 84.4543 398.077 85.3732 398.077 86.4998C398.077 87.6264 398.941 88.5453 400 88.5453ZM400 84.769C400.897 84.769 401.627 85.5463 401.627 86.4998C401.627 87.4533 400.897 88.2306 400 88.2306C399.104 88.2306 398.373 87.4533 398.373 86.4998C398.373 85.5463 399.104 84.769 400 84.769Z" fill="#646368"/>
    </g>
    <g id="Group 68">
    <path id="Vector_7" d="M12.446 94.9229C12.4728 94.9692 12.5205 95 12.5741 95H17.4259C17.4795 95 17.5272 94.9692 17.554 94.9229L19.9799 90.5771C20.0067 90.5308 20.0067 90.4692 19.9799 90.4229L17.554 86.0771C17.5272 86.0308 17.4795 86 17.4259 86H12.5741C12.5205 86 12.4728 86.0308 12.446 86.0771L10.0201 90.4229C9.99329 90.4692 9.99329 90.5308 10.0201 90.5771L12.446 94.9229ZM12.6606 86.3082H17.3394L19.6789 90.5L17.3394 94.6918H12.6606L10.3211 90.5L12.6606 86.3082Z" fill="#646368"/>
    <path id="Vector_8" d="M15.0002 92.5453C16.0594 92.5453 16.9233 91.6264 16.9233 90.4998C16.9233 89.3732 16.0594 88.4543 15.0002 88.4543C13.9411 88.4543 13.0771 89.3732 13.0771 90.4998C13.0771 91.6264 13.9411 92.5453 15.0002 92.5453ZM15.0002 88.769C15.8967 88.769 16.6274 89.5463 16.6274 90.4998C16.6274 91.4533 15.8967 92.2306 15.0002 92.2306C14.1038 92.2306 13.373 91.4533 13.373 90.4998C13.373 89.5463 14.1038 88.769 15.0002 88.769Z" fill="#646368"/>
    </g>
    </g>
    <defs>
    <linearGradient id="paint0_linear_2113_18" x1="6.0925" y1="-46.7718" x2="435.151" y2="50.2801" gradientUnits="userSpaceOnUse">
    <stop stop-color="#B0B2B6"/>
    <stop offset="1" stop-color="#AFAFB5"/>
    </linearGradient>
    </defs>

    </svg>
  )
}

export default EFEM_layout